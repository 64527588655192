//React
//import { useNavigate } from "react-router-dom";

//UI
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";

//Services
import { useAtom } from "jotai";
import { breadCrumbsAtom } from "atom";
import config from "config";

//Logics

//Classes

//Components

const BreadcrumbBar = () => {

    //const navigate = useNavigate();
    const [breadCrumb] = useAtom(breadCrumbsAtom);

    return (
        <div className="flex justify-center">
            {breadCrumb.length > 0 && <Breadcrumb aria-label="Default breadcrumb example">
                <Breadcrumb.Item href="/" icon={HiHome}>
                    Home
                </Breadcrumb.Item>
                {breadCrumb.map((item, index) => {
                    return <Breadcrumb.Item key={index} href={config.publicUrl + item.url}>{item.name}</Breadcrumb.Item>
                })}
            </Breadcrumb>}
        </div>
    );
}

export default BreadcrumbBar;
