//React

//UI

//Services

//Logics
import createGUID from "logic/utility/createGUID";

//Classes
import { Mcq } from "classes/synapp/mcq/Mcq";
import { McqAnswer } from "classes/synapp/mcq/McqAnswer";
import { McqCodeQuestionSchema } from "classes/models/response/McqCodeQuestionSchema";

//Components


const parseAikenTextToMcqs = (inputString: string) => {
    //Example of text format:
    //         A variable is something that _________ information. "Variable" means that the value can ___________.
    // A) stores; change
    // B) changes; store
    // C) edits; not be changed
    // D) None of the above
    // ANSWER: A

    //Convert text into mcq objects

    class TempAikenObject {
        questionText: string;
        answers: { answerText: string, isCorrect: boolean }[];
        constructor() {
            this.questionText = "";
            this.answers = [];
        }
    }
    let AnswerReference = ["A", "B", "C", "D", "E"];
    let lines = inputString.split("\n");
    //Convert text into tempAikenObjects
    let tempAikenObjects = [] as TempAikenObject[];
    let currentTempAikenObject = new TempAikenObject();
    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        if (line.startsWith("ANSWER:")) {
            let answerLetter = line.substring(7).trim();
            let answerIndex = AnswerReference.indexOf(answerLetter);
            currentTempAikenObject.answers[answerIndex].isCorrect = true;
            //Add currentTempAikenObject to tempAikenObjects
            tempAikenObjects.push(currentTempAikenObject);
            currentTempAikenObject = new TempAikenObject();
        } else {
            if (line.startsWith("A)") || line.startsWith("B)") || line.startsWith("C)") || line.startsWith("D)") || line.startsWith("E)")) {
                let answerText = line.substring(2).trim();
                let isCorrect = false;
                currentTempAikenObject.answers.push({ answerText: answerText, isCorrect: isCorrect });
            }
            else {
                currentTempAikenObject.questionText += line;
            }
        }
    }

    //Convert tempAikenObjects into Mcq objects
    let mcqs = [] as Mcq[];

    for (let i = 0; i < tempAikenObjects.length; i++) {
        let tempAikenObject = tempAikenObjects[i];
        let mcq = new Mcq();
        mcq.contentDocument = JSON.stringify({
            "type": "doc",
            "content": [
                {
                    "type": "heading",
                    "attrs": {
                        "id": createGUID(10),
                        "level": 3
                    },
                    "content": [
                        {
                            "type": "text",
                            "text": tempAikenObject.questionText
                        }
                    ]
                }
            ]
        });
        mcq.difficulty = 1;
        mcq.isSelfContained = true;
        mcq.answers = [];
        for (let j = 0; j < tempAikenObject.answers.length; j++) {
            let answer = tempAikenObject.answers[j];
            let newAnswer = new McqAnswer();
            newAnswer.answerText = answer.answerText;
            newAnswer.isCorrect = answer.isCorrect;
            mcq.answers.push(newAnswer);
        }
        mcqs.push(mcq);
    }

    return mcqs;

}

const parseAIResponseSchemaToMcqs = (inputString: string) => {

    let mcqs = JSON.parse(inputString);
    let newMcqs = [] as Mcq[];

    for (let i = 0; i < mcqs.questions.length; i++) {
        let mcq = mcqs.questions[i] as McqCodeQuestionSchema;
        let newMcq = new Mcq();

        let contentObject = {
            "type": "doc",
            "content": [
                {
                    "type": "heading",
                    "attrs": {
                        "id": createGUID(10),
                        "level": 3
                    },
                    "content": [
                        {
                            "type": "text",
                            "text": mcq.contentDocument.questionText
                        }
                    ]
                }
            ]

        };

        if (mcq.contentDocument.sourceCode !== "") {
            let sourceCodeObject = {
                "type": "codeBlock",
                "attrs": {
                    "id": createGUID(10),
                    "level": 3
                },
                "content": [
                    {
                        "type": "text",
                        "text": mcq.contentDocument.sourceCode
                    }
                ]
            }
            contentObject.content.push(sourceCodeObject);
        }

        newMcq.contentDocument = JSON.stringify(contentObject);
        newMcq.difficulty = mcq.difficulty;
        newMcq.answers = [];
        for (let j = 0; j < mcq.answers.length; j++) {
            let answer = mcq.answers[j];
            let newAnswer = new McqAnswer();
            newAnswer.answerText = answer.answerText;
            newAnswer.isCorrect = answer.isCorrect;
            newAnswer.explanation = answer.explanation;
            newMcq.answers.push(newAnswer);
        }
        newMcqs.push(newMcq);
    }


    return newMcqs;
}

export default { parseAIResponseSchemaToMcqs, parseAikenTextToMcqs };