//import { PropertyFilter } from './PropertyFilter';

class PropertyFilter {
    property: string = "";
    value: string = "";

    constructor(property: string = "", value: string = "") {
        this.property = property;
        this.value = value;
    }
}
class FilterModel {
    propertyFilters: PropertyFilter[] = [];
    propertyExclusions : string[] = [];
    onlyOwner: boolean = false;
    onlyUser: boolean = false;

    constructor(propertyFilters: PropertyFilter[] = []) {
        this.propertyFilters = propertyFilters;
    }
}

export {FilterModel, PropertyFilter};   