import React from 'react';

type Props = {
    content: any;
    elementId: string;
}

//Parse the marks of content JSON into HTML
const RenderNodeContent = (props: Props) => {
    //Marks are the formatting of the text
    //Types of marks to render are bold, italic, strike, code, highlight, link
    const { content, elementId } = props;
    
    return (
        <React.Fragment>
            {content.marks && <span>
                {content.marks.map((mark: any, index: number) => (
                    <React.Fragment key={"marks-" + elementId + index}>
                        {mark.type === "bold" && <b>{content.text}</b>}
                        {mark.type === "italic" && <i>{content.text}</i>}
                        {mark.type === "strike" && <del>{content.text}</del>}
                        {mark.type === "code" && <code>{content.text}</code>}
                        {mark.type === "highlight" && <mark style={{ backgroundColor: mark.attrs.color }}>{content.text}</mark>}
                        {mark.type === "link " && <a href={mark.attrs.href} target={mark.attrs.target} rel={mark.attrs.rel} className={mark.attrs.class}>{content.text}</a>}
                    </React.Fragment>
                ))}
            </span>}
            {!content.marks && <span>{content.text}</span>}

        </React.Fragment>
    )
}

export default RenderNodeContent;