//React

//UI

//Services

//Logics

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';

//Components
import { mergeAttributes,  } from '@tiptap/core';
import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import CourseTextChallenge from './CourseTextChallenge';

//TODO: Add past duedate setting
//byte flags for past duedate setting

export default Node.create({
    name: ExtensionType.CourseTextChallenge,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            elementId: {
                default: "default",
            },
            courseChallenge: {
                default: new CourseChallenge(CourseChallengeType.TextChallenge),
            }
        }
    },
    parseHTML() {
        return [
            {
                tag: ExtensionType.CourseTextChallenge,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [ExtensionType.CourseTextChallenge, mergeAttributes(HTMLAttributes)]
    },
    addNodeView() {
        return ReactNodeViewRenderer(CourseTextChallenge)
    },
})

