//React
import { useState, useEffect } from 'react';
import React from 'react';

//Components 
import Editor from "react-simple-code-editor";

//Services/Helpers

//Store

//Views/Components

//Classes
//import 'prismjs/themes/prism.css'; //Example style, you can use another
import "css/prism-vsc-dark.css";
import 'css/codeeditor.css';
import { highlight, languages } from "prismjs";

const TestStuff = () => {

    const [code, setCode] = useState('test code');
    const [lifeCalendar, setLifeCalendar] = useState<string[]>([]);
    const [orderBy, setOrderBy] = useState('none'); //options: none, year, decade

    const age = 39;
    const expectedAgeAtDeath = 80;

    useEffect (() => {
        createLifeCalendar();
    }, []);

    const hightlightWithLineNumbers = (input: string, language: any) =>
        highlight(input, languages.js, 'js')
            .split("\n")
            .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join("\n");

    const createLifeCalendar = () => {
        let totalWeeks = expectedAgeAtDeath * 52;
        let weeksLived = age * 52;
        let newLifeCalendar = [] as string[];

        for (let i = 0; i < totalWeeks; i++) {
            newLifeCalendar.push(i < weeksLived ? 'L' : 'U');
        }
        setLifeCalendar([...newLifeCalendar]);
    }

    

    return (
        <div className="container mx-auto">
            TestText
            <Editor
                value={code}
                padding={10}
                onValueChange={(code: string) => setCode(code)}
                className="code-editor"
                highlight={(sourceCode) => hightlightWithLineNumbers(sourceCode, languages)}
                textareaId="codeArea"
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 24,
                    backgroundColor: 'white',
                    outline: 0
                }}
            />
            
            <select onChange={(e) => setOrderBy(e.target.value)}>
                <option value="none">None</option>
                <option value="year">Year</option>
                <option value="decade">Decade</option>
            </select>
            
            
            <div className="">
                {orderBy === 'none' && lifeCalendar.map((week, index) => (
                    <div key={index} className={`w-8 h-6 rounded-sm m-1 inline-block bg-${week === 'L' ? 'green' : 'red'}-500`}>{week}</div>
                ))}
                 {orderBy === 'year' && lifeCalendar.map((week, index) => (
                    <React.Fragment>
                        {index % 52 === 0 && <div>Year: {(index / 52) + 1}</div>}
                        <div key={index} className={`w-8 h-6 rounded-sm m-1 inline-block bg-${week === 'L' ? 'green' : 'red'}-500`}>{week}</div>   
                    </React.Fragment>
                ))}
                 {orderBy === 'decade' && lifeCalendar.map((week, index) => (
                    <React.Fragment>
                        {index % 520 === 0 && <div>Decade: {index / 520}</div>}
                        <div key={index} className={`w-8 h-6 rounded-sm m-1 inline-block bg-${week === 'L' ? 'green' : 'red'}-500`}>{week}</div>   
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default TestStuff;