
class QuestionAnswer {

    question: string;
    answers: string[];

    constructor(question: string, answers: string[]) {
        this.question = question;
        this.answers = answers;
    }
}   

class QuestionAnswerFlashGame {

    name: string;
    questionAnswerSets: QuestionAnswer[];

    constructor(title: string, sets: QuestionAnswer[]) {
        this.name = title;
        this.questionAnswerSets = sets;
    }
}

export { QuestionAnswerFlashGame, QuestionAnswer };