//React
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Components / helpers 
import { Button, Card, ToggleSwitch, Modal, Tooltip, TextInput, Alert, Select, Avatar } from "flowbite-react";
import { HiInformationCircle, HiHome, HiOutlineExclamationCircle } from "react-icons/hi";
import courseIcons from 'view_components/icons/courseIcons';
import flashSetLogic from "logic/synapp/flashSetLogic";

//Services
//import useApiGet from "services/useApiGet";
import config from "config";
//import apiService from "services/apiService";
import errorService from "services/errorService";
import { toast } from "react-toastify";
import QRCode from 'react-qr-code'; //TODO: Implement QR code for sharing games

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Views/Components
import CreateWordSetGroupModal from "./modals/CreateWordSetGroupModal";
import CreateQASetGroupModal from "./modals/CreateQAFlashSetModal";

//Classes
import { WordSetGroup, WordSet } from "classes/synapp/wordflash/WordSetGroup";
import { CourseTemplate } from "classes/course/CourseTemplate";
import { QASetGroup } from "classes/synapp/wordflash/QASetGroup";
import { IsBusy } from "classes/general/IsBusy";
import { Class } from "classes/enums/Class";
import crudService from "services/crudService";
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";

type Props = {
  courseId: string;
}

function WordSetConstructor(props: Props) {

  const { courseId } = props;

  //=================================================================================================== SIMPLE HELPER CLASSES
  class CurrentAction {
    type: string = "";
    id: string;
    isSaving: boolean = false;
    isLoading: boolean = false;
    isCloning: boolean = false;
    isDeleting: boolean = false;
    isEditing: boolean = false;

    constructor(type: string, id: string) {
      this.type = type;
      this.id = id;
      if (type === 'saving') {
        this.isSaving = true;
      }
      if (type === 'loading') {
        this.isLoading = true;
      }
      if (type === 'cloning') {
        this.isCloning = true;
      }
      if (type === 'deleting') {
        this.isDeleting = true;
      }
      if (type === 'editing') {
        this.isEditing = true;
      }
    }

    reset = () => {
      this.type = '';
      this.isSaving = false;
      this.isLoading = false;
      this.isCloning = false;
      this.isDeleting = false;
      this.isEditing = false;
      return this;
    }
  }

  // class BreadcrumbItem {
  //   title: string;
  //   path: string;
  //   icon: any;
  //   constructor(title: string, path: string, icon: any) {
  //     this.name = title;
  //     this.path = path;
  //     this.icon = icon;
  //   }
  // }

  class CloneData {
    type: string;
    index: number;

    constructor(type: string, index: number) {
      this.type = type;
      this.index = index;

    }
  }

  //=================================================================================================== STATE

  //const baseLink = 'https://synapp.org/wordflash/stage';
  const baseLink =  config.publicUrl + '/wordflash/stage';
  //const { stateEditingOn } = useParams();

  //const [path, setPath] = useState<BreadcrumbItem[]>([new BreadcrumbItem('Courses', '/course/browser', HiHome)]);
  //const [selectedCourseTemplate, setSelectedCourseTemplate] = useState<CourseTemplate | null>(null);
  const [user] = useAtom(userAtom);
  const [editingOn, setEditingOn] = useState<boolean>(true);
  const [hasEditingPrivileges, setHasEditingPrivileges] = useState<boolean>(true);
  const [courseTemplates, setCourseTemplates] = useState<CourseTemplate[]>([]);

  const [wordSetGroups, setWordSetGroups] = useState<WordSetGroup[]>([]);
  const [qASetGroups, setQASetGroups] = useState<QASetGroup[]>([]);

  //Modal states
  const [showCreateWordSetGroupModal, setShowCreateWordSetGroupModal] = useState<boolean>(false);
  const [showCreateQASetGroupModal, setShowCreateQASetGroupModal] = useState<boolean>(false);
  //const [showCreateCourseTemplateModal, setShowCreateCourseTemplateModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showQRModal, setShowQRModal] = useState<boolean>(false);

  //For creating new courses and word flash sets
  //const [newCourseTemplate, setNewCourseTemplate] = useState<CourseTemplate>(new CourseTemplate());
  const [tempWordSetGroup, setTempWordSetGroup] = useState<WordSetGroup>(new WordSetGroup('', '', [new WordSet([], 'Set 1')]));
  const [tempQASetGroup, setTempQASetGroup] = useState<QASetGroup>(new QASetGroup('', ''));

  //For delete confirmation prompt
  const [deleteID, setDeleteID] = useState<string>('');
  const [deleteType, setDeleteType] = useState<string>('');

  //For cloning/copying
  const [showCopyToModal, setShowCopyToModal] = useState<boolean>(false);
  const [cloneData, setCloneData] = useState<CloneData>(new CloneData('', -1));
  const [selectedCopyToCourseTemplateId, setSelectedCopyToCourseTemplateId] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<CurrentAction>(new CurrentAction('loading', 'courses')); //For spinners

  const [qrCodeLink, setQRCodeLink] = useState<string>('');

  const [isBusy, setIsBusy] = useState(new IsBusy());

  const navigate = useNavigate();

  //=================================================================================================== USE EFFECTS

  // useEffect(() => {
  //   if (stateEditingOn && stateEditingOn === 'true') {
  //     setEditingOn(true);
  //   }
  // }, [stateEditingOn]);
  //=================================================================================================== CRUD I/O


  useEffect(() => {
    const getWordSetGroups = async () => {

      //let response = await apiService.get(config.apiUrl + '/cms/GetFlashSet/word/' + courseId);
      let filter = new FilterModel([new PropertyFilter('CourseId', courseId)]);
      let response = await crudService.get(Class.wordSetGroup, filter);
      if (response.success) {
        let wordSetGroups = response.payload as WordSetGroup[];
        setWordSetGroups(wordSetGroups);
        setIsBusy(isBusy.loaded(Class.wordSetGroup));
      } else {
        errorService.handleError(response);
      }
    }

    const getQASetGroups = async () => {
      ///let response = await apiService.get(config.apiUrl + '/cms/GetFlashSet/qa/' + courseId);
      let filter = new FilterModel([new PropertyFilter('CourseId', courseId)]);
      let response = await crudService.get(Class.qASetGroup, filter);
      if (response.success) {
        let qASetGroups = response.payload as QASetGroup[];
        setQASetGroups(qASetGroups);
        setIsBusy(isBusy.loaded(Class.qASetGroup));
      } else {
        errorService.handleError(response);
      }
    }

    setIsBusy(new IsBusy([Class.wordSetGroup, Class.qASetGroup]));
    getWordSetGroups();
    getQASetGroups();

  }, [courseId]);


  // const handleCreateUpdateCourseTemplate = async (courseTemplate: CourseTemplate) => {
  //   let response;
  //   setIsSaving(true);

  //   if (courseTemplate.id === '') {
  //     //Create a new slideshow
  //     response = await apiService.post(config.apiUrl + '/cms/CreateCourseTemplate', courseTemplate);
  //     if (response.success) {
  //       toast.success("Course created successfully!");
  //       setCourseTemplates([...courseTemplates, response.payload]);
  //     } else {
  //       //Error reported from server
  //       errorService.handleError(response);
  //       //toast.error(response.message);
  //       //console.log(response.message);
  //     }
  //     setIsSaving(false);
  //   } else {
  //     //Update existing course template
  //     response = await apiService.post(config.apiUrl + '/cms/UpdateCourseTemplate', courseTemplate);
  //     if (response.success) {
  //       toast.success("Course updated successfully!");
  //       let newCourseTemplates = [...courseTemplates];
  //       let index = newCourseTemplates.findIndex((cTemplate: CourseTemplate) => cTemplate.id === courseTemplate.id);
  //       newCourseTemplates[index] = response.payload;
  //       setCourseTemplates(newCourseTemplates);
  //     } else {
  //       //Error reported from server
  //       //toast.error(response.message);
  //       //console.log(response.message);
  //       errorService.handleError(response);
  //     }
  //     setIsSaving(false);
  //   }

  // }

  // const handleEditCourseTemplate = (index: number) => {
  //   setNewCourseTemplate(courseTemplates[index]);
  //   setShowCreateCourseTemplateModal(true);
  // }

  // const handleCloneCourseTemplate = async (type: string, index: number) => {
  //   setCurrentAction(new CurrentAction('cloning', courseTemplates[index].id));
  //   let response = await apiService.get(config.apiUrl + '/cms/CloneCourseTemplate/' + courseTemplates[index].id);
  //   if (response.success) {
  //     toast.success("Course template cloned successfully!");
  //     setCourseTemplates([...courseTemplates, response.payload]);
  //     setCurrentAction(new CurrentAction('none', ''));
  //   } else {
  //     errorService.handleError(response);
  //     setCurrentAction(new CurrentAction('none', ''));
  //   }

  // }

  const handlePersistWordSetGroup = async (wordSetGroup: WordSetGroup) => {

    let response = await crudService.create(Class.wordSetGroup, wordSetGroup);
    if (response.success) {
      toast.success("Word flash set updated successfully!");
      let updatedWordSetGroup = response.payload;
      let newWordSetGroups = [...wordSetGroups];
      let index = newWordSetGroups.findIndex((thisSet: WordSetGroup) => thisSet.id === wordSetGroup.id);
      if (index === -1) {
        newWordSetGroups.push(updatedWordSetGroup);
      } else {
        newWordSetGroups[index] = updatedWordSetGroup;
      }
      setWordSetGroups(newWordSetGroups);
      setShowCreateWordSetGroupModal(false);
    } else {
      errorService.handleError(response);
    }

  }

  const handlePersistQASetGroup = async (qASetGroup: QASetGroup) => {
    let response = await crudService.create(Class.qASetGroup, qASetGroup);
    if (response.success) {
      toast.success("QA flash set updated successfully!");
      let updatedQASetGroup = response.payload;
      let newQASetGroups = [...qASetGroups];
      let index = newQASetGroups.findIndex((thisSet: QASetGroup) => thisSet.id === qASetGroup.id);
      if (index === -1) {
        newQASetGroups.push(updatedQASetGroup);
      } else {
        newQASetGroups[index] = updatedQASetGroup;
      }
      setQASetGroups(newQASetGroups);
      setShowCreateQASetGroupModal(false);
    } else {
      errorService.handleError(response);
    }

  }

  const handleCreateFlashSet = (type: string) => {
    // if (selectedCourseTemplate === null) {
    //   toast.error("Please select a course template first");
    //   return;
    // }

    if (type === 'word') {
      let newWordSetGroup = new WordSetGroup(courseId, '', [new WordSet([], 'Set 1')]);
      //TODO: add isPublic on course?
      newWordSetGroup.isPublic = true;
      setTempWordSetGroup(newWordSetGroup);
      setShowCreateWordSetGroupModal(true);
    } else if (type === 'qa') {
      let newQASetGroup = new QASetGroup(courseId, '');
      newQASetGroup.isPublic = true;
      setTempQASetGroup(newQASetGroup);
      setShowCreateQASetGroupModal(true);
    }
  }

  const handleCloneFlashSet = async (type: string, index: number, toCourseTemplateId: string) => {
    // setCurrentAction(new CurrentAction('cloning', type === 'word' ? wordSetGroups[index].id : qASetGroups[index].id));
    // if (type === 'word') {
    //   let response = await apiService.get(config.apiUrl + '/cms/CloneWordSetGroup/' + wordSetGroups[index].id + '/' + toCourseTemplateId);
    //   if (response.success) {
    //     toast.success("Word flash set cloned successfully!");
    //     if (toCourseTemplateId === courseId) {
    //       let newWordSetGroups = [...wordSetGroups];
    //       newWordSetGroups.push(response.payload);
    //       setWordSetGroups(newWordSetGroups);
    //     }
    //   } else {
    //     errorService.handleError(response);
    //   }
    //   setCurrentAction(new CurrentAction('none', ''));
    // } else if (type === 'qa') {
    //   let response = await apiService.get(config.apiUrl + '/cms/CloneQASetGroup/' + qASetGroups[index].id + '/' + toCourseTemplateId);
    //   if (response.success) {
    //     toast.success("QA flash set cloned successfully!");
    //     if (toCourseTemplateId === courseId) {
    //       let newQASetGroups = [...qASetGroups];
    //       newQASetGroups.push(response.payload);
    //       setQASetGroups(newQASetGroups);
    //     }
    //   } else {
    //     errorService.handleError(response);
    //   }
    //   setCurrentAction(new CurrentAction('none', ''));
    // }

  }

  const handleEditFlashSet = (type: string, index: number) => {
    if (type === 'word') {
      setTempWordSetGroup(wordSetGroups[index]);
      setShowCreateWordSetGroupModal(true);
    } else if (type === 'qa') {
      setTempQASetGroup(qASetGroups[index]);
      setShowCreateQASetGroupModal(true);
    }
  }

  // const handleDeleteCourseTemplate = async () => {
  //   setCurrentAction(new CurrentAction('deleting', deleteID));
  //   //let response = await apiService.del(config.apiUrl + '/cms/DeleteCourseTemplate/' + deleteID);
  //   let filter = new FilterModel([new PropertyFilter('Id', deleteID)]);
  //   let response = await crudService.delete(Class.courseTemplate, filter);

  //   if (response.success) {
  //     toast.success("Course template deleted successfully!");
  //     let newCourseTemplates = [...courseTemplates];
  //     let index = newCourseTemplates.findIndex((courseTemplate: CourseTemplate) => courseTemplate.id === deleteID);
  //     newCourseTemplates.splice(index, 1);
  //     setCourseTemplates(newCourseTemplates);

  //     //remove word flash games associated with this course template
  //     //TODO: should be able to remove this because flashsets are loaded on course template open
  //     let newWordSetGroups = [...wordSetGroups];
  //     newWordSetGroups = newWordSetGroups.filter((wordFlashSet: WordSetGroup) => wordFlashSet.courseId !== deleteID);
  //     setWordSetGroups(newWordSetGroups);

  //   } else {
  //     errorService.handleError(response);
  //   }
  //   setCurrentAction(new CurrentAction('none', ''));
  // }

  const handleDeleteWordSetGroup = async () => {
    setCurrentAction(new CurrentAction('deleting', deleteID));
    //let response = await apiService.del(config.apiUrl + '/cms/DeleteWordSetGroup/' + deleteID);
    let filter = new FilterModel([new PropertyFilter('Id', deleteID)]);
    let response = await crudService.del(Class.wordSetGroup, filter);
    if (response.success) {
      toast.success("Word flash game deleted successfully!");
      let newWordSetGroups = [...wordSetGroups];
      let index = newWordSetGroups.findIndex((wordFlashSet: WordSetGroup) => wordFlashSet.id === deleteID);
      newWordSetGroups.splice(index, 1);
      setWordSetGroups(newWordSetGroups);
    } else {
      errorService.handleError(response);
    }
    setCurrentAction(new CurrentAction('none', ''));
  }

  const handleDeleteQASetGroup = async () => {
    setCurrentAction(new CurrentAction('deleting', deleteID));
    let filter = new FilterModel([new PropertyFilter('Id', deleteID)]);
    let response = await crudService.del(Class.wordSetGroup, filter);
    if (response.success) {
      toast.success("QA flash game deleted successfully!");
      let newQASetGroups = [...qASetGroups];
      let index = newQASetGroups.findIndex((qaFlashSet: QASetGroup) => qaFlashSet.id === deleteID);
      newQASetGroups.splice(index, 1);
      setQASetGroups(newQASetGroups);
    } else {
      errorService.handleError(response);
    }
    setCurrentAction(new CurrentAction('none', ''));
  }

  const handleDelete = async () => {
    if (deleteType === 'course') {
      //handleDeleteCourseTemplate();
    } else if (deleteType === 'word') {
      handleDeleteWordSetGroup();
    } else if (deleteType === 'qa') {
      handleDeleteQASetGroup();
    }

  }

  //=================================================================================================== FUNCTIONS

  // const loadCourseTemplate = async (courseTemplate: CourseTemplate) => {
  //   setCurrentAction(new CurrentAction('loading', 'games'));
  //   let newPathItem = new BreadcrumbItem(courseTemplate.name, '', null);
  //   if (!selectedCourseTemplate) {
  //     setPath(path.concat(newPathItem));
  //   } else {
  //     setPath(path.slice(0, path.length - 1).concat(newPathItem));
  //   }
  //   setSelectedCourseTemplate(courseTemplate);

  //   let response = await apiService.get(config.apiUrl + '/cms/GetGamesByCourseTemplateId/' + courseTemplate.id);
  //   if (response.success) {
  //     //setQuestionAnswerFlashGames(response.payload.qASetGroups);
  //     setQASetGroups(response.payload.qaFlashSets);
  //     setWordSetGroups(response.payload.wordSetGroups);
  //   } else {
  //     //toast.error(response.message);
  //     errorService.handleError(response);
  //   }
  //   setCurrentAction(currentAction.reset());
  // }

  const startFlashGame = async (type: string, index: number) => {
    if (type === 'qa') {
      //navigate('/wordflash/run', { state: { gameType: 'qa', gameObject: qASetGroups[index] } });
      navigate('/wordflash/stage/qa/' + qASetGroups[index].id);
    }
    if (type === 'word') {
      //navigate('/wordflash/run', { state: { gameType: 'word', gameObject: wordSetGroups[index] } });
      navigate('/wordflash/stage/word/' + wordSetGroups[index].id);
    }
  }

  //=================================================================================================== HELPER

  //=================================================================================================== UI TOOLBARS AND MODALS

  // const renderBreadcrumbs = () => {
  //   return (
  //     <div>
  //       <Breadcrumb className="bg-gray-50 px-5 py-6 dark:bg-gray-800 mb-3">
  //         {path.map((item: BreadcrumbItem, index: number) => (
  //           <Breadcrumb.Item className='text-2xl' key={item.name} href={item.path} icon={item.icon}>
  //             {item.name}
  //           </Breadcrumb.Item>
  //         ))}
  //       </Breadcrumb>
  //     </div>
  //   );
  // }

  // const renderFilterToolbar = () => {
  //   return (
  //     <div className='grid grid-cols-3'>

  //       {!selectedCourseTemplate && <div className='flex align-middle mb-3'>         
  //         <Button size='sm' pill outline={!filterPublic}
  //           className={filterPublic ? 'ml-3 text-2xl bg-col-s1 mr-3' : 'text-xs bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
  //           onClick={() => { applyFilters(!filterPublic, filterPrivate); setFilterPublic(!filterPublic) }}
  //         ><span className='mr-3'>{courseIcons.renderOpenHandsIcon(24, 24)}</span> Public Courses</Button>
  //         <Button size='sm' pill outline={!filterPrivate}
  //           className={filterPrivate ? 'bg-col-s1 mr-3' : 'bg-col-p2 mr-3 line-through decoration-col-p2 decoration-2'}
  //           onClick={() => { applyFilters(filterPublic, !filterPrivate); setFilterPrivate(!filterPrivate) }}
  //         ><span className='mr-3'>{courseIcons.renderLockedIcon(24, 24)}</span>Your Courses</Button>
  //       </div>}
  //       {selectedCourseTemplate && <h1 className='text-xl md:text-2xl my-2 font-bold'>Course: {selectedCourseTemplate.name}</h1>}
  //       <div></div>
  //       {hasEditingPrivileges && <div className='relative h-10'>
  //         <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
  //       </div>}
  //     </div>
  //   )
  // }

  const renderConfirmDeleteModal = () => {
    return (<Modal show={showConfirmDeleteModal} size="md" onClose={() => setShowConfirmDeleteModal(false)} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {deleteType === 'course' ?
              'Are you sure you want to delete this course template? All associated word flash sets will also be deleted.'
              : 'Are you sure you want to delete this Wordflash set?'}
          </h3>
          <div className="flex justify-center gap-4">
            {/* <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); deleteType === 'template' ? handleDeleteCourseTemplate() : handleDeleteWordSetGroup() }}> */}
            <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); handleDelete() }}>
              {"Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => setShowConfirmDeleteModal(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>);
  }


  const renderQRCodeModal = () => {
    return (
      <Modal show={showQRModal} size="6xl" onClose={() => { setShowQRModal(false) }} popup>
        <Modal.Header>Launch Flashset Game</Modal.Header>
        <Modal.Body>
          <div className='flex flex-col items-center'>
            <QRCode value={qrCodeLink} />
            <p className='text-3xl my-6'>Share this link if you want your students to launch individual games</p>
            <p>{qrCodeLink}</p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  //render icons and information for the relevant card
  const renderCourseInfoBar = (type: string, object: CourseTemplate) => {

    // if (type === 'course') {
    //   return (
    //     <div className='grid grid-cols-3 place-items-center'>
    //       <div className='border-4 p-2 border-col-p1'>{courseIcons.renderCourseIcon()}</div>
    //       {/* <div className='inline-flex'>{courseIcons.renderBrainIcon()}</div> */}
    //       {!object.isPublic && <Tooltip className="transition-opacity delay-700" content="Private">
    //         <div>{courseIcons.renderLockedIcon()}</div>
    //       </Tooltip>}
    //       {object.isPublic && <Tooltip className="transition-opacity delay-700" content="Publicly accessible">
    //         <div>{courseIcons.renderOpenHandsIcon()}</div>
    //       </Tooltip>}
    //       {object.ownerId === user.id && <Tooltip className="transition-opacity delay-700" content="Yours!">
    //         <Avatar className='cursor-default' alt="Owner" placeholderInitials={user?.initials} rounded />
    //       </Tooltip>}
    //     </div>
    //   )
    // }

  }

  // const renderCreateCourseTemplateModal = () => {

  //   return (
  //     <Modal show={showCreateCourseTemplateModal} onClose={() => setShowCreateCourseTemplateModal(false)}>
  //       <Modal.Header>Create a new Course Template</Modal.Header>
  //       <Modal.Body>
  //         <div className='flex flex-col'>
  //           <div className='mb-3'>
  //             <span>Title </span>
  //             <TextInput type='text' placeholder='Title' value={newCourseTemplate.name} onChange={(e) => setNewCourseTemplate({ ...newCourseTemplate, title: e.target.value })} />
  //           </div>
  //           <div className='mb-5'>
  //             <span>Description </span>
  //             <TextInput type='text' placeholder='Description (optional)' value={newCourseTemplate.description} onChange={(e) => setNewCourseTemplate({ ...newCourseTemplate, description: e.target.value })} />
  //           </div>
  //           <ToggleSwitch
  //             checked={newCourseTemplate.isPublic}
  //             label={newCourseTemplate.isPublic ? "Course template is public. Anyone can see it." : "Course template is private. Only you can see it."}
  //             onChange={() => setNewCourseTemplate({ ...newCourseTemplate, isPublic: !newCourseTemplate.isPublic })}
  //           />
  //         </div>

  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button
  //           disabled={newCourseTemplate.name === ''}
  //           onClick={() => {
  //             setShowCreateCourseTemplateModal(false);
  //             handleCreateUpdateCourseTemplate(newCourseTemplate);
  //           }
  //           }>{newCourseTemplate.id === '' ? 'Create New Course Template' : 'Update Course Template'}</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  // const renderCopyToModal = () => {

  //   return (
  //     <Modal show={showCopyToModal} size="4xl" onClose={() => { setShowCopyToModal(false) }} popup>
  //       <Modal.Header>Copy To</Modal.Header>
  //       <Modal.Body>
  //         <div className='flex flex-col items-center'>
  //           <p className='text-3xl my-6'>Which course template do you want to copy this to?</p>
  //           {/* //Create select dropdown of all course templates owned by this user. Select current course template by default */}
  //           <Select id="copy-to-modal-select" value={selectedCopyToCourseTemplateId} onChange={(e) => setSelectedCopyToCourseTemplateId(e.target.value)}>
  //             {selectedCopyToCourseTemplateId === "None" && <option value="None">Select a course template</option>}
  //             {yourCourseTemplates?.map((courseTemplate: CourseTemplate, index: number) => (
  //               <option key={courseTemplate.id} value={courseTemplate.id} >{courseTemplate.name}</option>
  //             ))}
  //           </Select>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button
  //           disabled={selectedCopyToCourseTemplateId === 'None'}
  //           onClick={() => {
  //             setShowCopyToModal(false);

  //             handleCloneFlashSet(cloneData.type, cloneData.index, selectedCopyToCourseTemplateId);
  //             // handleCreateUpdateCourseTemplate(newCourseTemplate);
  //           }
  //           }>Copy</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   )
  // }

  const renderEditToolbar = (type: string, index: number, object: any) => {

    let objectId = "";
    let ownerId = object.ownerId;
    //console.log(ownerId);
    //console.log(user.id);
    if (type === 'word') {
      objectId = wordSetGroups[index].id;
    }
    if (type === 'qa') {
      objectId = qASetGroups[index].id;
    }
    if (type === 'course') {
      objectId = courseTemplates[index].id;
    }

    const handleEdit = () => {
      if (type === 'course') {
        //handleEditCourseTemplate(index);
      }
      if (type === 'word') {
        handleEditFlashSet('word', index);
      }
      if (type === 'qa') {
        handleEditFlashSet('qa', index);
      }
    }

    const handleClone = () => {
      // let val = selectedCourseTemplate?.isPublic ? "None" : courseId;
      // if (val === undefined) val = "None";
      // setSelectedCopyToCourseTemplateId(val);

      if (type === 'course') {
        //handleCloneCourseTemplate(type, index);
      }
      if (type === 'word' || type === 'qa') {
        setCloneData(new CloneData(type, index));
        setShowCopyToModal(true);
      }
    }

    return (
      <>
        <div className='grid grid-cols-3 place-items-center'>

          {ownerId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Edit">
              <Button
                size='xs' className='bg-col-p2' outline
                disabled={currentAction.id === objectId}
                onClick={() => handleEdit()}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isEditing)) && courseIcons.renderEditIcon()}
                {currentAction.id === objectId && currentAction.isEditing && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>}

          <Tooltip className="transition-opacity delay-700" content="Copy to">
            <Button
              size='xs' className='bg-col-p2' outline
              disabled={currentAction.id === objectId}
              onClick={() => { handleClone() }}>
              {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isCloning)) && courseIcons.renderCopyIcon()}
              {currentAction.id === objectId && currentAction.isCloning && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
            </Button>
          </Tooltip>

          {ownerId === user.id &&
            <Tooltip className="transition-opacity delay-700" content="Delete">
              <Button
                size='xs' className='' outline color='failure'
                disabled={currentAction.id === objectId}
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                  setDeleteID(objectId);
                  setDeleteType(type);
                }}>
                {(currentAction.id !== objectId || (currentAction.id === objectId && !currentAction.isDeleting)) && courseIcons.renderDeleteIcon()}
                {currentAction.id === objectId && currentAction.isDeleting && <HiOutlineExclamationCircle className='animate-spin h-6 w-6 text-red-500' />}
              </Button>
            </Tooltip>}
        </div>
      </>
    )
  }

  const renderLoadingCard = () => {
    return (<Card className="max-w-md border-dotted cursor-pointer">
      <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>Loading...</span>
    </Card>)
  }

  //=================================================================================================== RENDER

  return (
    <div className='container mx-auto'>

      {/* //TODO: Convert to components */}
      {/* {renderBreadcrumbs()}
      {renderFilterToolbar()} */}

      {editingOn && !user.loggedIn && <Alert className='my-8 text-sm md:text-lg' color="failure" icon={HiInformationCircle}>
        <span className="text-sm md:text-lg">Info alert!</span> You are not logged in so you cannot create new course templates or flash sets.
        Log in or create an account to continue. <a href='/accounts/login' className='underline'>Click here to log in</a>
      </Alert>}

      {/* {!courseId &&
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {currentAction.isLoading && currentAction.id === 'courses' && renderLoadingCard()}
          {courseTemplates && courseTemplates.map((courseTemplate: CourseTemplate, index: number) => (
            <Card className="max-w-md flex" key={courseTemplate.name + index}>
              {renderCourseInfoBar('course', courseTemplate)}
              <div className='flex'>
                <h5 className='text-lg h-16 md:text-2xl font-bold'>{courseTemplate.name}</h5>
              </div>

              <div className='h-32'>
                <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal">{courseTemplate.description}</div>
              </div>
              <Button className="bg-col-p2" onClick={() => loadCourseTemplate(courseTemplate)}>Open</Button>
              {editingOn && renderEditToolbar('course', index, courseTemplate)}
            </Card>

          ))}
          {editingOn && <Card className="max-w-sm border-dotted cursor-pointer" onClick={() => setShowCreateCourseTemplateModal(true)}>
            <span className='inline-flex text-lg min-h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new course template'}
              {!user.loggedIn && 'Log in to create new course templates'}
            </span>
          </Card>}
        </div>} */}
      {isBusy.isLoading && <div>Loading...</div>}
      {!isBusy.isLoading && <div>
        {/* <h1 className='text-xl md:text-2xl my-2'>{selectedCourseTemplate.name}</h1> */}

        {/* ======================================================================= WORDFLASH SETS */}

        <h2 className='text-lg md:text-2xl my-2 font-bold'>Wordflash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg:grid-col-6'>
          {currentAction.isLoading && currentAction.id === 'games' && renderLoadingCard()}
          {wordSetGroups && wordSetGroups.map((wordset: WordSetGroup, index: number) => (
            <Card className="max-w-sm" key={wordset.name + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderBrainIcon()}
                </span>
                <span className='inline-flex text-lg h-16 md:text-2xl font-bold'>{wordset.name}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinWordSetGroupWords(wordset)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/word/' + wordset.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className='bg-col-p2' onClick={() => startFlashGame('word', index)}>Launch</Button>

              {editingOn && renderEditToolbar('word', index, wordset)}
            </Card>

          ))}

          {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('word')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new wordflash set'}
              {!user.loggedIn && 'Log in to create wordflash sets'}
            </span>
          </Card>}
        </div>

        {/* ======================================================================= QA FLASH SETS */}
        <hr className="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        <h2 className='text-lg md:text-2xl my-2 font-bold'>Question Answer Flash Sets</h2>
        <div className='grid grid-cols-2 gap-2 md:grid-cols-6 lg: grid-col-6'>
          {qASetGroups && qASetGroups.map((qaSet: QASetGroup, index: number) => (
            <Card className="max-w-sm" key={qaSet.name + index}>
              <div className="flex">
                <span className='inline-flex mr-3'>
                  {courseIcons.renderSpeechIcon()}
                </span>
                <span className='inline-flex  text-lg h-16 md:text-2xl font-bold'>{qaSet.name}</span>
              </div>
              <div className="truncate overflow-hidden leading-snug h-16 whitespace-normal ml-3">{flashSetLogic.joinQASets(qaSet)}</div>
              {/* //QR Code Icon */}
              <div
                className='cursor-pointer mx-auto'
                onClick={() => { setQRCodeLink(baseLink + '/qa/' + qaSet.id); setShowQRModal(true) }}
              >{courseIcons.renderQRCodeIcon(32, 32)}
              </div>
              {/* //Launch button */}
              <Button className="bg-col-p2" onClick={() => startFlashGame('qa', index)}>Launch</Button>
              {editingOn && renderEditToolbar('qa', index, qaSet)}
            </Card>
          ))}

          {editingOn && <Card className="max-w-sm min-h-64 border-dotted cursor-pointer" onClick={() => handleCreateFlashSet('qa')}>
            <span className='inline-flex  text-lg h-16 md:text-2xl font-bold text-center text-gray-400'>
              {user.loggedIn && 'Create new question/answer set'}
              {!user.loggedIn && 'Log in to create question/answer sets'}
            </span>
          </Card>}
        </div>
      </div>}

      {/* {renderCreateCourseTemplateModal()} */}
      {renderConfirmDeleteModal()}
      {renderQRCodeModal()}
      {/* {renderCopyToModal()} */}
      {showCreateWordSetGroupModal &&
        <CreateWordSetGroupModal
          showModal={showCreateWordSetGroupModal}
          setShowModal={setShowCreateWordSetGroupModal}
          wordFlashSet={tempWordSetGroup}
          handlePersistWordSetGroup={handlePersistWordSetGroup}
        />}
      {showCreateQASetGroupModal &&
        <CreateQASetGroupModal
          showModal={showCreateQASetGroupModal}
          setShowModal={setShowCreateQASetGroupModal}
          qAFlashSet={tempQASetGroup}
          handlePersistWordSetGroup={handlePersistQASetGroup}
        />}

    </div>
  );


}

export default WordSetConstructor;