import { Status } from "classes/enums/Status";
import createGUID from "logic/utility/createGUID";
import { CodeExerciseSubmissionGrade } from "./CodeExerciseSubmissionGrade";
import { StatusCode } from "classes/enums/StatusCode";

class CodeExerciseSubmission {
    public id: string = createGUID(10);
    //TODO: can this be fixed?
    public nodeCourseId: string = "";
    public courseId: string = "";
    public ownerId: string = "";
    public codeExerciseId: string = "";
    public challengeId: string = "";
    public sourceCode: string = "";
    public pointsAwarded: number = 0;
    public isSubmitted: boolean = false;
    public dateSubmitted: number = 0;
    public grades: CodeExerciseSubmissionGrade[] = [];
    statusCode: StatusCode = StatusCode.new;

    public status: Status = Status.unchanged; //client side
}

export { CodeExerciseSubmission };