//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import { useState, useEffect } from "react";

//TODO: Re-check AbortController for latest version of React
const useS3Fetch = (url, data) => {

    const [payload, setPayload] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsPending(true);
       
        fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
              },
        })
        .then((response) => {return response.json()})
        .then((data) => {
            setIsPending(false);   
            setPayload(data);    
          })
        .catch((error) => {
            setError(error);
        });
      
    }, [url])


    return {payload, isPending, error}
}

export default useS3Fetch;
