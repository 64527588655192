//React
import { useEffect } from "react";

//UI
import { Datepicker, Button } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';

//Logics
import time from 'logic/utility/time';
import parseObjectUpdate from 'logic/parse/parseObjectUpdate';

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType'
import { CourseChallenge } from "classes/course/CourseChallenge";
import { GenericPropertyUpdate } from "classes/gridslate/GenericPropertyUpdate";
import { CourseContent } from "classes/course/CourseContent";
import { CourseContentType } from "classes/enums/CourseContentType";

//Components
import { NodeViewWrapper } from '@tiptap/react'

export default (props: any) => {

    const [nodeCourse] = useAtom(courseAtom);

    //Get challenge information 
    let courseChallenge = props.node.attrs.courseChallenge as CourseChallenge;

    useEffect(() => {
        if (courseChallenge.courseContentId !== "none") {
            //Update checkpoints from courseContent if it exists
            //TODO: work out why this doesn't work
            //updateCheckpoints();
        }
    }, []);

    const updateCheckpoints = () => {
        let courseContent = nodeCourse.courseContents.find((x: CourseContent) => x.id === courseChallenge.courseContentId);
        if (courseContent) {
            //To prevent tiptap flush sync error
            setTimeout(() => {
                updateCourseChallenge("checkpoints", courseContent?.checkpoints);
            }, 200);

        }
    }

    const updateCourseChallenge = (target: string, value: any) => {
        let newCourseChallenge = { ...courseChallenge };
        newCourseChallenge = parseObjectUpdate(newCourseChallenge, [new GenericPropertyUpdate(target, value)]);
        props.updateAttributes({
            courseChallenge: newCourseChallenge,
        });
    }

    const editCourseContentSelection = (courseContentId: string) => {

        let updates = [] as GenericPropertyUpdate[];
        updates.push(new GenericPropertyUpdate("courseContentId", courseContentId));

        //Get information from the selected course content, if it exists
        let selectedCourseContent = nodeCourse.courseContents.find((courseContent: CourseContent) => courseContent.id === courseContentId);
        if (selectedCourseContent) {
            updates.push(new GenericPropertyUpdate("name", selectedCourseContent.name));
            updates.push(new GenericPropertyUpdate("checkpoints", selectedCourseContent.checkpoints));
        }

        let newCourseChallenge = { ...courseChallenge };

        newCourseChallenge = parseObjectUpdate(newCourseChallenge, updates);
        props.updateAttributes({
            courseChallenge: newCourseChallenge,
        });
    }

    const getCourseTexts = () => {
        let courseTexts = [] as CourseContent[];

        for (let courseContent of nodeCourse.courseContents) {
            if (courseContent.type === CourseContentType.Text) {
                courseTexts.push(courseContent);
            }
        }

        return courseTexts;
    }

    const getStartDate = () => {
        let returnedDate = time.toDate(courseChallenge.startDate);
        return returnedDate;
    }

    const getDueDate = () => {
        let returnedDate = time.toDate(courseChallenge.dueDate);
        return returnedDate;
    }

    const setDueDate = (date: any) => {
        updateCourseChallenge("dueDate", time.toUTC(date));
    }

    const setStartDate = (date: any) => {
        updateCourseChallenge("startDate", time.toUTC(date));
    }

    return (
        <NodeViewWrapper className={ExtensionType.CourseTextChallenge} data-localid={props.node.attrs.elementId}>
            <label>Text Challenge</label>
            {/* <div>{JSON.stringify(props.node.attrs.courseChallenge)}</div> */}
            <div>

                <div className="content">
                    <div className="flex gap-2">
                        Select Text:
                        <select value={courseChallenge.courseContentId} onChange={(e) => {
                            editCourseContentSelection(e.target.value);
                        }}>
                            <option value="none">None selected</option>
                            {getCourseTexts().map((courseText, index) => {
                                return <option key={index} value={courseText.id}>{courseText.name}</option>
                            })}
                        </select>
                        <Button onClick={() => updateCheckpoints()}>Update Checkpoints</Button>
                    </div>
                </div>

                {courseChallenge.courseContentId !== "none" && <div className="content flex gap-2">

                    <div>Challenge Name</div>
                    <input type="text" value={courseChallenge.name} onChange={(e) => updateCourseChallenge("name", e.target.value)} />
                    <div>Start Date</div>
                    <Datepicker value={getStartDate()} onSelectedDateChanged={setStartDate} />
                    <div>Due Date</div>
                    <Datepicker value={getDueDate()} onSelectedDateChanged={setDueDate} />
                </div>}

            </div>
        </NodeViewWrapper>
    )
}