//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Tabs, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import Dropzone from 'react-dropzone';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
//import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import config from 'config';

//Logics

//Components
import GenericSaveLoadPanel from "views/utility/GenericSaveLoadPanel";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
//import { Account, getAccountProperties } from 'classes/account/Account';
import { Account, getAccountProperties } from 'classes/accounts/Account';
import { CrudAction } from 'classes/enums/CrudAction';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';
import createGUID from 'logic/utility/createGUID';

type Props = {
}

const AdminHandler = (props: Props) => {

    //const [accounts, setAccounts] = useState<Account[]>([]);
    //const [studentNumbers, setStudentNumbers] = useState<string>("");
    //const [loadedAccountIndex, setLoadedAccountIndex] = useState<number>(-1);
    const [activeTab, setActiveTab] = useState(0);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const [selectedDeployment, setSelectedDeployment] = useState<string>(config.deployment ? config.deployment : "none");
    const [collectionNames, setCollectionNames] = useState<string[]>([]);
    const [collectionObjects, setCollectionObjects] = useState<any[]>([]);
    const [showContentImportModal, setShowContentImportModal] = useState<boolean>(false);

    const mainTabsRef = useRef<TabsRef>(null);

    // useEffect(() => {

    //     const loadAccounts = async () => {
    //         let response = await apiService.get(config.apiUrl + "/admin/getAllAccounts");
    //         if (response.success) {
    //             setAccounts(response.payload);
    //         } else {
    //             errorService.handleError(response);
    //         }

    //     }

    //     if (accounts.length === 0) {
    //         loadAccounts();
    //     }

    // }, []);


    //================================================================================== - Render Account Manager

    const fetchCollectionNames = async (deployment: string) => {
        let response = await apiService.get(config.apiUrl + "/admin/getCollectionNames/" + deployment);
        if (response.success) {
            setCollectionNames(response.payload);
        } else {
            errorService.handleError(response);
        }
    }

    const fetchCollectionObjects = async (name: string) => {
        let postObject = {
            deployment: selectedDeployment,
            collectionName: name,
            numberOfObjects: 10
        }
        let response = await apiService.post(config.apiUrl + "/admin/getCollectionObjects", postObject);
        if (response.success) {
            setCollectionObjects(response.payload);
        } else {
            errorService.handleError(response);
        }
    }


    const setDeploymentSelection = (deployment: string) => {
        setSelectedDeployment(deployment);
        fetchCollectionNames(deployment);

    }

    const getDatabaseAsJSON = async () => {
        let response = await apiService.get(config.apiUrl + "/admin/GetDatabaseAsJSON/" + selectedDeployment);
        if (response.success) {
            console.log(response.payload);
            let obj1 = JSON.parse(response.payload);
            console.log(obj1);
            //Create json file from response.payload
            var element = document.createElement('a');
            //element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(response.payload)));
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.payload));
            element.setAttribute('download', selectedDeployment + '.json');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            toast.success("Deployment backed up");
        } else {
            errorService.handleError(response);
        }
    }

    const getJSONFileString = (acceptedFiles: any) => {
        let jsonFile = acceptedFiles[0];

        //Open file and read as text
        let reader = new FileReader();
        reader.addEventListener(
            "load",
            () => {
                if (reader.result !== null && typeof reader.result === "string") {
                    //let obj2 = JSON.parse(reader.result);
                    //console.log(obj2);
                    loadDatabaseFromJSON(reader.result);
                } else {
                    console.error("Error reading file");
                }
            },
            false,
        );

        reader.readAsText(jsonFile);

    }

    const loadDatabaseFromJSON = async (JSONString: string) => {

        let request = {
            "JSONString": JSONString
        }
        let response = await apiService.post(config.apiUrl + "/admin/LoadDatabaseFromJSON", request);
        if (response.success) {
            toast.success("Deployment loaded from backup");
        } else {
            errorService.handleError(response);
        }

    }

    const renderContentImportModal = () => {

        //TODO: Add options here for type of import: prepend, append, replace
        //const [contentImportType, setContentImportType] = useState<CourseChallengeType>(CourseChallengeType.Text);

        return (<Modal show={showContentImportModal} size="3xl" onClose={() => setShowContentImportModal(false)} popup>
            <Modal.Header>
                <div>Import JSON backup file</div>
            </Modal.Header>
            <Modal.Body>
                <Dropzone onDrop={(acceptedFiles) => {
                    setShowContentImportModal(false);
                    getJSONFileString(acceptedFiles);
                }}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()} className="flex w-full items-center justify-center h-32 cursor-pointer">
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={() => setShowContentImportModal(false)}>Close</Button> */}
                <Button onClick={() => setShowContentImportModal(false)}>Close</Button>
            </Modal.Footer>

        </Modal>
        )
    }

    const restructureObjects = async () => {
        let response = await apiService.post(config.apiUrl + "/admin/restructureObjects", {});
        if (response.success) {
            console.log(response.payload);
            toast.success("Objects restructured");
        } else {
            errorService.handleError(response);
        }
    }

    return (
        <div className='container mx-auto'>
            <div>Current deployment: {config.deployment}</div>
            <div>Selected deployment: {selectedDeployment}</div>
            <select value={selectedDeployment} onChange={(e) => setDeploymentSelection(e.target.value)}>
                <option value="development">Development</option>
                <option value="testing">Testing</option>
                <option value="production">Production</option>
            </select>

            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="Collections">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <h2 className="text-2xl font-semibold">Collections</h2>
                            <ul>
                                {collectionNames.map((name, index) => {
                                    return <li key={index} onClick={() => fetchCollectionObjects(name)}>{name}</li>
                                })}
                            </ul>
                        </div>
                        <div>
                            <h2 className="text-2xl font-semibold">Objects</h2>
                            <ul>
                                {collectionObjects.map((object, index) => {
                                    return <li key={index}>{JSON.stringify(object)}</li>
                                })}
                            </ul>
                        </div>
                    </div>


                </Tabs.Item>

                <Tabs.Item title="Database">
                    <Button onClick={() => getDatabaseAsJSON()}>{`Get Database(${selectedDeployment}) as Json file`}</Button>
                    <Button onClick={() => setShowContentImportModal(true)}>Replace current deployment from Json file</Button>
                </Tabs.Item>

                <Tabs.Item title="Permissions / Roles">
                </Tabs.Item>
                <Tabs.Item title="Restructure">
                    <div>{createGUID(10)}</div>
                    <Button onClick={() => restructureObjects()}>{`Restructure Objects`}</Button>
                </Tabs.Item>
            </Tabs>

            {showContentImportModal && renderContentImportModal()}

        </div>
    )

}

export default AdminHandler;