//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import http from "./httpService";
import config from "config";
import jwtDecode from "jwt-decode";

const apiEndpoint = config.apiUrl + "accounts/";

export function checkAccessTokenStillValid() {
  const expiration = localStorage.getItem("expirationTime");
  if (expiration === "null" || expiration === undefined) return false;
  if (Date.now() / 1000 >= expiration) return false;
  else return true;
}

export async function refreshAccessToken() {

  const refreshToken = localStorage.getItem("refreshToken");
  const { data: result } = await http.post(apiEndpoint + "refreshaccesstoken", {
    RefreshToken: refreshToken
  });

  return result;
}

export function decodeToken() {
  const accessToken = localStorage.getItem("accessToken");
  let user = null;

  try {
    const decodedToken = jwtDecode(accessToken);
    user = {
      username: decodedToken["use"],
      email: decodedToken["ema"],
      claims: decodedToken["rol"],
      tes: decodedToken["tes"],
      id: decodedToken["sub"],
      refreshExpirationTime: decodedToken["exp"] * 1000,
      handle: decodedToken["han"],
      fullname: decodedToken["ful"],
    };

    //console.log(user);

    // console.log(
    //   new Intl.DateTimeFormat("en-US", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   })
    //     .format(decodedToken["exp"] * 1000)
    // );

    return user;
  } catch (error) {

  }

}

//From https://stackoverflow.com/questions/63202266/how-to-extract-full-information-of-the-users-using-google-one-tap-signin
// export function decodeGoogleOpenAuthCredentialToken(token) {
//   var base64Url = token.split('.')[1];
//   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);

// }