//React
import { useEffect, useState } from 'react';

//UI
import { Table, Button, Modal, Textarea, Sidebar } from 'flowbite-react';
import { Row } from 'view_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import { useAtom } from 'jotai';
import { courseAtom, categoriesAtom, loadedMcqs } from 'atom';

//Logics
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "views/utility/GenericSaveLoadPanel";
import CategoryManager from "views/courseconstruction/CategoryManager";
//import CategoryManager2 from "views/courseconstruction/CategoryManager2";
import ViewCourseProgress from 'views/coursemanagement/ViewCourseProgress';
import WordSetConstructor from 'views/courseconstruction/WordSetConstructor';

//Classes
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
import { Course, getCourseProperties } from 'classes/course/Course';
import { CourseEnrollment } from 'classes/course/CourseEnrollment';
import { CrudAction } from 'classes/enums/CrudAction';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';
import { CourseContent } from 'classes/course/CourseContent';
import { CourseContentType } from "classes/enums/CourseContentType";
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';
import ContentDocumentConstructorPanel from './ContentDocumentConstructorPanel';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import createGUID from 'logic/utility/createGUID';
import McqConstructor from './McqConstructor';
import { Mcq } from 'classes/synapp/mcq/Mcq';
import CodeExerciseConstructor from './CodeExerciseConstructor';

const defaultContentDocument = {
    "type": "doc",
    "content": [
        {
            "type": "heading",
            "attrs": {
                "elementId": createGUID(10),
                "level": 1
            },
            "content": [
                {
                    "type": "text",
                    "text": "Default Content Document"
                }
            ]
        }
    ]
};

type Props = {
}

const CourseConstructor = (props: Props) => {

    const [courses, setCourses] = useState<Course[]>([]);
    //TODO: change to loadedCourse
    const [nodeCourse, setCourse] = useAtom(courseAtom);
    const [categories, setCategories] = useAtom(categoriesAtom);
    const [mcqs, setMcqs] = useAtom(loadedMcqs);
    //const [contentToEdit, setContentToEdit] = useState<CourseContent>(new CourseContent());
    const [courseEnrollments, setCourseEnrollments] = useState<CourseEnrollment[]>([]);
    const [sideMenuSelection, setSideMenuSelection] = useState<string>("Course");
    const [loadedCourseIndex, setLoadedCourseIndex] = useState<number>(-1);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const [newContentType, setNewContentType] = useState<CourseContentType>(CourseContentType.Page);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);

    const [pageConstructorOpen, setPageConstructorOpen] = useState(false);
    const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);

    //MODALS
    const [showEnrollUsersModal, setShowEnrollUsersModal] = useState(false);
    const [showCreateNewCourseContent, setShowCreateNewCourseContent] = useState(false);

    useEffect(() => {

        const loadCourses = () => {
            setIsBusy(isBusy.loading(["courses"]));
            let filterModel = new FilterModel();
            filterModel.onlyOwner = true;
            crudService.get(Class.course, filterModel).then(response => {
                if (response.success) {
                    //set status to unchanged
                    let loadedCourses = response.payload as Course[];
                    loadedCourses.forEach(course => {
                        course.status = Status.unchanged;
                    });
                    setCourses(loadedCourses);
                } else {
                    errorService.handleError(response);
                }
                setIsBusy(isBusy.loaded("courses"));
            });
        }

        if (courses.length === 0) {
            loadCourses();
        }

    }, []);

    const loadCourseEnrollments = async (courseId: string) => {
        setIsBusy(isBusy.loading(["courseEnrollments"]));

        let filterModel = new FilterModel([new PropertyFilter("CourseId", courseId)]);
        let response = await crudService.get(Class.courseEnrollment, filterModel);
        if (response.success) {
            setCourseEnrollments(response.payload as CourseEnrollment[]);
        } else {
            errorService.handleError(response);
        }
        setIsBusy(isBusy.loaded("courseEnrollments"));
    }

    useEffect(() => {
        const loadCategories = async () => {
            let filterModel = new FilterModel();
            crudService.get(Class.category, filterModel).then(response => {
                if (response.success) {
                    setCategories(response.payload);
                } else {
                    errorService.handleError(response);
                }

            });
        }

        if (categories.length === 0) {
            loadCategories();
        }

    }, []);

    const loadMcqs = async () => {
        if (mcqs.length === 0) {
            let filter = new FilterModel();
            let response = await crudService.get(Class.mcq, filter);
            if (response.success) {
                let newMcqs = response.payload as Mcq[];
                for (let mcq of newMcqs) {
                    mcq.status = Status.unchanged;
                    if (!mcq.isReviewed) {
                        mcq.isReviewed = false;
                    }
                }
                setMcqs(response.payload);
            } else {
                errorService.handleError(response);
            }
        }
    }

    //================================================================================================= - Edit Course

    const crudActions = (action: CrudAction, value: any, target?: string) => {
        if (action === CrudAction.appendNewObject) {
            //expect value as new course object
            var newCourses = [...courses, value];
            setLoadedCourseIndex(newCourses.length - 1);
            setCourse(value);
            setCourses(newCourses);
        }
        if (action === CrudAction.getUpdatedObject) {
            return nodeCourse;
        }
        else if (action === CrudAction.returnNewObject) {
            let newCourse = new Course();
            return newCourse;
        }
        else if (action === CrudAction.loadObject) {
            //value is actually id of object here
            //console.log(value);
            let index = courses.findIndex((course) => course.id === value);
            let loadedCourse = courses[index];
            loadedCourse.status = Status.unchanged;
            setCourse(loadedCourse);
            setLoadedCourseIndex(index);
            loadCourseEnrollments(value);

        }
        else if (action === CrudAction.updateObject) {
            //value as Course object
            let newCourse = value as Course;
            var tempCourses = [...courses];
            tempCourses[loadedCourseIndex] = newCourse;
            setCourses(tempCourses);
            toast.success("Course Updated");
        }
        else if (action === CrudAction.updateObjectProperties) {
            let editableProperties = value as EditablePropertyMetadata[];
            let newCourses = [...courses];
            let newCourse = { ...newCourses[loadedCourseIndex] };
            newCourse = parseLogic.parseObjectUpdate(newCourse, editableProperties);
            newCourse.status = Status.updated;
            newCourses[loadedCourseIndex] = newCourse;
            setCourses(newCourses);
            setCourse(newCourse);
        }

        else if (action === CrudAction.deleteObject) {
            //value is actually the object here
            //But SHOULD always be currently selected object, especially if disabling selection while pending
            var tempCourses = [...courses];
            //find index of object
            let index = tempCourses.findIndex((course) => course.id === value.id);
            tempCourses.splice(index, 1);
            setCourses(tempCourses);
            setLoadedCourseIndex(-1);
            toast.success("Course Deleted");
        }

    }

    const addCourseContent = async (nodeCourseContent: CourseContent) => {

        //Add default content document if not set
        if (nodeCourseContent.contentDocument === "") {
            nodeCourseContent.contentDocument = JSON.stringify(defaultContentDocument);
        }

        //Update nodeCourse
        let newCourse = { ...nodeCourse };
        newCourse.courseContents = [...newCourse.courseContents, nodeCourseContent];
        setCourse(newCourse);

        //TODO: Consider auto persist after create        

    }

    const setContentToEdit = (content: CourseContent) => {
        setCourse({ ...nodeCourse, currentlyLoadedParent: content });
    }

    //================================================================================================= - Save/Load Course

    const handleEnrollUsers = async (studentIdsText: string) => {
        let userIdArray = studentIdsText.split("\n");
        //remove blank lines and trim
        userIdArray = userIdArray.filter(userId => userId.trim() !== "");

        let response = await apiService.post(config.apiUrl + "/Accounts/GetAccountsByStudentNumbers", userIdArray);
        if (response.success) {
            let errorFound = false;
            //Check for not found users
            for (let i = 0; i < response.payload.length; i++) {
                if (response.payload[i] === "NOT FOUND") {
                    toast.warning("User with student number " + userIdArray[i] + " not found. ");
                    errorFound = true;
                }
            }
            if (errorFound) {
                toast.error("No students enrolled");
                return;
            }
        } else {
            errorService.handleError(response);
            return;
        }

        let enrolledUsers = [] as CourseEnrollment[];

        userIdArray.forEach(studentNumber => {
            let enrollment = new CourseEnrollment();
            enrollment.studentNumber = studentNumber;
            enrollment.group = 1;
            enrollment.dateEnrolled = Date.now();
            enrollment.courseId = nodeCourse.id;
            enrolledUsers.push(enrollment);
        });

        //Add userIds
        let userIdsFromServer = response.payload as string[];
        for (let i = 0; i < enrolledUsers.length; i++) {
            enrolledUsers[i].userId = userIdsFromServer[i];
        }

        let response2 = await crudService.create(Class.courseEnrollment, enrolledUsers);
        if (response2.success) {
            toast.success("Students enrolled");
            //loadCourseEnrollments(courses[loadedCourseIndex].id);
            let newEnrollments = response2.payload as CourseEnrollment[];
            let currentCourseEnrollments = [...courseEnrollments].concat(newEnrollments);
            setCourseEnrollments(currentCourseEnrollments);
        } else {
            errorService.handleError(response2);
        }
    }

    // ================================================================================== - Render Modals

    const RenderEnrollUsersModal = () => {

        const [enrollUsersText, setEnrollUsersText] = useState("");

        return <Modal show={showEnrollUsersModal} onClose={() => setShowEnrollUsersModal(false)}>
            <Modal.Header>Enroll Users</Modal.Header>
            <Modal.Body>
                <Textarea
                    value={enrollUsersText}
                    onChange={(e) => setEnrollUsersText(e.target.value)}
                    placeholder="Enter student numbers here, one per line"
                />

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { handleEnrollUsers(enrollUsersText); setShowEnrollUsersModal(false); }}>Enroll</Button>
                <Button color="gray" onClick={() => setShowEnrollUsersModal(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const RenderCreateNewCourseContent = () => {

        const [newCourseContent, setNewCourseContent] = useState<CourseContent>(new CourseContent(newContentType));

        useEffect(() => {
            setNewCourseContent(new CourseContent(newContentType));
        }, [showCreateNewCourseContent]);

        const setCategoryIds = (newCatIds: string[]) => {
            setNewCourseContent({ ...newCourseContent, categoryIds: newCatIds });
        }

        return <Modal show={showCreateNewCourseContent} onClose={() => setShowCreateNewCourseContent(false)}>
            <Modal.Header>Create new course content</Modal.Header>
            <Modal.Body>
                {/* //Text input for name */}
                Input a name:
                <Row>
                    <input
                        type="text"
                        value={newCourseContent.name}
                        onChange={(e) => setNewCourseContent({ ...newCourseContent, name: e.target.value })}
                        placeholder="Name"
                    />
                </Row>

                {/* //TODO: Add categoryIds for text, code, mcq */}
                <CategoryManager categoryIds={newCourseContent.categoryIds} setCategoryIds={setCategoryIds} editable={true} />

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { addCourseContent(newCourseContent); setShowCreateNewCourseContent(false); }}>Create</Button>
                <Button color="gray" onClick={() => setShowCreateNewCourseContent(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    }

    //================================================================================== - Render Components

    const duplicateContent = (content: CourseContent) => {
        let newContent = { ...content };
        newContent.id = createGUID(10);
        newContent.name += " - Copy";
        addCourseContent(newContent);
    }

    //================================================================================== - Helper Functions

    const getCoursePagesFromCourse = () => {
        let coursePages = nodeCourse.courseContents.filter(content => content.type === CourseContentType.Page);
        return coursePages;
    }

    const getTypeFromCourse = (type: CourseContentType) => {
        let objects = nodeCourse.courseContents.filter(content => content.type === type);
        return objects;
    }

    const getTypeFromChallenges = (type: CourseChallengeType) => {
        let objects = nodeCourse.courseChallenges.filter(content => content.type === type);
        return objects;
    }

    const getContentTypeCount = (type: CourseContentType) => {
        return nodeCourse.courseContents.filter(content => content.type === type).length;
    }

    const getChallengeTypeCount = (type: CourseChallengeType) => {
        return nodeCourse.courseChallenges.filter(content => content.type === type).length;
    }

    const getEnrollmentsCount = () => {
        return courseEnrollments.length;
    }

    //================================================================================== - UI Helper Functions

    const returnCourseContentClass = (type: string) => {
        let className = "p-2 rounded cursor-pointer";
        if (sideMenuSelection === type) {
            className += " bg-col-p1 hover:bg-col-p1";
        } else {
            className += " bg-col-s2 hover:bg-col-p1 hover:opacity-75";
        }
        return className;
        // let className = "p-2 rounded hover:bg-gray-300 cursor-pointer";
        // if (sideMenuSelection === type) {
        //     className += " bg-blue-200";
        // } else {
        //     className += " bg-gray-200";
        // }
        // return className;
    }

    const renderContentCard = (content: CourseContent, index: number) => {
        return (
            <div key={"content-" + index} className="border border-4 border-gray-700 p-2 w-64 h-48 m-2 rounded hover:bg-gray-300 cursor-pointer">
                <div className="m-3 text-sm md:text-lg font-bold">
                    {content.name}
                </div>

                <div className="flex flex-auto gap-2">
                    <Button onClick={() => {
                        setContentToEdit(content);
                        setPageConstructorOpen(true);
                    }}>Edit</Button>
                    <Button onClick={() => {
                        duplicateContent(content);
                    }}>Duplicate</Button>
                </div>

            </div>
        )
    }

    const renderChallengeCard = (content: CourseChallenge, index: number) => {
        return (
            <div key={"content-" + index} className="border border-4 border-gray-700 p-2 w-32 h-32 m-2 rounded hover:bg-gray-300 cursor-pointer">
                <h3>{content.name}</h3>
                <div>Duedate: {content.dueDate}</div>
                {content.type === CourseChallengeType.TextChallenge && <div>Checkpoints: {content.checkpoints.length}</div>}
                {content.type === CourseChallengeType.CodeExerciseChallenge || content.type === CourseChallengeType.McqChallenge && <div>Minimum Points: {content.minimumPointsRequired}</div>}
                {content.type === CourseChallengeType.TextChallenge && <div>Content Document: TODO SOMETHING HERE</div>}

            </div>
        )
    }


    //================================================================================== - Render Course

    const duplicateCourse = () => {
        let newCourse = { ...nodeCourse };
        newCourse.id = createGUID(10);
        newCourse.name += " - Copy";

        //Collect courseContent Ids
        // let courseContentIds = [];
        // newCourse.courseContents.forEach(content => {
        //     courseContentIds.push(content.id);
        // });

        //newCourse.courseContents = [];
        //newCourse.courseChallenges = [];
        newCourse.status = Status.new;
        newCourse.statusCode = 0;
        setCourses([...courses, newCourse]);
    }

    return (
        <div className='container mx-auto'>
            {/* {!pageConstructorOpen && nodeCourse.status === Status.loaded && <Button>Load a different new course</Button>} */}

            {!pageConstructorOpen && nodeCourse.status === Status.unloaded &&
                <GenericSaveLoadPanel
                    objectType={Class.course}
                    loadedObjectIndex={loadedCourseIndex}
                    objects={courses}
                    crudActions={crudActions}
                    saveLoadData={nodeCourse}
                    getEditableProperties={() => { return getCourseProperties(nodeCourse, getCoursePagesFromCourse()) }}
                    saveLoadLogic={genericSaveLoadLogic}
                />}

            {!pageConstructorOpen && nodeCourse.status !== Status.unloaded && <div>
                <h1 className="text-2xl font-bold">{nodeCourse.name}</h1>
                {/* <Button onClick={() => { duplicateCourse() }}>Duplicate course</Button> */}
                <Button onClick={() => { setSideMenuCollapsed(!sideMenuCollapsed) }}>Side Menu</Button>
                <div className="flex h-screen">

                    {!sideMenuCollapsed && <Sidebar>
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item className={returnCourseContentClass("master")} onClick={() => setSideMenuSelection("master")}>Master Page</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass("enrollments")} onClick={() => setSideMenuSelection("enrollments")}>Enrollments ({getEnrollmentsCount()})</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass("progress")} onClick={() => setSideMenuSelection("progress")}>Progress</Sidebar.Item>
                            </Sidebar.ItemGroup>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item className={returnCourseContentClass(CourseContentType.Page)} onClick={() => setSideMenuSelection(CourseContentType.Page)}>Course Pages ({getContentTypeCount(CourseContentType.Page)})</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass(CourseContentType.Text)} onClick={() => setSideMenuSelection(CourseContentType.Text)}>Course Texts ({getContentTypeCount(CourseContentType.Text)})</Sidebar.Item>

                            </Sidebar.ItemGroup>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item className={returnCourseContentClass(CourseChallengeType.TextChallenge)} onClick={() => setSideMenuSelection(CourseChallengeType.TextChallenge)}>Text Challenges ({getChallengeTypeCount(CourseChallengeType.TextChallenge)})</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass(CourseChallengeType.CodeExerciseChallenge)} onClick={() => setSideMenuSelection(CourseChallengeType.CodeExerciseChallenge)}>Code Challenges ({getChallengeTypeCount(CourseChallengeType.CodeExerciseChallenge)})</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass(CourseChallengeType.McqChallenge)} onClick={() => setSideMenuSelection(CourseChallengeType.McqChallenge)}>Mcq Challenges</Sidebar.Item>
                            </Sidebar.ItemGroup>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item className={returnCourseContentClass("wordsets")} onClick={() => setSideMenuSelection("wordsets")}>Wordsets</Sidebar.Item>
                                {/* <Sidebar.Item className={returnCourseContentClass("questionAnswers")} onClick={() => setSideMenuSelection("questionAnswers")}>Question/Answers</Sidebar.Item> */}
                                <Sidebar.Item className={returnCourseContentClass("mcqs")} onClick={() => { setSideMenuSelection("mcqs"); loadMcqs() }}>Mcqs</Sidebar.Item>
                                <Sidebar.Item className={returnCourseContentClass("codeExercises")} onClick={() => setSideMenuSelection("codeExercises")}>Code Exercises</Sidebar.Item>
                            </Sidebar.ItemGroup>

                        </Sidebar.Items>
                    </Sidebar>}

                    {/* <!-- Content Area --> */}
                    <div className="bg-white p-4 w-full">
                        <div id="content" className="h-full">
                            {/* <!-- Content for each selection will appear here --> */}
                            {/* <p className="text-gray-700">Select an item from the list to view its contents.</p> */}

                            {sideMenuSelection === "master" && <div className="px-4">
                                <h1 className="text-xl font-bold">Master Page</h1>

                                <div className="border border-4 border-gray-700 p-2 w-32 h-32 m-2 rounded hover:bg-gray-300 cursor-pointer">
                                    Master Page
                                    <Button onClick={() => {
                                        //setContentDocumentId(nodeCourse.masterContentDocumentId);
                                        setContentToEdit(nodeCourse.masterContentDocument);
                                        setPageConstructorOpen(true);
                                    }
                                    }>Edit</Button>
                                </div>

                            </div>}

                            {sideMenuSelection === "enrollments" && <div className="px-4">
                                <h1 className="text-xl font-bold">Enrollments</h1>
                                <Table className="table-auto">
                                    <Table.Head>

                                        {/* <Table.HeadCell>UserId</Table.HeadCell> */}
                                        <Table.HeadCell>Student Number</Table.HeadCell>
                                        <Table.HeadCell>Name</Table.HeadCell>
                                        <Table.HeadCell>Group</Table.HeadCell>

                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {/* //TODO: two identical userIds in this list */}
                                        {courseEnrollments.map((enrollment, index) => {
                                            return <Table.Row key={enrollment.userId + index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                                {/* <Table.Cell>{enrollment.userId}</Table.Cell> */}
                                                <Table.Cell>{enrollment.studentNumber}</Table.Cell>
                                                <Table.Cell>{enrollment.name}</Table.Cell>
                                                <Table.Cell>{enrollment.group}</Table.Cell>
                                            </Table.Row>

                                        })}

                                    </Table.Body>
                                </Table>

                                <Button onClick={() => { setShowEnrollUsersModal(true) }}>Enroll Users</Button>
                            </div>}

                            {sideMenuSelection === "progress" && <div className="px-4">
                                <h1 className="text-xl font-bold">Progress</h1>
                                <ViewCourseProgress courseId={nodeCourse.id} courseEnrollments={courseEnrollments} />
                            </div>}

                            {sideMenuSelection === CourseContentType.Page && <div className="px-4">
                                <h1 className="text-xl font-bold">Course Pages</h1>
                                <div className="flex">
                                    {getTypeFromCourse(CourseContentType.Page).map((page, index) => {
                                        return renderContentCard(page, index);

                                    })}
                                </div>
                                <Button onClick={() => { setNewContentType(CourseContentType.Page); setShowCreateNewCourseContent(true) }}>Create New Page</Button>
                            </div>}

                            {sideMenuSelection === CourseContentType.Text && <div className="px-4">
                                <h1 className="text-xl font-bold">Course Texts</h1>
                                <div className="flex">
                                    {getTypeFromCourse(CourseContentType.Text).map((text, index) => {
                                        return renderContentCard(text, index);

                                    })}
                                </div>
                                <Button onClick={() => { setNewContentType(CourseContentType.Text); setShowCreateNewCourseContent(true) }}>Create New Text</Button>
                            </div>}

                            {sideMenuSelection === CourseChallengeType.TextChallenge && <div className="px-4">
                                <h1 className="text-xl font-bold">Text Challenge</h1>
                                <div className="flex">
                                    {getTypeFromChallenges(CourseChallengeType.TextChallenge).map((challenge, index) => {
                                        return renderChallengeCard(challenge, index);
                                    })}
                                </div>

                            </div>}

                            {sideMenuSelection === CourseChallengeType.CodeExerciseChallenge && <div className="px-4">
                                <h1 className="text-xl font-bold">Code Challenge</h1>
                                <div className="flex">
                                    {getTypeFromChallenges(CourseChallengeType.CodeExerciseChallenge).map((challenge, index) => {
                                        return renderChallengeCard(challenge, index);
                                    })}
                                </div>
                            </div>}

                            {sideMenuSelection === "mcqs" && <div className="w-full">
                                <h1>Mcq</h1>
                                <CategoryManager categoryIds={selectedCategoryIds} setCategoryIds={setSelectedCategoryIds} editable={true} />
                                <McqConstructor baseCategoryIds={selectedCategoryIds} content={""} getContent={() => { }} />
                            </div>}

                            {sideMenuSelection === "wordsets" && <div>
                                <h1>WordSets</h1>
                                <WordSetConstructor courseId={nodeCourse.id} />
                            </div>}

                            {sideMenuSelection === "codeExercises" && <div>
                                <h1>Code Exercises</h1>
                                <CategoryManager categoryIds={selectedCategoryIds} setCategoryIds={setSelectedCategoryIds} editable={true} />
                                <CodeExerciseConstructor content={""} categoryIds={selectedCategoryIds} />
                            </div>}
                        </div>
                    </div>
                </div>

                {showEnrollUsersModal && <RenderEnrollUsersModal />}
                {showCreateNewCourseContent && <RenderCreateNewCourseContent />}
            </div>}

            {
                pageConstructorOpen && <div>

                    <Button onClick={() => setPageConstructorOpen(false)}>Back</Button>
                    {nodeCourse.currentlyLoadedParent &&
                        <ContentDocumentConstructorPanel courseContent={nodeCourse.currentlyLoadedParent} />
                    }
                </div>
            }


        </div >
    )

}

export default CourseConstructor;