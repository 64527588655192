//React
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

//Helpers/Services

//Views/Components

const NotFound = () => {

    const [timer, setTimer] = useState<any>(null);
    const [isNotFound, setIsNotFound] = useState(false);

    const notFoundReached = () => {
        setIsNotFound(true);
    }

    useEffect(() => {
        //TODO: clean up after this
        if (timer == null) {
            setTimer(window.setTimeout(notFoundReached, 2000));
        }
        return () => {
            clearTimeout(timer);
        }
    }, [timer])

    return (
        <div className="container m-auto">
            {isNotFound &&
                <div className="text-center mt-16">
                    <h2 className='text-lg md:text-3xl font-bold'>Sorry!</h2>
                    <p className='mt-3'>Page cannot be found</p>
                    <Link to="/">Click here to return <b>Home</b></Link>
                </div>
            }
            {!isNotFound && <div className="text-center mt-16">
                {/* <Spinner animation="border" /> */}
                <img className='animate-spin m-auto w-[64] h-[64]' src={require('assets/images/synapp.png')}></img>
            </div>}
        </div>
    );
}

export default NotFound;