//Button

type Props = {
    onClick: Function;
    className: string;
    size: string;
    children: JSX.Element;
}

const Button = (props: any) => {
    const { onClick, className, size, children } = props;
    return <div
        onClick={onClick}
        className={"inline-block text-center p-1 md:p-2 text-xs md:text-base border border-2 cursor-pointer border-col-s1 bg-col-s2 hover:brightness-90 font-bold text-col-p2 rounded " + className}
    >{children}</div>
}

export default Button;