//React
import React from 'react';

//UI

//Services

//Logics

//Components
import RenderNode from "views/render/RenderNode";

//Store

//Classes

type Props = {
    contentJSON: any;
}

//This function will take a nodepage JSON and parse it into HTML
const RenderPageSnippet = (props: Props) => {
    const { contentJSON } = props;
    
    //console.log("ContentJSON: ", contentJSON);

    return (
        <>
            {contentJSON.content.map((node: any, index: number) => (
                <React.Fragment key={"nodepage-top-" + index + node.attrs.elementId}>
                    <RenderNode node={node} elementId={node.attrs.elementId} enableTryIt={false}></RenderNode>
                </React.Fragment>
            ))}
        </>
    )
}

export default RenderPageSnippet;

