//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Tabs, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'view_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
//import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import config from 'config';

//Logics
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "views/utility/GenericSaveLoadPanel";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
//import { Account, getAccountProperties } from 'classes/account/Account';
import { Account, getAccountProperties } from 'classes/accounts/Account';
import { CrudAction } from 'classes/enums/CrudAction';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';

type Props = {
}

const AccountManager = (props: Props) => {

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [studentNumbers, setStudentNumbers] = useState<string>("");
    const [loadedAccountIndex, setLoadedAccountIndex] = useState<number>(-1);
    const [activeTab, setActiveTab] = useState(0);
    const [isBusy, setIsBusy] = useState(new IsBusy());

    const mainTabsRef = useRef<TabsRef>(null);

    useEffect(() => {

        const loadAccounts = async () => {
            let response = await apiService.get(config.apiUrl + "/admin/getAllAccounts");
            if (response.success) {
                setAccounts(response.payload);
            } else {
                errorService.handleError(response);
            }
         
        }

        if (accounts.length === 0) {
            loadAccounts();
        }

    }, []);

    //================================================================================================= - Edit Account

    const createAccounts = async () => {

        let newAccounts = [] as Account[];

        //If studentNUmbers contains commas, split them into an array
        //else split by new lines
        let studentNumbersArray;
        if (studentNumbers.indexOf(",") !== -1) {
            studentNumbersArray = studentNumbers.split(",");
        } else {
            studentNumbersArray = studentNumbers.split("\n");
        }
       
        studentNumbersArray.forEach(studentNumber => {
            let account = new Account();
            studentNumber = studentNumber.trim();
            account.organisationId = "Woosong";
            account.studentNumber = studentNumber;
            account.username = studentNumber;
            account.password = studentNumber;
            newAccounts.push(account);
        });

        let response = await apiService.post(config.apiUrl + "/admin/createAccounts", newAccounts);
        if (response.success) {
            toast.success("Accounts created");
            setAccounts(accounts.concat(response.payload));
            setStudentNumbers("");
        } else {
            errorService.handleError(response);
        }
    }


    //================================================================================== - Render Account Manager

    return (
        <div className='container mx-auto'>

            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="View">

                    <div className="text-lg font-semibold">Accounts</div>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-5">
                            <div>Username</div>
                            <div>Fullname</div>
                            <div>Email</div>
                            <div>Student Number</div>
                            <div>Actions</div>
                        </div>
                        {accounts.map((account, index) => {
                            return (
                                <div key={index} className="grid grid-cols-5">
                                    <div>{account.username}</div>
                                    <div>{account.fullname}</div>
                                    <div>{account.email}</div>
                                    <div>{account.studentNumber}</div>
                                    <div>
                                        <Button onClick={() => { }} size="small">Edit</Button>
                                        <Button onClick={() => { }} size="small">Delete</Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </Tabs.Item>

                <Tabs.Item title="Create">
                    <p>Provide a list of student numbers separated by commas OR by new lines</p>

                    <Textarea placeholder="Student numbers" value={studentNumbers} onChange={(e) => setStudentNumbers(e.target.value)} />
                    <Button disabled={studentNumbers.indexOf(",")  !== -1 && studentNumbers.indexOf("\n") !== -1} onClick={() => createAccounts()} size="small">Create</Button>
                </Tabs.Item>

                <Tabs.Item title="Permissions / Roles">
                </Tabs.Item>
            </Tabs>
        </div>
    )

}

export default AccountManager;