//React
import { useEffect, useState } from 'react';

//UI
import { Button, Modal } from "flowbite-react";

//Services
import { toast } from 'react-toastify';
import apiService from "services/apiService";
import errorService from 'services/errorService';
import { useAtom } from 'jotai';
import { loadedMcqs } from "atom";
import config from "config";

//Logics
import parseMcqAILogic from "logic/ai/parseMcqAILogic";
import { extractTextFromNodeElement } from 'logic/gridslate/nodePage/extractTextFromNodeElement';

//Classes
import { StatusCode } from 'classes/enums/StatusCode';
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { Status } from 'classes/enums/Status';
import { Mcq } from 'classes/synapp/mcq/Mcq';   

//Components
class McqGenerationItem {
    content: string = "";
    checkpointCategoryIds: string[] = [];
    isComplete: boolean = false;
    startTime: number = 0;
    endTime: number = 0;
}

type Props = {
    setShowAutoGenerateMcqsModal: Function;
    content: any;
}

const AutoGenerateMcqsModal = (props: Props) => {

    const { setShowAutoGenerateMcqsModal, content } = props;

    const [stage, setStage] = useState<number>(1);
    const [mcqs, setMcqs] = useAtom(loadedMcqs);
    //const [generatedMcqs, setGeneratedMcqs] = useState<Mcq[]>([]);

    //const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [cancelGeneration, setCancelGeneration] = useState<boolean>(false);

    const [numberOfQuestionToGeneratePerCheckpoint, setNumberOfQuestionToGeneratePerCheckpoint] = useState<number>(5);
    const [difficultyRange, setDifficultyRange] = useState<number>(1); //1-3
    const [generateIfExistingQuestionsFewerThan, setGenerateIfExistingQuestionsFewerThan] = useState<number>(5);
    const [numberOfQuestionsPerAttempt, setNumberOfQuestionsPerAttempt] = useState<number>(3);

    const [mcqGenerationQueueItems, setMcqGenerationQueueItems] = useState<McqGenerationItem[]>([]);

    useEffect(() => {
        if (!content.content) {
            toast.error("No content found in editor");
            setShowAutoGenerateMcqsModal(false);
            return;
        }

    }, []);

    const extractTextBetweenTwoElementIds = (elementId1: string, elementId2: string) => {

        let contentText = "";
        let captureStarted = false;
        //Get text from all elements before this checkpoint
        for (let node of content.content) {
            if (node.attrs.elementId === elementId1) {
                contentText += extractTextFromNodeElement(node) + "\n";
                captureStarted = true;
            } else if (node.attrs.elementId === elementId2) {
                contentText += extractTextFromNodeElement(node) + "\n";
                return contentText;
            }
            if (captureStarted) {
                contentText += extractTextFromNodeElement(node) + "\n";
            }
        }
        return contentText;
    }

    const createMcqGenerationQueue = () => {
        let tempMcqGenerationQueueItems = [] as McqGenerationItem[];
        let lastElementId = content.content[0].attrs.elementId;
        for (let element of content.content) {
            //TODO: extend extension type to differentiate continue and mcq checkpoints
            //TODO: change ExtensionType to something better
            if (element.type === ExtensionType.Checkpoint && element.attrs.checkpoint.type === CheckpointTypes.Mcq) {
                let contentText = extractTextBetweenTwoElementIds(lastElementId, element.attrs.elementId);

                let mcqGenerationItem = new McqGenerationItem();
                mcqGenerationItem.content = contentText;
                mcqGenerationItem.checkpointCategoryIds = element.attrs.checkpoint.categoryIds;

                tempMcqGenerationQueueItems.push(mcqGenerationItem);
                lastElementId = element.attrs.elementId;
            }
        }
        setMcqGenerationQueueItems(tempMcqGenerationQueueItems);

    }


    const generateMcqs = async (contentText: string, checkpointCategoryIds: string[]) => {
        let request = { "Content": contentText, "NumberOfQuestions": numberOfQuestionToGeneratePerCheckpoint, "Difficulty": difficultyRange };
        let response = await apiService.post(config.apiUrl + '/cms/AICreateCodeMcq', request)

        if (response.success) {
            let tempMcqs = parseMcqAILogic.parseAIResponseSchemaToMcqs(response.payload);
            for (let mcq of tempMcqs) {
                mcq.categoryIds = checkpointCategoryIds;
                mcq.status = Status.new;
                mcq.statusCode = StatusCode.new;
            }
            //Add to existing mcqs
            // let tempLoadedMcqs = [...mcqs];
            // tempLoadedMcqs = tempLoadedMcqs.concat(tempMcqs);
            // setMcqs(tempLoadedMcqs);

    
            console.log("Created AI MCQs: ", tempMcqs);

            // let tempLoadedMcqs = [...generatedMcqs];
            // tempLoadedMcqs = tempLoadedMcqs.concat(tempMcqs);
            // setGeneratedMcqs(tempLoadedMcqs);
            
            toast.success("Questions generated");
            //return true;
            return tempMcqs;
        } else {
            errorService.handleError(response);
            return [];
        }

    }

    const processMcqGenerationQueue = async () => {
        let allCreatedMcqs = [] as Mcq[];
        let tempMcqGenerationQueueItems = [...mcqGenerationQueueItems];
        for (let item of tempMcqGenerationQueueItems) {
            if (!item.isComplete) {
                item.startTime = Date.now();
                let createdMcqs = await generateMcqs(item.content, item.checkpointCategoryIds);

                if (createdMcqs.length > 0) {
                    item.isComplete = true;
                    item.endTime = Date.now();
                    allCreatedMcqs = allCreatedMcqs.concat(createdMcqs);
                } else {
                    item.endTime = Date.now();
                }
            
                setMcqGenerationQueueItems(tempMcqGenerationQueueItems);
                if (cancelGeneration) {
                    break;
                }
            }
        }

        console.log("All created: ",  allCreatedMcqs);
        //Add to existing mcqs
        let tempLoadedMcqs = [...mcqs];
        tempLoadedMcqs = tempLoadedMcqs.concat(allCreatedMcqs);
        setMcqs(tempLoadedMcqs);

    }

    return (<Modal show={true} size="3xl" onClose={() => setShowAutoGenerateMcqsModal(false)} popup>
        <Modal.Header>
            <div>Auto Generate Mcqs</div>
        </Modal.Header>
        <Modal.Body>
            {stage === 1 &&
                <div>
                    <div>Stage 1: Settings</div>
                    <div className="flex-auto">
                        <div>
                            {/* //Number of questions to generate */}
                            <label>Number of questions to generate per checkpoint</label>
                            <input type="number" value={numberOfQuestionToGeneratePerCheckpoint} onChange={(e) => setNumberOfQuestionToGeneratePerCheckpoint(parseInt(e.target.value))} />

                            {/* //Difficulty range */}
                            <label>Difficulty range</label>
                            <input type="number" value={difficultyRange} onChange={(e) => setDifficultyRange(parseInt(e.target.value))} />

                            {/* //Generate if existing questions fewer than */}
                            <label>Generate if existing questions fewer than</label>
                            <input type="number" value={generateIfExistingQuestionsFewerThan} onChange={(e) => setGenerateIfExistingQuestionsFewerThan(parseInt(e.target.value))} />

                            {/* //Number of questions per attempt */}
                            <label>Number of questions per attempt</label>
                            <input type="number" value={numberOfQuestionsPerAttempt} onChange={(e) => setNumberOfQuestionsPerAttempt(parseInt(e.target.value))} />

                        </div>
                    </div>

                    <Button onClick={() => {
                        createMcqGenerationQueue();
                        setStage(2);
                    }
                    }>Generate!</Button>
                </div>
            }
            {stage === 2 &&
                <div>
                    <div>Stage 2: MCQ Staging</div>
                    <div className="flex-auto">
                        <div>
                            {mcqGenerationQueueItems.map((item, index) => {
                                return <div key={index}>
                                    <div>CategoryIds: {item.checkpointCategoryIds}</div>
                                    <div>Content: {item.content}</div>
                                    <div>Complete: {item.isComplete ? "Yes" : "No"}</div>
                                    {/* <Button onClick={() => {
                                        generateMcqs(item.content, item.checkpointCategoryIds);
                                    }}>Generate</Button> */}
                                </div>
                            })}

                        </div>
                    </div>

                    {/* <Button disabled={creatingCategoriesStatus === "creating"} onClick={() => createCategories()}>Create categories</Button> */}
                    <Button onClick={() => {
                        setStage(3);
                        processMcqGenerationQueue()
                    }
                    }>Generate questions</Button>
                    <Button onClick={() => {
                        setShowAutoGenerateMcqsModal(false);
                    }
                    }>Cancel</Button>

                </div>
            }

            {stage === 3 &&
                <div>
                    <div>Stage 3: MCQ Generation</div>
                    <div className="flex-auto">
                        <div>
                            {mcqGenerationQueueItems.map((item, index) => {
                                return <div key={index}>
                                    <div>CategoryIds: {item.checkpointCategoryIds}</div>
                                    <div>Complete: {item.isComplete ? "Yes" : "No"}</div>
                                    {item.isComplete && <div>Time taken: {(item.endTime - item.startTime) / 1000}s</div>}
                                    {!item.isComplete && <div>Pending...</div>}
                                </div>
                            })}

                        </div>
                    </div>

                    <Button onClick={() => {
                        setCancelGeneration(true);
                    }
                    }>Cancel</Button>

                </div>
            }


        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowAutoGenerateMcqsModal(false)}>Close</Button>
        </Modal.Footer>

    </Modal>
    )
}

export default AutoGenerateMcqsModal;