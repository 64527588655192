
import { GenericPropertyUpdate } from "classes/gridslate/GenericPropertyUpdate";

const editObject = (obj: any, updates: GenericPropertyUpdate[]) => {
    console.log("editObject: ", obj, updates);
    let newObj = { ...obj };
    for (let update of updates) {
        //if primitive value, just update
        if (typeof update.value !== 'object') {
            newObj[update.propertyName] = update.value;
        } else if (Array.isArray(update.value)) {
            newObj[update.propertyName] = [...update.value];
        } else {
            newObj[update.propertyName] = { ...update.value };
        }
    }
    return newObj;
}

export default editObject;

