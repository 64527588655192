import config from "config";

const GetRouteByClass = (className: string, id: string) => {
    let route = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/" + config.deployment + "/storage/" + className.toLowerCase() + "/" + id + ".json";
    return route;
}

const GetNavbarRoute = () => {
    let route = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/" + config.deployment + "/storage/navbar/navbar.json";
    return route;
}

const GetImageRoute = (id: string) => {
    let route = config.S3Region + ".amazonaws.com/" + config.S3Bucket + "/" + config.deployment + "/storage/image/" + id;
    return route;
}

export default { GetNavbarRoute, GetRouteByClass, GetImageRoute };
