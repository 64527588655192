import createGUID from "logic/utility/createGUID";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";
import { StatusCode } from "classes/enums/StatusCode";

class CodeExercise {
    id: string = createGUID(10);
    name: string = "";
    difficulty: number = 1;
    instructionText: string = "";
    sourceCode: string = "";
    possibleAnswer: string = "";
    explanation: string = "";
    categoryIds: string[] = [];
    ownerId: string = "";
    statusCode: StatusCode = StatusCode.new;

    status: string = "new"; //client side only
}

const getCodeExerciseProperties = (thisObject: CodeExercise) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Code challenge name", value: thisObject? thisObject.name : "new"});
    return editableProperties;
}

export {CodeExercise, getCodeExerciseProperties};