import { PageCheckpointProgress } from "./PageCheckpointProgress";
import createGUID from "logic/utility/createGUID";

class ChallengeProgress {
    id: string = createGUID(10);
    challengeId: string = "";
    courseContentId: string = "";
    type: string = "";
    checkpointProgresses: PageCheckpointProgress[] = [];
    isComplete: boolean = false;
    pointsAwarded: number = 0;
    dateCompleted: number = 0;
    //statusCode: StatusCode = StatusCode.new;
}

export { ChallengeProgress };