const returnDurationString = (duration: number) => {
    if (duration === 0) {
        return "No limit";
    }
    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;
    return (minutes > 0 && seconds > 0) ? minutes + "m " + seconds + "s" : (minutes > 0) ? minutes + "m" : seconds + "s";
    //return (minutes > 0) ? minutes + "m " + seconds + "s" : (minutes > 0) ? minutes + "m" : "";
}

const returnTimeRemainingString = (startDate: number, duration: number) => {
    if (duration === 0) {
        return "No time limit";
    }
    if (startDate === 0) {
        return "Not started";
    }
    
    let now = Date.now();
    let elapsed = now - startDate;
    let remaining = Math.ceil((duration) - (elapsed / 1000));

    if (remaining < 0) {
        return "Time's up!";
    } else {
        return returnDurationString(remaining);
    }
}

export default { returnDurationString, returnTimeRemainingString };