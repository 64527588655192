enum InternalErrorCodes {
    ServerOffline = -1,
    None = 0,
    IncorrectUsernameOrPassword = 1,
    UserNotEnrolledInCourse = 2,
    FilterModelMissing = 3,
    ModelMissing = 4

}

export {InternalErrorCodes};