//React

//UI

//Services

//Logics

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType';

//Components
import { mergeAttributes,  } from '@tiptap/core';
import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import McqRevisionChallenge from "./McqRevisionChallenge";

//TODO: Change all extensions to single data object
export default Node.create({
    name: ExtensionType.McqRevisionChallenge,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            elementId: {
                default: "default",
            },
            courseId: {
                default: "None",
            },
            baseCategoryIds: {
                default: ["None"],
            },
            selectedCategoryIds: {
                default: [],
            }
           
        }
    },
    parseHTML() {
        return [
            {
                tag: ExtensionType.McqRevisionChallenge,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [ExtensionType.McqRevisionChallenge, mergeAttributes(HTMLAttributes)]
    },
    addNodeView() {
        return ReactNodeViewRenderer(McqRevisionChallenge)
    },
})

