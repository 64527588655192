import { StatusCode } from "classes/enums/StatusCode";
import createGUID from "logic/utility/createGUID";
class Category {
    id: string = createGUID(10);
    parentId: string = "";
    ownerId: string = "";
    level: number = 0;
    name: string = "";
    statusCode: StatusCode = StatusCode.new;
}

export { Category};