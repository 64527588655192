//React
import { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';

//Components 
import Dropzone from 'react-dropzone';

//Services/Helpers
import createGUID from 'logic/utility/createGUID';

//Logic
import S3RouteLogic from 'logic/gridslate/S3RouteLogic';

//Views/Components
import { Container } from "view_components/helper/HelperComponents";
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import TextStyle from '@tiptap/extension-text-style';

//Modals
import AutoCreateCheckpointModal from './modals/AutoCreateCheckpointsModal';
import AutoGenerateMcqsModal from './modals/AutoGenerateMcqsModal';

//import History from '@tiptap/extension-history';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
//import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
//import { createLowlight } from 'lowlight'

//import js from "highlight.js/lib/languages/javascript";

import UniqueID from '@tiptap-pro/extension-unique-id'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import FileHandler from '@tiptap-pro/extension-file-handler'

import TiptapToolbar from "views/courseconstruction/tiptappanels2/TiptapToolbar";
import TiptapPanelMarks from "views/courseconstruction/tiptappanels2/TiptapPanelMarks";

//Classes
import { Mcq } from "classes/synapp/mcq/Mcq";
import { CourseChallenge } from "classes/course/CourseChallenge";
import { ChallengeCheckpoint } from "classes/course/ChallengeCheckpoint";
import { CourseContent } from "classes/course/CourseContent";

//CSS
import "css/custom-tiptap-styles.css";
//import "css/prosemirror.css";
//import "css/tiptap.css";

//React

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';
import docXService from 'services/docXService';
import apiService from 'services/apiService';
import config from 'config';
import { useAtom } from 'jotai';
import { categoriesAtom } from "atom";
import { loadedMcqs } from "atom";
import { courseAtom } from 'atom';
//import { contentDocumentAtom } from 'atom';
import { baseCategoryIdsAtom } from "atom";

//Logics

//Components
import CheckpointExtension from "views/courseconstruction/customelements/CheckpointExtension";
import CourseTextChallengeExtension from 'views/courseconstruction/customelements/CourseTextChallengeExtension';
import CourseTextViewExtension from 'views/courseconstruction/customelements/CourseTextViewExtension';
import CategoryManager from "views/courseconstruction/CategoryManager";
import CodeExerciseChallengeExtension from "views/courseconstruction/customelements/CodeExerciseChallengeExtension";
import McqRevisionChallengeExtension from "views/courseconstruction/customelements/McqRevisionChallengeExtension";

//Classes
import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from "classes/enums/Class";
import { Status } from 'classes/enums/Status';
import { RequestImageUploadURLResponseModel } from "classes/models/response/RequestImageUploadURLResponseModel";

import { Course } from "classes/course/Course";
//import { IsBusy } from 'classes/general/IsBusy';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseContentType } from 'classes/enums/CourseContentType';
import { toast } from 'react-toastify';
import { Category } from 'classes/synapp/Category';
import { StatusCode } from 'classes/enums/StatusCode';
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';


const defaultContentDocument = {
  "type": "doc",
  "content": [
    {
      "type": "heading",
      "attrs": {
        "elementId": createGUID(10),
        "level": 1
      },
      "content": [
        {
          "type": "text",
          "text": "Default Content Document"
        }
      ]
    }
  ]
};

type Props = {
  courseContent: CourseContent;
}

const ContentDocumentConstructorPanel = (props: Props) => {

  const [nodeCourse, setCourse] = useAtom(courseAtom);
  const [courseContent, setCourseContent] = useState<CourseContent>(props.courseContent);

  //Modals
  const [showContentImportModal, setShowContentImportModal] = useState<boolean>(false);
  const [showAutoCreateModal, setShowAutoCreateModal] = useState<boolean>(false);
  const [showAutoGenerateMcqsModal, setShowAutoGenerateMcqsModal] = useState<boolean>(false);

  const [mcqs, setMcqs] = useAtom(loadedMcqs);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [categories, setCategories] = useAtom(categoriesAtom);
  //const [baseCategoryIds, setBaseCategoryIds] = useAtom(baseCategoryIdsAtom);

  //const lowlight = createLowlight()
  //lowlight.register('js', js)

  useEffect(() => {
    //TODO: Error handling
    if (courseContent) {
      //console.log('courseContent: ', courseContent);
      if (courseContent.contentDocument !== "") {
        let parsedContentDocument = JSON.parse(courseContent.contentDocument);
        setTimeout(() => {
          editor?.commands.setContent(parsedContentDocument);
        }, 100);

        //setParsedContent(parsedContentDocument);
        //console.log('parsedContentDocument: ', parsedContentDocument);
      }
    }
  }, [courseContent.contentDocument]);

  //TODO: Load all MCQs for this base categoryId
  useEffect(() => {
    //TODO: load mcqs
    const loadMcqs = async () => {
      let filterModel = new FilterModel();

      let response = await crudService.get(Class.mcq, filterModel);
      if (response.success) {
        let responseModel = response.payload as Mcq[];
        for (let i = 0; i < responseModel.length; i++) {
          responseModel[i].status = Status.unchanged;
        }
        setMcqs(response.payload);
      } else {
        errorService.handleError(response);
      }

    }
    loadMcqs();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      let filterModel = new FilterModel();
      crudService.get(Class.category, filterModel).then(response => {
        if (response.success) {
          setCategories(response.payload);
        } else {
          errorService.handleError(response);
        }

      });
    }

    if (categories.length === 0) {
      loadCategories();
    }

  }, []);

  const extensions = [
    StarterKit.configure({
      //codeBlock: false
  }),
    Image,
    TextStyle,
    // CodeBlockLowlight.configure({
    //   lowlight,
    // }),
    Link.configure({
      openOnClick: false,
    }),
    Color.configure({
      types: ['textStyle', 'heading', 'paragraph'],
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph', 'blockquote'],
    }),
    Highlight.configure({ multicolor: true }),
    FileHandler.configure({
      allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
      onDrop: (currentEditor, files, pos) => {
        files.forEach(file => {
          createImageFromFileDrop(currentEditor, file, pos);
        })
      },
    }),
    CheckpointExtension,
    CourseTextChallengeExtension,
    CodeExerciseChallengeExtension,
    CourseTextViewExtension,
    McqRevisionChallengeExtension,
    UniqueID.configure({
      attributeName: 'elementId',
      types: ['heading', 'paragraph', 'codeBlock', 'bulletList', 'orderedList', 'listItem', 'blockquote', 'horizontalRule', 'image'],
      generateID: () => createGUID(10),
    })
  ];

  const createImageFromFileDrop = async (editor: any, file: any, pos: any) => {
    //let localSrc = URL.createObjectURL(file);
    let elementId = createGUID(10);

    //TODO: Check if this still works
    let response = await apiService.post(config.apiUrl + "/cms/RequestImageUploadURL", { "elementId": elementId });
    if (response.success) {
      let responseModel = response.payload as RequestImageUploadURLResponseModel;
      var putS3Response = await apiService.put(responseModel.uploadUrl, file)
      if (putS3Response.success) {
        editor.chain().focus().insertContentAt(pos, {
          type: 'image',
          attrs: {
            elementId: elementId,
            //src: responseModel.fullPath,
            src: S3RouteLogic.GetImageRoute(elementId),
          },
        }).focus().run();

      } else {
        console.log("Put to S3 error: ", putS3Response);
        //TODO: Check about using errorService here
      }

    } else {
      errorService.handleError(response);
    }
  }

  //================================================================================================= - Edit ContentDocument

  const editor = useEditor({
    extensions: extensions,
    content: "",
    editorProps: {
      attributes: {
        class: 'max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl'
      },
    },
    onUpdate: () => {
      if (courseContent.status !== Status.updated) {
        let newCourseContent = { ...courseContent };
        newCourseContent.status = Status.updated;
        setCourseContent(newCourseContent);
      }
    }
  })

  if (!editor) {
    return null;
  }

  const handleDocXUpload = async (files: any) => {
    let data = await docXService.handleDocXUpload2(files);
    if (data && data.success) {
      let parsedContentDocumentJSON = docXService.createPageFromDocXData(data.payload) as any;
      editor.commands.setContent(parsedContentDocumentJSON);
    } else {
      errorService.handleError(data);
    }

  }

  //TODO: reconcile with nodeCourse object, in case of delete
  const saveContentDocument = async () => {

    setIsSaving(true);

    //Create a new nodeCourse object
    let newCourse = { ...nodeCourse };

    //Update this courseContent with new content
    let content = editor.getJSON();
    console.log('content: ', content);
    let newCourseContent = { ...courseContent };
    newCourseContent.contentDocument = JSON.stringify(content);
    newCourseContent.status = Status.updated;

    //Parse contentDocument for checkpoints
    //Should only be done for CourseTextChallenge, but could theoretically be done for any content
    let elementCheckpoints = content.content?.filter((element: any) => element.type === ExtensionType.Checkpoint);
    if (elementCheckpoints) {
      newCourseContent.checkpoints = [];
      for (let elementCheckpoint of elementCheckpoints) {
        if (!elementCheckpoint.attrs?.checkpoint) {
          console.log('No checkpoint object found in element: ', elementCheckpoint);
          continue;
        } else {
          //Add checkpoint to newCourseContent, using object directly from attrs
          let newCheckpoint = elementCheckpoint.attrs?.checkpoint as ChallengeCheckpoint;
          //Set elementId here
          newCheckpoint.elementId = elementCheckpoint.attrs?.elementId;
          newCourseContent.checkpoints.push(newCheckpoint);
        }
      }
    }

    //Parse challenges
    //Should only be done for MasterContentDocument, but could theoretically be done for any content
    //TODO: Update challenge categoryIds which are not set
    if (courseContent.type === CourseContentType.MasterPage) {
      let newCourseChallenges = [] as CourseChallenge[];
      let challenges = content.content?.filter((element: any) => element.type === ExtensionType.CourseTextChallenge || element.type === ExtensionType.CodeExerciseChallenge);
      if (challenges) {
        for (let challenge of challenges) {
          if (!challenge.attrs?.courseChallenge) {
            console.log('No challenge object found in element: ', challenge);
            continue;
          } else {
            newCourseChallenges.push(challenge.attrs?.courseChallenge);
          }
        }
      }
      newCourse.courseChallenges = newCourseChallenges;
    }

    //Find and update newCourse with new courseContent
    if (courseContent.type === CourseContentType.MasterPage) {
      newCourse.masterContentDocument = newCourseContent;

    } else if (courseContent.type === CourseContentType.Text) {
      let courseContentIndex = newCourse.courseContents.findIndex((content) => content.id === courseContent.id);
      newCourse.courseContents[courseContentIndex] = newCourseContent;
    }

    //Persist newCourse
    crudService.update(Class.course, newCourse).then(response => {
      if (response.success) {
        let savedCourse = response.payload as Course;
        savedCourse.status = Status.unchanged;
        newCourseContent.status = Status.unchanged;
        //reset currently loaded parent
        savedCourse.currentlyLoadedParent = nodeCourse.currentlyLoadedParent;
        setCourse(savedCourse);
        setCourseContent(newCourseContent);
        setIsSaving(false);
      } else {
        errorService.handleError(response);
        setIsSaving(false);
      }
    });

  }

  const getSaveButtonClass = () => {
    let baseClass = "hover:bg-blue-700 text-white font-bold py-2 px-4 round";
    if (courseContent.status === Status.updated) {
      baseClass += " bg-yellow-500";
    } else {
      baseClass += " bg-blue-500";
    }
    return baseClass;
  }

  const renderContentImportModal = () => {

    //TODO: Add options here for type of import: prepend, append, replace
    //const [contentImportType, setContentImportType] = useState<CourseChallengeType>(CourseChallengeType.Text);

    return (<Modal show={showContentImportModal} size="3xl" onClose={() => setShowContentImportModal(false)} popup>
      <Modal.Header>
        <div>Import courseContentId</div>
      </Modal.Header>
      <Modal.Body>
        <Dropzone onDrop={(acceptedFiles) => {
          setShowContentImportModal(false);
          handleDocXUpload(acceptedFiles);
        }}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className="flex w-full items-center justify-center h-32 cursor-pointer">
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={() => setShowContentImportModal(false)}>Close</Button> */}
        <Button onClick={() => setShowContentImportModal(false)}>Close</Button>
      </Modal.Footer>

    </Modal>
    )
  }

  const updateEditorContent = (newContent: any) => {
    editor.commands.setContent(newContent);
  }

  return (
    <Container>

      {/* //Display name, categoryIds, description of courseContent */}
      <div className="flex flex-row">
        <div className="flex-auto">
          <div>Course Content: {courseContent.name}</div>
        </div>
        <div className="flex-auto">
          <Button onClick={() => setShowContentImportModal(true)}>Import Content</Button>
        </div>
       
        <div>
          <Button onClick={() => setShowAutoCreateModal(true)}>Auto Create Checkpoints</Button>
          <Button onClick={() => setShowAutoGenerateMcqsModal(true)}>Auto Generate MCQs</Button>
        </div>
      </div>

      <CategoryManager
          categoryIds={courseContent.categoryIds}
          setCategoryIds={(newCatIds: string[]) => {
            let newCourseContent = { ...courseContent };
            newCourseContent.categoryIds = newCatIds;
            setCourseContent(newCourseContent);
          }}
          editable={true} />

      <div className="sticky float-right top-5 z-20 ">
        <button disabled={isSaving} className={getSaveButtonClass()}
          onClick={() => saveContentDocument()}>{isSaving ? 'Saving...' : 'Save' + isSaving}</button>
      </div>

      <TiptapToolbar editor={editor} disallowedControls={[]} disallowedNodes={[]} />
      <DragHandle editor={editor}>
        Drag
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
        </svg>
      </DragHandle>
      <br />
      <EditorContent editor={editor} />

      <BubbleMenu editor={editor} className="w-fit mb-4">
        <TiptapPanelMarks editor={editor} bubble={true} />
      </BubbleMenu>

      {showContentImportModal && renderContentImportModal()}
      {showAutoCreateModal && <AutoCreateCheckpointModal setShowAutoCreateModal={setShowAutoCreateModal} courseContent={courseContent} content={editor.getJSON()} updateEditorContent={updateEditorContent} />}
      {showAutoGenerateMcqsModal && <AutoGenerateMcqsModal setShowAutoGenerateMcqsModal={setShowAutoGenerateMcqsModal} content={editor.getJSON()} />}

    </Container>
  )
}

export default ContentDocumentConstructorPanel;