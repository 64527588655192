
class AICreateCodeMcqVariationRequest {
    // mcqQuestionText = "";
    // mcqAnswers = [] as string[];
    questionContent = "";
    mcqQuestionDifficulty = 1;
    numberOfQuestions = 0;
    difficulty = 0;
}

export { AICreateCodeMcqVariationRequest };