//React
import React from 'react';
import { useState, useEffect } from 'react';

//UI
import { Select } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { courseAtom, totalCourseProgressAtom } from "atom";
import config from 'config';
import apiService from 'services/apiService';
import errorService from 'services/errorService';
import crudService from 'services/crudService';

//Logics


//Classes
//import GetTotalProgressResponseModel from 'classes/models/response/GetTotalProgressResponseModel';
import { CourseProgress } from 'classes/courseprogress/CourseProgress';
import { CourseEnrollment } from 'classes/course/CourseEnrollment';
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import { Class } from 'classes/enums/Class';
import { CodeExerciseSubmission } from 'classes/synapp/code_exercise/CodeExerciseSubmission';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';
import { ChallengeCheckpoint } from 'classes/course/ChallengeCheckpoint';
import groupBy from 'logic/utility/groupBy';
import createGUID from 'logic/utility/createGUID';

//Components


type Props = {
    courseId: string;
    courseEnrollments: CourseEnrollment[];
};


const ViewCourseProgress = (props: Props) => {

    const { courseId, courseEnrollments } = props;

    const [course] = useAtom(courseAtom);
    const [loadedChallengeIds, setLoadedChallengeIds] = useState<string[]>([]);
    const [selectedChallengeId, setSelectedChallengeId] = useState<string>("none");
    const [courseProgresses, setCourseProgresses] = useState<CourseProgress[]>([]);
    //const [courseEnrollments, setCourseEnrollments] = useState<CourseEnrollment[]>([]);
    const [codeExerciseSubmissions, setCodeExerciseSubmissions] = useState<CodeExerciseSubmission[]>([]);

    // useEffect(() => {
    //     const getCourseEnrollments = async () => {
    //         let filter = new FilterModel([new PropertyFilter("CourseId", course.id)]);
    //         await crudService.get(Class.courseEnrollment, filter).then((response) => {
    //             if (response.success) {
    //                 setCourseEnrollments(response.payload as CourseEnrollment[]);
    //             } else {
    //                 errorService.handleError(response);
    //             }
    //         });
    //     }

    //     if (courseEnrollments.length === 0) {
    //         getCourseEnrollments();
    //     }
    // });

    useEffect(() => {
        console.log(course);
    }, [course]);

    useEffect(() => {
        const getCourseProgresses = async () => {
            let filter = new FilterModel([new PropertyFilter("CourseId", course.id)]);
            await crudService.get(Class.courseProgress, filter).then((response) => {
                if (response.success) {
                    setCourseProgresses(response.payload as CourseProgress[]);
                } else {
                    errorService.handleError(response);
                }
            });
        }

        if (courseProgresses.length === 0) {
            getCourseProgresses();
        }
    });

    const getCodeExerciseSubmissions = async (challengeId: string) => {
        let filter = new FilterModel([new PropertyFilter("ChallengeId", challengeId)]);
        await crudService.get(Class.codeExerciseSubmission, filter).then((response) => {
            if (response.success) {
                let fetchedCodeExerciseSubmissions = response.payload as CodeExerciseSubmission[];
                setCodeExerciseSubmissions([...codeExerciseSubmissions, ...fetchedCodeExerciseSubmissions]);
                setLoadedChallengeIds([...loadedChallengeIds, challengeId]);
            } else {
                errorService.handleError(response);
            }
        });
    }

    // useEffect(() => {

    //     const getTotalProgress = async () => {

    //         await apiService.get(config.apiUrl + "/cms/getTotalProgress/" + course.id).then((response) => {
    //             if (response.success) {
    //                 let totalCourseProgress = response.payload as GetTotalProgressResponseModel;
    //                 setTotalCourseProgress(totalCourseProgress.courseProgresses);
    //                 setCourseEnrollments(totalCourseProgress.courseEnrollments);

    //             } else {
    //                 errorService.handleError(response);
    //             }
    //         });

    //     }

    //     if (totalCourseProgress.length === 0) {
    //         getTotalProgress();
    //     }


    // }, [totalCourseProgressAtom]);

    const renderCheckpointsProgressPerStudent = (challenge: CourseChallenge) => {

        return (
            <>
                {courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {challenge.checkpoints.map((checkpoint: ChallengeCheckpoint) => {
                                return (
                                    <div key={checkpoint.id}>
                                        <div>
                                            {courseProgresses.map((courseProgress: CourseProgress) => {
                                                if (courseProgress.userId === enrollment.userId) {
                                                    let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === challenge.id);
                                                    if (challengeProgress) {
                                                        let checkpointProgress = challengeProgress.checkpointProgresses.find((x) => x.elementId === checkpoint.elementId);
                                                        if (checkpointProgress) {
                                                            return (
                                                                <div className="text-center" key={checkpointProgress.id}>
                                                                    <span>✔️</span>
                                                                    {/* <div>{checkpointProgress.pointsAwarded}</div> */}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="text-center" key={checkpoint.id}>
                                                                    <span>❌</span>
                                                                </div>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <div className="text-center" key={checkpoint.id}>
                                                                <span>∅</span>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </>)
    }

    const renderCodeExerciseProgressPerStudent = (submissionsByUser: any, challengeId: string) => {
        let rowIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        console.log(submissionsByUser);
        console.log(submissionsByUser.length);
        return (
            <>
                {Object.keys(submissionsByUser).length > 0 && courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col auto-cols-fr hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {rowIndex.map((rowIndex: number) => {
                                return (
                                    <div className="text-center" key={rowIndex}>
                                        {submissionsByUser[enrollment.userId]?.[rowIndex]?.pointsAwarded}
                                    </div>
                                )
                            })}
                            <div>
                                {submissionsByUser[enrollment.userId]?.reduce((acc: number, submission: CodeExerciseSubmission) => acc + submission.pointsAwarded, 0)}
                            </div>
                        </div>
                    )
                })}
                {Object.keys(submissionsByUser).length === 0 && loadedChallengeIds.includes(challengeId) && <div>No submissions</div>}
                {Object.keys(submissionsByUser).length && !loadedChallengeIds.includes(challengeId) && <div>
                    <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
                </div>}




                {/* {courseEnrollments.map((enrollment: CourseEnrollment, index: number) => {
                    return (
                        <div key={enrollment.id} className="grid grid-flow-col-dense hover:bg-col-s2">
                            <div>{enrollment.studentNumber}</div>
                            {challengeCodeExerciseSubmissions.filter((x) => x.ownerId === enrollment.userId).map((submission: CodeExerciseSubmission, index: number) => {
                                return (
                                    <div key={submission.id}>
                                            <div>{submission.pointsAwarded}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })} */}
            </>)
    }

    const renderChallenge = (challenge: CourseChallenge) => {
        if (challenge.type === CourseChallengeType.TextChallenge) {
            return (
                <div>
                    {/* //Headings */}
                    <div className="grid grid-flow-col-dense">
                        <div className="font-bold">Student Number</div>
                        {challenge.checkpoints.map((checkpoint: ChallengeCheckpoint) => {
                            return (
                                <div key={checkpoint.id}>
                                    <div className="font-bold">{checkpoint.type}</div>
                                </div>
                            )
                        })}
                    </div>
                    {/* //Progress per student*/}
                    {renderCheckpointsProgressPerStudent(challenge)}
                    {/* <div className="grid grid-flow-col-dense">
                            {renderCheckpointsProgressPerStudent(challenge)}
                        </div> */}
                </div>
            )
        }
        else if (challenge.type === CourseChallengeType.CodeExerciseChallenge) {
            let challengeCodeExerciseSubmissions = codeExerciseSubmissions.filter((x) => x.challengeId === challenge.id);
            let submissionsByUser = groupBy(challengeCodeExerciseSubmissions, (x) => x.ownerId);
            //let highestNumberOfSubmissions = Math.max(...Object.values(submissionsByUser).map((x) => x.length));
            return (
                <div>
                    {/* //Headings */}
                    <div className="grid grid-flow-col auto-cols-fr">
                        <div className="font-bold">Student #</div>
                        <div className="font-bold text-center"># 1</div>
                        <div className="font-bold text-center"># 2</div>
                        <div className="font-bold text-center"># 3</div>
                        <div className="font-bold text-center"># 4</div>
                        <div className="font-bold text-center"># 5</div>
                        <div className="font-bold text-center"># 6</div>
                        <div className="font-bold text-center"># 7</div>
                        <div className="font-bold text-center"># 8</div>
                        <div className="font-bold text-center"># 9</div>
                        <div className="font-bold text-center">Tot. Points</div>
                    </div>
                    {/* //Progress per student*/}
                    {renderCodeExerciseProgressPerStudent(submissionsByUser, challenge.id)}
                </div>
            )
        }

    }

    return (
        <>
        {/* {JSON.stringify(course.courseChallenges)} */}
        {createGUID(10)}
            <h1>Course Progress</h1>
            <Select value={selectedChallengeId} onChange={(e: any) => {
                setSelectedChallengeId(e.target.value);
                //If code exercise challenge not already loaded
                if (!loadedChallengeIds.includes(e.target.value)) {
                    //If code exercise challenge
                    if (course.courseChallenges.find((x) => x.id === e.target.value)?.type === CourseChallengeType.CodeExerciseChallenge) {
                        getCodeExerciseSubmissions(e.target.value);
                    }
                }
            }}>
                <option value="none">Select a challenge</option>
                {course && course.courseChallenges.map((challenge: CourseChallenge, index: number) => {
                    return (
                        <option key={challenge.id + index} value={challenge.id}>{challenge.name}</option>
                    )
                })}
            </Select>
            <div className="border border-2">
                {selectedChallengeId !== "none" && <div>{renderChallenge(course.courseChallenges.find((x) => x.id === selectedChallengeId) as CourseChallenge)}</div>}
                {/* {course && course.courseChallenges.map((challenge: CourseChallenge) => {
                    return (
                        <div key={challenge.id}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <div className="badge bg-primary me-2">{challenge.name}</div>
                                        {challenge.id === selectedChallengeId && <div>{renderChallenge(challenge)}</div>}

                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                })} */}

            </div>
        </>
    );
};

export default ViewCourseProgress;
