//React
import { useEffect } from 'react';

//Helpers/Services
import { decodeToken } from 'services/tokenService';

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Views/Components

//Classes
import { User } from 'classes/accounts/User';

const useUser = () => {
  const [currentUser, setCurrentUser] = useAtom(userAtom);
  
  useEffect(() => {
    if (!currentUser.checkedForToken) {
      let userToken = decodeToken();
      if (userToken !== null && userToken !== undefined) {
        let thisUser = new User();
        thisUser.setUserFromToken(userToken);
        thisUser.initials = thisUser.getInitials(thisUser.fullname);
        setCurrentUser(thisUser);
      }
    }

  }, [currentUser.checkedForToken]);

//   return (<></>);
}

export default useUser;