import CodeTryItViewer from "views/courseview/courseTextElements/TryItCodeblockElement";
import { Container } from "view_components/helper/HelperComponents";

const CodePlayground = () => {
    return (
        <Container>
            <strong className="mb-4">CodePlayground</strong>
            <CodeTryItViewer code={""} />
        </Container>
    );
}

export default CodePlayground;