import {PageGroupCategory} from "classes/enums/custom_pages/PageGroupCategory";
import {PageGroupSubCategory} from "classes/enums/custom_pages/PageGroupSubCategory";
import {CustomPages} from "classes/enums/custom_pages/CustomPages";

class ConstructedPageLookup {
    groupCategory: PageGroupCategory;;
    groupSubcategory: string = "";
    CustomPages: string[] = [];

    constructor(groupCategory: PageGroupCategory, groupSubCategory: PageGroupSubCategory, CustomPages: string[]) {
        this.groupCategory = groupCategory;
        this.groupSubcategory = groupSubCategory;
        this.CustomPages = CustomPages;        
    }
}

let preconstructedPages = [] as ConstructedPageLookup[];

preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Test, PageGroupSubCategory.Test, [CustomPages.TestTurtle, CustomPages.Test]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Gridslate, PageGroupSubCategory.Constructor, [CustomPages.PageConstructor, CustomPages.NavbarConstructor]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Synapp, PageGroupSubCategory.Course, [CustomPages.CourseInstanceConstructor, CustomPages.CourseInstanceBrowser, CustomPages.CourseTemplateBrowser, CustomPages.ChallengeConstructor, CustomPages.PageChallengeConstructor]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Gridslate, PageGroupSubCategory.Page, [CustomPages.PageConstructor, CustomPages.PageViewer, CustomPages.CourseManager, CustomPages.CourseBrowser]));
preconstructedPages.push(new ConstructedPageLookup(PageGroupCategory.Tools, PageGroupSubCategory.Code, [CustomPages.TestTurtle, CustomPages.CodePlayground]));

const convertPagesToGroupedOptions = () => {
    let groupedOptions = [] as any[];
    let groupCategories = preconstructedPages;
    groupCategories.forEach((groupCategory) => {
        let groupCategoryOptions = [] as any[];
        groupCategoryOptions.push({ value: "None", label: "Select page" });
        groupCategory.CustomPages.forEach((page) => {
            groupCategoryOptions.push({ value: page, label: page });
        })
        groupedOptions.push({ label: groupCategory.groupCategory, options: groupCategoryOptions });
    })
    return groupedOptions;
}

export default {ConstructedPageLookup, preconstructedPages, convertPagesToGroupedOptions };