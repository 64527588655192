import {NavbarElement} from './NavbarElement';
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";

class NavbarClass {

    id: string = createGUID(10);
    websiteId: string = "";
    elements: NavbarElement[];
    name: string = "new";
    isPrimary: boolean = false;
    statusCode: StatusCode = StatusCode.new;

    constructor(websiteId: string = "", elements: NavbarElement[] = []) {
        this.websiteId = websiteId;
        this.elements = elements;
    }
    
}

const getNavbarClassProperties = (thisObject: NavbarClass) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Navbar name", value: thisObject? thisObject.name : "new"});
    editableProperties.push({ propertyName: 'isPrimary', propertyType: 'boolean', displayName: "Primary navbar", value: thisObject? thisObject.isPrimary : false});

    return editableProperties;
}

export { NavbarClass, getNavbarClassProperties};