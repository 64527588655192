class FlashGameSession {

  id: string = "";
  userId: string = "";
  type: string = "";
  setId: string = ""; //Qa or word flashset
  subSetsSelected: number[] = [];
  totalWords: number = 0;
  totalCorrect: number = 0;
  gameWasFinished: boolean = false;
  creationDate: string = "";
  isPublic : boolean = false;

  constructor() {

  }


}

export { FlashGameSession};