//React

//Components / helpers / services

//Store

//Views/Components

//Classes
import { WordSetGroup, WordSet, SingleWord } from "classes/synapp/wordflash/WordSetGroup";
import { QASetGroup } from "classes/synapp/wordflash/QASetGroup";

const joinWordSetGroupWords = (wordFlashSet: WordSetGroup) => {
    let words = '';
    wordFlashSet.wordSets.forEach((wordSet: WordSet) => {
      wordSet.words.forEach((word: SingleWord) => {
        words += word.word + ', ';
      });

    });
    return words;
  }

  const joinQASets = (qaSet: QASetGroup) => {
    let words = '';
    qaSet.qaSets.forEach((qa: any) => {
      words += qa.question;
      for (let i = 0; i < qa.answers.length; i++) {
        words += ', ' + qa.answers[i] + '  ';
      }
    });
    return words;
  }

  export default { joinWordSetGroupWords, joinQASets };