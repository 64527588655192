//ACTUAL rendering for VIEWING purposes
//Construction happens in Tiptap editor, NOT HERE
//TODO: Make additional RenderMasterPage, which redirects here, to make things simpler

//React
import React from 'react';

//UI

//Services

//Logics

//Components
import CheckpointContinue from "views/courseview/courseTextElements/CheckpointContinueElement";
import CourseTextChallenge from "views/courseview/masterpage/CourseTextChallengeElement";
import CodeExerciseChallenge from "views/courseview/masterpage/CodeExerciseChallengeElement";
import CheckpointMcq from '../courseview/courseTextElements/CheckpointMcqElement';
import RenderNode from './RenderNode';
import CourseTextView from 'views/courseview/masterpage/CourseTextViewElement';
import McqRevisionChallenge from 'views/courseview/masterpage/McqRevisionChallengeElement';

//Store

//Classes
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseChallenge } from 'classes/course/CourseChallenge';

const isRenderElement = (node: any) => {
    if (node.attrs && node.attrs.renderVisible !== undefined) {
        return node.attrs.renderVisible;
    } else {
        return true;
    }
}

type Props = {
    courseChallenge: CourseChallenge | null;
    contentJSON: any;
    renderCheckpoints: boolean;
}

//TODO: Fix nesting - happening because of not passing props properly
//This function will take a nodepage JSON and parse it into HTML
const RenderCourseContent = (props: Props) => {
    const { courseChallenge, contentJSON, renderCheckpoints } = props;

    //console.log("RenderCourseContent contentJSON", contentJSON);

    return (
        <>
            {contentJSON.content && contentJSON.content.map((node: any, index: number) => (
                <React.Fragment key={"nodepage-top-" + index + node.attrs.elementId}>
                    {isRenderElement(node) && node.type !== ExtensionType.Checkpoint &&
                        <RenderNode
                            node={node}
                            elementId={node.attrs.elementId}
                            enableTryIt={true}
                        ></RenderNode>}
                    {isRenderElement(node) && node.type === ExtensionType.Checkpoint && renderCheckpoints &&
                        <div>
                            {/* Does not have challengeLocalId because constructed as a generic page */}
                            {/* //TODO: What if there is no courseChallenge? */}
                            {!courseChallenge && <div>Course Challenge not found for checkpoint</div>}
                            {node.attrs.checkpoint.type === CheckpointTypes.Continue && courseChallenge && <CheckpointContinue elementId={node.attrs.elementId} courseChallenge={courseChallenge} ></CheckpointContinue>}
                            {node.attrs.checkpoint.type === CheckpointTypes.Mcq && courseChallenge && <CheckpointMcq elementId={node.attrs.elementId} courseChallenge={courseChallenge} ></CheckpointMcq>}
                        </div>}

                    {isRenderElement(node) && node.type == ExtensionType.CourseTextChallenge &&
                        <div>
                            {/* //has challengeLocalId because constructed as a challenge */}
                            <CourseTextChallenge courseChallenge={node.attrs.courseChallenge} ></CourseTextChallenge>
                        </div>}
                    {isRenderElement(node) && node.type == ExtensionType.CourseText &&
                        <div>
                            <CourseTextView courseContentId={node.attrs.courseContentId} courseContentName={node.attrs.courseContentName} ></CourseTextView>
                        </div>}
                    {isRenderElement(node) && node.type == ExtensionType.CodeExerciseChallenge &&
                        <div>
                            {/* //has challengeLocalId because constructed as a challenge */}
                            <CodeExerciseChallenge courseChallenge={node.attrs.courseChallenge} ></CodeExerciseChallenge>
                        </div>}
                    {isRenderElement(node) && node.type == ExtensionType.McqRevisionChallenge &&
                        <div>
                            {/* //has challengeLocalId because constructed as a challenge */}
                            <McqRevisionChallenge courseId={node.attrs.courseId} elementId={node.attrs.elementId} selectedCategoryIds={node.attrs.selectedCategoryIds}></McqRevisionChallenge>
                        </div>}

                </React.Fragment>
            ))}
        </>
    )
}

export default RenderCourseContent;

