//React
import { useEffect, useState } from "react";

//UI
import { Datepicker, Button, Modal } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';

//Logics
import time from 'logic/utility/time';
import parseObjectUpdate from 'logic/parse/parseObjectUpdate';

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType'
import { CourseChallenge } from "classes/course/CourseChallenge";
import { GenericPropertyUpdate } from "classes/gridslate/GenericPropertyUpdate";

//Components
import CategoryManager from "views/courseconstruction/CategoryManager";
import CodeExerciseConstructor from "views/courseconstruction/CodeExerciseConstructor";
import { NodeViewWrapper } from '@tiptap/react'

export default (props: any) => {

    const [nodeCourse] = useAtom(courseAtom);
    const [showCodeExerciseModal, setShowCodeExerciseModal] = useState<boolean>(false);

    let courseChallenge = props.node.attrs.courseChallenge as CourseChallenge;

    useEffect(() => {
        //Set the categoryIds from parent CourseContent if they are not set
        if (courseChallenge.categoryIds.length === 0 && nodeCourse.currentlyLoadedParent) {
            updateCourseChallenge("categoryIds", nodeCourse.currentlyLoadedParent.categoryIds);
        }
    });

    const updateCourseChallenge = (target: string, value: any) => {
        let newCourseChallenge = { ...courseChallenge };
        newCourseChallenge = parseObjectUpdate(newCourseChallenge, [new GenericPropertyUpdate(target, value)]);
        props.updateAttributes({
            courseChallenge: newCourseChallenge,
        });
    }

    const setStartDate = (date: any) => {
        updateCourseChallenge("startDate", time.toUTC(date));
    }

    const setDueDate = (date: any) => {
        updateCourseChallenge("dueDate", time.toUTC(date));
    }

    const getStartDate = () => {
        let returnedDate = time.toDate(courseChallenge.startDate);
        return returnedDate;
    }

    const getDueDate = () => {
        let returnedDate = time.toDate(courseChallenge.dueDate);
        return returnedDate;
    }


    const renderCodeExerciseModal = () => {

        return (<Modal show={showCodeExerciseModal} size="7xl" onClose={() => setShowCodeExerciseModal(false)} popup>
            <Modal.Header>
                <div>Manage code exercises</div>
            </Modal.Header>
            <Modal.Body>
                <CodeExerciseConstructor categoryIds={courseChallenge.categoryIds} content={""} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowCodeExerciseModal(false)}>Close</Button>
            </Modal.Footer>

        </Modal>
        )
    }

    return (
        <NodeViewWrapper className={ExtensionType.CodeExerciseChallenge} data-localid={props.node.attrs.elementId}>
            <label>Code Exercise Challenge</label>

            <div className="content">
                <CategoryManager
                    categoryIds={courseChallenge.categoryIds}
                    setCategoryIds={(newCatIds: string[]) => updateCourseChallenge("categoryIds", newCatIds)}
                    editable={true} />
            </div>
            <div className="flex flex-wrap gap-2">
                <div>Challenge Name</div>
                <input type="text" value={courseChallenge.name} onChange={(e) => updateCourseChallenge("name", e.target.value)} />
                <div>Minimum Points Required</div>
                <input type="number" value={courseChallenge.minimumPointsRequired} onChange={(e) => updateCourseChallenge("minimumPointsRequired", e.target.value)} />
                <div>Start Date</div>
                <Datepicker value={getStartDate()} onSelectedDateChanged={setStartDate} />
                <div>Due Date</div>
                <Datepicker value={getDueDate()} onSelectedDateChanged={setDueDate} />

                <Button onClick={() => setShowCodeExerciseModal(true)}>Manage code exercises</Button>
            </div>


            {showCodeExerciseModal && renderCodeExerciseModal()}

        </NodeViewWrapper>
    )
}