import createGUID from "logic/utility/createGUID";

class QASetGroup {
    id: string = createGUID(10);
    courseId: string;
    name: string;
    description: string = "";
    imageSrc: string = "";
    qaSets: QASet[] = [];
    ownerId: string = "";
    isPublic: boolean = false;
    creationDate: number = Date.now();
    lastEditDate: number = Date.now();
    statusCode: number = 0;

    constructor(courseId: string, name: string) {
        this.courseId = courseId;
        this.name = name;
    }
}

class QASet {
    question: string;
    answers: string[];

    constructor(question: string, answers: string[]) {
        this.question = question;
        this.answers = answers;
    }
}

export { QASetGroup, QASet};