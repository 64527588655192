import { CodeExerciseSubmissionGrade } from "classes/synapp/code_exercise/CodeExerciseSubmissionGrade";
import { AICodeExerciseSubmissionGrade } from "classes/AI/AICodeExerciseSubmissionGrade";

const parseNewAIResponse = (inputString: string) => {
    let grade = JSON.parse(inputString) as AICodeExerciseSubmissionGrade;

    let appendedGradeBreakdown = "Correct answer grade (out of 6): " + grade.correctAnswerGrade + "\nFormatting grade (out of 2): " + grade.formattingGrade + "\nVariable naming grade ( out of 2): " + grade.variableNamingGrade;
    grade.comment = appendedGradeBreakdown + "\n" + grade.comment;

    let newGrade = new CodeExerciseSubmissionGrade();
    newGrade.grade = grade.correctAnswerGrade + grade.formattingGrade + grade.variableNamingGrade;
    newGrade.comments = grade.comment;
    newGrade.gradeType = 1;
    newGrade.dateGraded = Date.now();
    return newGrade;
}

export default { parseNewAIResponse };
