//React
//import React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

//Components 

//Services/Helpers
import createGUID from 'logic/utility/createGUID';

//Store

//Views/Components
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import TextStyle from '@tiptap/extension-text-style';

//import History from '@tiptap/extension-history';
import TextAlign from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';

import FileHandler from '@tiptap-pro/extension-file-handler'

//import TiptapToolbar from "views/courseconstruction/tiptappanels2/TiptapToolbar";
import TiptapPanelControls from "views/courseconstruction/tiptappanels2/TiptapPanelControls";
import TiptapPanelMarks from "views/courseconstruction/tiptappanels2/TiptapPanelMarks";

//Classes
import { McqTiptapEditorInterface } from "classes/interfaces/McqTiptapEditorInterface";

//CSS
import "css/custom-tiptap-styles.css";
import "css/prosemirror.css";

//React

//Services
import errorService from 'services/errorService';
import apiService from 'services/apiService';
import config from 'config';

//Logics

//Components

//Classes
import { RequestImageUploadURLResponseModel } from "classes/models/response/RequestImageUploadURLResponseModel";

type Props = {
    mcqId: string;
    content: any;
    contentChanged: Function;
}

interface ChildComponentProps {
    mcqId: string;
    content: any;
    contentChanged: Function;
}

const McqTiptapEditor = forwardRef<McqTiptapEditorInterface, ChildComponentProps>((props: Props, ref) => {

    const { content, contentChanged, mcqId } = props;

    const extensions = [
        StarterKit,
        Image,
        TextStyle,
        Link.configure({
            openOnClick: false,
        }),
        Color.configure({
            types: ['textStyle', 'heading', 'paragraph'],
        }),
        TextAlign.configure({
            types: ['heading', 'paragraph', 'blockquote'],
        }),
        Highlight.configure({ multicolor: true }),
        FileHandler.configure({
            allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
            onDrop: (currentEditor, files, pos) => {
                files.forEach(file => {
                    createImageFromFileDrop(currentEditor, file, pos);
                })
            },
        }),

    ];

    const createImageFromFileDrop = async (editor: any, file: any, pos: any) => {
        //let localSrc = URL.createObjectURL(file);
        let elementId = createGUID(10);

        let response = await apiService.post(config.apiUrl + "/cms/RequestImageUploadURL", { "LocalId": elementId });
        if (response.success) {
            let responseModel = response.payload as RequestImageUploadURLResponseModel;
            var putS3Response = await apiService.put(responseModel.uploadUrl, file)
            if (putS3Response.success) {
                editor.chain().focus().insertContentAt(pos, {
                    type: 'image',
                    attrs: {
                        elementId: elementId,
                        src: responseModel.fullPath,
                    },
                }).focus().run();
                //editor.commands.set('paragraph', { id: 'paragraph-01' })
            } else {
                console.log("Put to S3 error: ", putS3Response);
                //TODO: Check about using errorService here
            }

        } else {
            errorService.handleError(response);
        }
    }

    const editor = useEditor({
        extensions: extensions,
        content: "",
        editorProps: {
            attributes: {
                class: 'max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl'
            },
        },
        onUpdate: () => {
            contentChanged();
        }

    })

    useImperativeHandle(ref, () => ({
        getContents: () => {
            if (!editor) {
                console.log("Editor is null on imperative call");
                return "";
            }
            let contentJSONString = JSON.stringify(editor.getJSON());
            return contentJSONString;
        }
    }));

    //TODO: CHeck this double update effect
    useEffect(() => {
        if (editor) {
            editor.commands.setContent(JSON.parse(content));
            // editor.on('update', ({ editor }) => {
            //     contentChanged();
            //   }
            // )
            console.log("Content changing...");
        }

    }, [mcqId])

    if (!editor) {
        return null;
    }

    return (
        <div>

            {/* <TiptapToolbar editor={editor} /> */}
            <div className="flex gap-2 bg-col-t1 m-2">
                <TiptapPanelControls editor={editor} disallowedControls={['undo', 'redo', 'heading']} />
            </div>

            <EditorContent editor={editor} />

            <BubbleMenu editor={editor}>

                <TiptapPanelMarks editor={editor} bubble={true} />
            </BubbleMenu>
        </div>
    )
}
)
export default McqTiptapEditor