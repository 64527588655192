import { BreadCrumbItem } from "classes/general/BreadcrumbItem";

const addBreadCrumb = (name: string, url: string, breadCrumbs: BreadCrumbItem[] ) => {
    let updatedBreadCrumbs = [...breadCrumbs];

    //Check if the breadCrumbItem already exists
    const exists = updatedBreadCrumbs.find((x) => x.name === name);
    //If a breadcrumb exists, remove all breadcrumbs after it
    if (exists) {
        const index = updatedBreadCrumbs.indexOf(exists);
        updatedBreadCrumbs = updatedBreadCrumbs.slice(0, index);
    } else {
        updatedBreadCrumbs = [...updatedBreadCrumbs, new BreadCrumbItem(name, url)];
    }
    return updatedBreadCrumbs;
}

export default { addBreadCrumb };