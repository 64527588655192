

const time = {

    timeStamp: 0,

    now() {
        this.timeStamp = new Date().getTime();
        return this;
    },

    set(timeStamp: number) {
        this.timeStamp = timeStamp;
        return this;
    },

    addDays(days: number) {
        this.timeStamp += days * 24 * 60 * 60 * 1000;
        return this;
    },

    addHours(hours: number) {
        this.timeStamp += hours * 60 * 60 * 1000;
        return this;
    },

    addMinutes(minutes: number) {
        this.timeStamp += minutes * 60 * 1000;
        return this;
    },

    toUTC(date: string) {
        return new Date(date).getTime();
    },

    toDate(timeStamp?: number) {
        let thisTimeStamp = timeStamp ? timeStamp: this.timeStamp;
        return new Date(thisTimeStamp).toDateString();  
    },

    toCoolTimeString(timeStamp: number) {
        let durationString = " remaining";

        let now = new Date().getTime();
        let timeRemaining = timeStamp - now;
        
        if (timeRemaining < 0) {
            timeRemaining = Math.abs(timeRemaining);
            durationString = " ago";
        }

        let seconds = Math.floor(timeRemaining / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let weeks = Math.floor(days / 7);
        let months = Math.floor(days / 30);
        let years = Math.floor(days / 365);

        if (seconds < 60) {
            return seconds + " seconds" + durationString;
        }
        else if (minutes < 60) {
            return minutes + " minutes" + durationString;
        }
        else if (hours < 24) {
            return hours + " hours" + durationString;
        }
        else if (days < 7) {
            return days + " days" + durationString;
        }
        else if (weeks < 4) {
            return weeks + " weeks remaining and " + days % 7 + " days" + durationString;
        }
        else if (months < 12) {
            return months + " months remaining and " + days % 30 + " days" + durationString;
        }
        else {
            return years + " years remaining and " + months % 12 + " months" + durationString;
        }

    }




}

export default time;