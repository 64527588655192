
import createGUID from "logic/utility/createGUID";

class PageCheckpointProgress {
    id: string = createGUID(10);
    checkpointId: string = "";
    elementId: string = "";
    type: string = "";
    pointsAwarded: number = 0;
    dateCompleted: number = 0;
    isCompleted: boolean = false;
}

export { PageCheckpointProgress };