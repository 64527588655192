//React

//UI

//Services
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';

//Logics

//Classes
import { CourseContent } from "classes/course/CourseContent";
import { CourseContentType } from "classes/enums/CourseContentType";

//Components
import { NodeViewWrapper } from '@tiptap/react'

export default (props: any) => {

    const [nodeCourse] = useAtom(courseAtom);

    //Get challenge information 
    let courseContentId = props.node.attrs.courseContentId as string;
    let courseContentName = props.node.attrs.courseContentName as string;

    const setCourseContentId = (courseContentId: string) => {

        //get name of course content
        let courseContent = nodeCourse.courseContents.find((x) => x.id === courseContentId);
        let courseContentName = courseContent ? courseContent.name : "none";

        props.updateAttributes({
            courseContentId: courseContentId,
            courseContentName: courseContentName
        });
    }

    const getCourseTexts = () => {
        let courseTexts = [] as CourseContent[];

        for (let courseContent of nodeCourse.courseContents) {
            if (courseContent.type === CourseContentType.Text) {
                courseTexts.push(courseContent);
            }
        }

        return courseTexts;
    }

   
    return (
        <NodeViewWrapper className={"customExtensionStyle"} data-localid={props.node.attrs.elementId}>
            <label>Text View</label>
            <div>

                <div className="content">
                    Select Text:
                    <select value={courseContentId} onChange={(e) => {
                        setCourseContentId(e.target.value);
                    }}>
                        <option value="none">None selected</option>
                        {getCourseTexts().map((courseText, index) => {
                            return <option key={index} value={courseText.id}>{courseText.name}</option>
                        })}
                    </select>

                </div>

            </div>
        </NodeViewWrapper>
    )
}