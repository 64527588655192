import { ChallengeProgress } from "classes/courseprogress/ChallengeProgress";
import { ExtensionType } from 'classes/enums/ExtensionType';

const parsePageJSONForVisibility = (nodePageJSON: any, challengeProgress?: ChallengeProgress) => {

    console.log("Visibility challenge progress:", challengeProgress);

    let content = nodePageJSON.content;
    if (content === undefined) {
        console.warn("Content is undefined");
        return nodePageJSON;
    }
    let visibilityCheckpointReached = false;
    for (let i = 0; i < content.length; i++) {
        let node = content[i];
        node.attrs.renderVisible = !visibilityCheckpointReached;

        if (node.type === ExtensionType.Checkpoint) {
            let elementId = node.attrs.elementId;
          
            if (challengeProgress === undefined) {
                console.log("Challenge Progress is undefined");
                visibilityCheckpointReached = true;
            } else {
              
                let checkpoint = challengeProgress.checkpointProgresses.find((x) => x.elementId === elementId);

                if (checkpoint === undefined) {
                    visibilityCheckpointReached = true;
                } else {
                    //Checking date as escape hatch for db change
                    //TODO: remove ASAP
                    if (checkpoint.dateCompleted != 0) {
                        visibilityCheckpointReached = false;
                    } else {
                        visibilityCheckpointReached = true;
                    }
                }
            }
        }

    }

    return { ...nodePageJSON };

}

export default parsePageJSONForVisibility;