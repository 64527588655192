class EditablePropertySelectItem {

    name: string = "";
    value: string = "";

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}

export { EditablePropertySelectItem};