//React
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

//UI
import { Table, Button, Tabs, Modal, Textarea, RangeSlider, Spinner, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'view_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
//import { useAtom } from 'jotai';
///import { courseAtom } from 'atom';
//import { pageAtom } from 'atom';
import { useAtom } from "jotai";
import { breadCrumbsAtom } from "atom";


//Logics
import breadCrumbLogic from 'logic/utility/breadCrumbLogic';


//Components

//Classes
import { Course } from 'classes/course/Course';
import { IsBusy } from 'classes/general/IsBusy';
import { BreadCrumbItem } from "classes/general/BreadcrumbItem";

type Props = {

}

const CourseBrowser = (props: Props) => {

    const [courses, setCourses] = useState<Course[]>([]);
    const [isBusy, setIsBusy] = useState(new IsBusy(["courses"]));
    const [breadCrumbs, setBreadCrumbs] = useAtom(breadCrumbsAtom);
    const navigate = useNavigate();

    useEffect(() => {
        setBreadCrumbs(breadCrumbLogic.addBreadCrumb("Courses", "/Courses", breadCrumbs));
    }, []);

    useEffect(() => {

        //Gets courses by enrollments
        const loadCourses = async () => {
            let response = await apiService.get(config.apiUrl + "/cms/getCoursesByEnrollments");
            if (response.success) {
                setCourses(response.payload as Course[]);
                setIsBusy(isBusy.loaded("courses"));
            } else {
                errorService.handleError(response);
                setIsBusy(isBusy.loaded("courses"));
            }
        }

        loadCourses();

    }, []);

    // const loadCourseEnrollments = async (courseId: string) => {
    //     setIsBusy(isBusy.loading(["courseEnrollments"]));
    //     let filterModel = new FilterModel();
    //     filterModel.PropertyFilters = [["CourseId", courseId]];
    //     let response = await crudService.get(Class.nodeCourseEnrollment, filterModel);
    //     if (response.success) {
    //         setCourseEnrollments(response.payload as CourseEnrollment[]);
    //     } else {
    //         errorService.handleError(response);
    //     }
    //     setIsBusy(isBusy.loaded("courseEnrollments"));
    // }

    //================================================================================== - Render Course

    return (
        <div className='container mx-auto'>
            {isBusy.isLoading && <Spinner />}

            {!isBusy.isLoading && <div>
                {courses.length === 0 && <div>No courses found</div>}
                {courses.length > 0 && <div>
                    <div className='flex'>
                        {courses.map((course, index) => (
                            <div key={"course" + index}
                                className='border-4 border-col-s1 p-2 m-2 w-32 h-32 rounded cursor-pointer'
                                //Redirects to CourseViewer
                                onClick={() => {
                                    navigate("/ViewCourse/" + course.id);
                                }}>
                                {course.name}
                            </div>
                        ))}

                    </div>

                </div>}

            </div>}
        </div>
    )

}

export default CourseBrowser;