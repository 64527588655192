//TODO: Possibly REDACT
//Description: This redirects to the code exercise challenge system, and handles the completion of the challenge.
//state not necessary if getting from nodeCourse

//React
import { useNavigate, useParams, useLocation } from 'react-router-dom';

//UI

//Services
import { useAtom } from 'jotai';
import { courseAtom, courseProgressAtom } from 'atom';
import crudService from 'services/crudService';
import errorService from 'services/errorService';

//Logics

//Classes
import { Class } from 'classes/enums/Class';

//Components
import CodeExerciseSystem from 'views/courseview/challenge/CodeExerciseSystem';

type Props = {
}

const CodeExerciseChallengeWrapper = (props: Props) => {

    const { state } = useLocation();
    const { courseChallenge } = state; // Read values passed on state
    const { codeChallegeId } = useParams();
    //const navigate = useNavigate();
    const [nodeCourse] = useAtom(courseAtom);
    const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);

    const completeChallenge = (points: number) => {
        //Update courseProgress

        let updatedCourseProgress = { ...courseProgress };
        let challengeProgress = updatedCourseProgress.challengeProgresses.find((x) => x.challengeId === codeChallegeId);
        if (!challengeProgress) {
            console.warn("ChallengeProgress not found for challenge: " + codeChallegeId);
        } else {
            challengeProgress.pointsAwarded = points;

            challengeProgress.dateCompleted = Date.now();
            challengeProgress.isComplete = true;

            let index = updatedCourseProgress.challengeProgresses.findIndex((x) => x.challengeId === codeChallegeId);
            updatedCourseProgress.challengeProgresses[index] = challengeProgress;
        }

        crudService.update(Class.courseProgress, updatedCourseProgress).then((response) => {
            if (response.success) {
                setCourseProgress(updatedCourseProgress);
                //setCourseProgress(response.payload);
                //navigate(-1);
            } else {
                errorService.handleError(response);
            }
        });

    }

    return (
        <div>
            {!courseChallenge && <div>Challenge not found</div>}
            {courseChallenge &&
                <CodeExerciseSystem 
                    questionsSubmittable={true}
                    courseChallenge={courseChallenge}
                    completeChallenge={completeChallenge}
                    courseId={nodeCourse.id} 
                />
            }
        </div>
    )

}

export default CodeExerciseChallengeWrapper;