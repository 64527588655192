import createGUID from "logic/utility/createGUID";

class WordSetGroup {
    id: string = createGUID(10);
    name: string = ""
    courseId: string = "";
    description: string = "";
    ownerId: string = "";
    imageSrc: string = "";
    wordSets: WordSet[];
    isPublic: boolean = false;
    creationDate: number = Date.now();
    lastEditDate: number = Date.now();
    statusCode: number = 0;

    constructor(courseId: string, name: string, wordSets: WordSet[]) {
        this.courseId = courseId;
        this.name = name;
        this.wordSets = wordSets;
    }
}

class WordSet {
    words: SingleWord[] = [];
    setName: string = "";

    constructor(words: SingleWord[], setName: string) {
        this.words = words;
        this.setName = setName;
    }
}

class SingleWord {
    word: string;
    translationPairings: TranslationKeyValue[] = [];
    imageSrc: string = "";

    constructor(word: string, translationPairings: TranslationKeyValue[]) {
        this.word = word;
        this.translationPairings = translationPairings;
    }

}

class TranslationKeyValue {
    languageCode: string;
    Value: string;

    constructor(languageCode: string, Value: string) {
        this.languageCode = languageCode;
        this.Value = Value;
    }
}

export { WordSetGroup, WordSet, SingleWord, TranslationKeyValue};