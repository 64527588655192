//React
import React, { useEffect, useRef, useState } from 'react';

//UI
import { Button } from 'flowbite-react';

//Services
import { useWindowDimensions } from "logic/hooks/useWindowDimensions";

//Logics

//Classes

//Components

interface TurtleState {
    x: number;
    y: number;
    angle: number;
    penDown: boolean;
    penColor: string;
    penWidth: number;
}

const TestTurtle: React.FC = () => {

    const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [commandString, setCommandString] = useState<string>('');
    const [firstCommand, setFirstCommand] = useState<boolean>(true);
    const [isSingleCommand, setIsSingleCommand] = useState<boolean>(true);
    const [ctx, setCtx] = useState<any>(null);
    // const [turtle, setTurtle] = useState<TurtleState>({
    //     x: 250,
    //     y: 250,
    //     angle: 0,
    //     penDown: true,
    // });
    const turtleRef = useRef<TurtleState>({
        x: 250,
        y: 250,
        angle: 0,
        penDown: true,
        penColor: 'red',
        penWidth: 1,
    });

    const [background, setBackground] = useState<ImageData | null>(null);

    useEffect(() => {
        setCtx(canvasRef.current?.getContext('2d', { willReadFrequently: true }));
    }, [canvasRef]);


    const saveBackground = (ctx: CanvasRenderingContext2D) => {
        const bg = ctx.getImageData(0, 0, canvasRef.current!.width, canvasRef.current!.height);
        setBackground(bg);
    };

    const restoreBackground = (ctx: CanvasRenderingContext2D) => {
        if (background) {
            ctx.putImageData(background, 0, 0);
        }
    };

    const drawTurtleHead = (ctx: CanvasRenderingContext2D, x: number, y: number, angle: number) => {
        const headSize = 10;
        const radians = angle * (Math.PI / 180);

        ctx.beginPath();
        ctx.moveTo(x + headSize * Math.cos(radians), y + headSize * Math.sin(radians));
        ctx.lineTo(
            x + headSize * Math.cos(radians + Math.PI * 2 / 3),
            y + headSize * Math.sin(radians + Math.PI * 2 / 3)
        );
        ctx.lineTo(
            x + headSize * Math.cos(radians - Math.PI * 2 / 3),
            y + headSize * Math.sin(radians - Math.PI * 2 / 3)
        );
        ctx.closePath();
        ctx.fillStyle = 'black';
        ctx.fill();
    };

    const clearCanvas = (ctx: CanvasRenderingContext2D) => {
        if (canvasRef.current) {
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
    };

    const parseCommand = (command: string, onRepeat: boolean = false) => {

        if (!firstCommand) {
            if (!onRepeat) restoreBackground(ctx); // Restore the canvas before any movement
        } else {
            clearCanvas(ctx);
            setFirstCommand(false);
        }

        let commands = command.split('\n');
        //remove empty lines
        commands = commands.filter((cmd) => cmd.trim() !== '');
        for (let i = 0; i < commands.length; i++) {
            console.log("Command: ", commands[i]);
            executeCommand(commands[i]);
        }

        saveBackground(ctx);
        drawTurtleHead(ctx, turtleRef.current.x, turtleRef.current.y, turtleRef.current.angle);

    }

    const createProgramFromCommand = (command: string) => {
        //split based on spaces
        //then combine every two elements into a single element with a space
        let commands = command.split(' ');
        //remove empty strings
        commands = commands.filter((cmd) => cmd.trim() !== '');
        if (commands.length % 2 !== 0) {
            console.log('Invalid command');
            return "";
        }
        let program = [];
        for (let i = 0; i < commands.length; i += 2) {
            program.push(commands[i] + ' ' + commands[i + 1]);
        }
        return program;
    }

    const executeCommand = (command: string) => {

        // if (!firstCommand) {
        //      restoreBackground(ctx); // Restore the canvas before any movement
        // } else {
        //     clearCanvas(ctx);
        //     setFirstCommand(false);
        // }

        const [cmd, ...args] = command.split(' ');

        if (cmd === 'repeat') {
            const times = parseInt(args[0]);
            let thisCommandString = args.slice(1).join(' ');
            //remove the opening and closing square brackets
            thisCommandString = thisCommandString.trim().substring(1, thisCommandString.length - 1);
            let commands = createProgramFromCommand(thisCommandString);
            console.log(times, commands);

            for (let i = 0; i < times; i++) {
                //executeCommand(commands);
                //parseCommand(commands, true);
                for (let j = 0; j < commands.length; j++) {
                    executeCommand(commands[j]);
                }
            }
            return;
        }

        switch (cmd) {
            case 'fd':
                moveTurtle(ctx, parseFloat(args[0]));
                break;
            case 'bk':
                moveTurtle(ctx, -parseFloat(args[0]));
                break;
            case 'rt':
                turnTurtle(ctx, parseFloat(args[0]));
                break;
            case 'lt':
                turnTurtle(ctx, -parseFloat(args[0]));
                break;
            case 'pen':
                togglePen(args[0]);
                break;
            case 'pc':
                if (args[0] === 'random') {
                    turtleRef.current.penColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
                } else {
                    turtleRef.current.penColor = args[0];
                }
                break;
            case 'pw':
                turtleRef.current.penWidth = parseFloat(args[0]);
                break;
            // case 'pcrandom':
            //     turtleRef.current.penColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            //     break;
            case 'reset':
                resetTurtle();
                break;

            case 'setpos':
                setPosition(ctx, parseFloat(args[0]), parseFloat(args[1]));
                break;
            case 'clear':
                clearCanvas(ctx);
                break;

            default:

                console.log('Unknown command: ' + cmd);
        }


    };

    const moveTurtle = (ctx: CanvasRenderingContext2D, distance: number) => {
        const radians = turtleRef.current.angle * (Math.PI / 180);
        const newX = turtleRef.current.x + distance * Math.cos(radians);
        const newY = turtleRef.current.y + distance * Math.sin(radians);


        if (turtleRef.current.penDown) {
            ctx.beginPath();
            ctx.moveTo(turtleRef.current.x, turtleRef.current.y);
            ctx.lineTo(newX, newY);
            ctx.strokeStyle = turtleRef.current.penColor;
            ctx.lineWidth = turtleRef.current.penWidth;
            ctx.stroke();
        }

        // saveBackground(ctx);
        // drawTurtleHead(ctx, newX, newY, turtleRef.current.angle);

        turtleRef.current.x = newX;
        turtleRef.current.y = newY;

    };

    const turnTurtle = (ctx: CanvasRenderingContext2D, angle: number) => {
        let newAngle = (turtleRef.current.angle + angle) % 360;
        // saveBackground(ctx);
        // drawTurtleHead(ctx, turtleRef.current.x, turtleRef.current.y, newAngle);

        turtleRef.current.angle = newAngle;
    };

    const togglePen = (state: string) => {

        turtleRef.current.penDown = state === 'down';
    };

    const setPosition = (ctx: CanvasRenderingContext2D, x: number, y: number) => {

        turtleRef.current.x = x;
        turtleRef.current.y = y;
        ctx.moveTo(x, y);
    };

    const resetTurtle = () => {
        turtleRef.current.x = getCanvasHeight() / 2;
        turtleRef.current.y = getCanvasHeight() / 2;
        turtleRef.current.angle = 0;
        turtleRef.current.penDown = true;
        turtleRef.current.penColor = 'red';
        turtleRef.current.penWidth = 1;
    }

    useEffect(() => {

        let initialPos = getCanvasHeight() / 2;
        turtleRef.current.x = initialPos;
        turtleRef.current.y = initialPos;

        //draw initial turtleRef.current
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) return;
        saveBackground(ctx);
        drawTurtleHead(ctx, turtleRef.current.x, turtleRef.current.y, turtleRef.current.angle);
    }, []);

    const getCanvasHeight = () => {
        return viewWidth < 600 ? viewWidth - 20 : 600;
    }

    return (
        // <div className="flex justify-center my-4">
        <div className="grid grid-flow-row justify-center gap-3">
            {/* <div className="my-4"> */}
            <br />
            <div className="flex justify-center">
            <canvas id="turtleCanvas" ref={canvasRef} width={getCanvasHeight()} height={getCanvasHeight()} style={{ border: '1px solid black' }} />
            </div>
            <br />
            <Button onClick={() => setIsSingleCommand(!isSingleCommand)}>{isSingleCommand ? 'Multiple Commands' : 'Single Command'}</Button>
            {isSingleCommand && <div>
                <input
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            //handleRunClick();
                            parseCommand(commandString);
                        }
                    }}
                    className="w-full"
                    type="text"
                    id="commandInput"
                    placeholder="Enter command"
                    value={commandString}
                    onChange={(e) => setCommandString(e.target.value)}
                />
                <Button onClick={() => parseCommand(commandString)}>Run</Button>
            </div>}
            {!isSingleCommand && <div>
                <textarea
                    className="w-full mt-4"
                    rows={6}
                    id="commandInput"
                    placeholder="Enter commands"
                    value={commandString}
                    onChange={(e) => setCommandString(e.target.value)}
                />
                <Button className="mt-6" onClick={() => parseCommand(commandString)}>Run</Button>
            </div>}

            <p>Commands: fd <em>num</em>, bk <em>num</em>, rt <em>angle</em>, lt <em>angle</em>, pen up, pen down, setpos <em>x y</em></p>
            <p>Commands: pc <em>color or random</em>, pw <em>num</em>, reset</p>
            {/* </div> */}
        </div>
    );
};

export default TestTurtle;
