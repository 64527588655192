import { ChallengeProgress } from "classes/courseprogress/ChallengeProgress";
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";
import { Status } from "classes/enums/Status";

class CourseProgress {
   id: string = createGUID(10);
    courseId: string = "";
    userId: string = "";
    challengeProgresses: ChallengeProgress[] = [];
    statusCode: StatusCode = StatusCode.new;
    dateCreated: number = Date.now();
    dateModified: number = Date.now();

    //local
    status: Status = Status.unloaded;
    visibilityUpdated: Boolean = false;
}

export { CourseProgress };