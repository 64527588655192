//FOR VIEWING
//Redirects here ONLY from CourseTextChallenge
//TODO: remove challenge part

//React
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Services/Helpers
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';
import { toast } from 'react-toastify';

//CSS
import "css/prosemirror.css";
import "css/custom-tiptap-styles.css";

//Logics
import { Container } from "view_components/helper/HelperComponents";

//Components
import RenderCourseContent from "views/render/RenderCourseContent";

//Classes
import { Status } from "classes/enums/Status";
import { CourseContent } from 'classes/course/CourseContent'; 
import { CourseChallenge } from 'classes/course/CourseChallenge';

const CourseTextViewWrapper = () => {

  const { courseContentId } = useParams(); 
  const [parsedContent, setParsedContent] = useState<any>("");
  const [courseContent] = useState<CourseContent | null>(null);
  const [courseChallenge, setCourseChallenge] = useState<CourseChallenge | null>(null);
  const [nodeCourse, setCourse] = useAtom(courseAtom);
  
  const navigate = useNavigate();

  //TODO: Redirect back to course page if nodeCourse not loaded
  //OR load nodeCourse here with selective load

  useEffect(() => {
    if (nodeCourse.status === Status.unloaded) {
      toast.error("Course not loaded. Redirecting to course browser.");
      navigate("/CourseBrowser");
    } else {
      //Set course content
      let thisCourseContent = nodeCourse.courseContents.find((x) => x.id === courseContentId);
      if (thisCourseContent) {
        let thisParsedContent = JSON.parse(thisCourseContent.contentDocument);
        setParsedContent(thisParsedContent);
      } else {
        toast.error("Course content not found. Redirecting to course page.");
        navigate("/ViewCourse/" + nodeCourse.id);
      }
    }

  }, []);

  return (
    <Container>      
      <div className="mb-44 pt-12">
        {parsedContent !== "" &&
          <div>
            <div className="max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl">
              <RenderCourseContent contentJSON={parsedContent} courseChallenge={courseChallenge} renderCheckpoints={false} />
            </div>
          </div>}
        {!courseContent && <div>Loading...</div>}
      </div>
    </Container>
  )
}

export default CourseTextViewWrapper;