// enum FetchStatus {
//     Idle = 'idle',
//     Loading = 'loading',
//     Success = 'success',
//     Error = 'error',
// }

class FetchStatus {
    static Idle = 'idle';
    static Loading = 'loading';
    static Success = 'success';
    static Error = 'error';
}

// class FetchTracker {
//     status: FetchStatus = FetchStatus.Idle;

//     loaded = () => {
//         this.status = FetchStatus.Success;
//         return this;
//     }

//     loading = () => {
//         this.status = FetchStatus.Loading;
//         return this;
//     }

//     error = () => {
//         this.status = FetchStatus.Error;
//         return this;
//     }

//     idle = () => {
//         this.status = FetchStatus.Idle;
//         return this;
//     }

//     success = () => {
//         this.status = FetchStatus.Success;
//         return this;
//     }
// }

export { FetchStatus };