//React

//UI
import MultiSelect from "react-tailwindcss-select";

//Services
import { useAtom } from 'jotai';
import { courseAtom, categoriesAtom } from 'atom';

//Logics
import parseMultiSelect from 'logic/parse/parseMultiSelect';

//Classes
import { SelectOption } from "classes/general/SelectOption";

//Components
import { NodeViewWrapper } from '@tiptap/react'
import CategoryManager from "views/courseconstruction/CategoryManager";
import { useEffect } from "react";

export default (props: any) => {

    const [nodeCourse] = useAtom(courseAtom);
    const [categories] = useAtom(categoriesAtom);

    let baseCategoryIds = props.node.attrs.baseCategoryIds as string[];
    let selectedCategoryIds = props.node.attrs.selectedCategoryIds as string[];

    useEffect(() => {
        if (props.node.attrs.courseId === "None") {
            //set this as a timeout to prevent flushsync error
            setTimeout(() => {
                props.updateAttributes({
                    courseId: nodeCourse.id
                });
            }, 500);
        }   
    }, []);

    const getOptionsFromCategories = () => {
        let options = [] as SelectOption[];

        //Get children of last id in baseCategoryIds
        let baseParent = baseCategoryIds.length > 0 ? baseCategoryIds[baseCategoryIds.length - 1] : "None";
        if (baseParent === "None") {
            return options;
        }

        let filteredCategories = categories.filter(x => x.parentId === baseParent);
        for (let i = 0; i < filteredCategories.length; i++) {
            options.push(new SelectOption(filteredCategories[i].id, filteredCategories[i].name));
        }
        return options;
    }

    const getCategorySelectedValues = () => {
        //parseMultiSelect.stringArrayToMultiSelectOptions(selectedCategoryIds)
        //Set label as category name and value as category id
        let options = [] as SelectOption[];
        for (let i = 0; i < selectedCategoryIds.length; i++) {
            let category = categories.find(x => x.id === selectedCategoryIds[i]);
            if (category) {
                options.push(new SelectOption(category.id, category.name));
            }
        }
        return options;
    }

    return (
        <NodeViewWrapper className={"customExtensionStyle"} data-localid={props.node.attrs.elementId}>
            <label>Mcq Revision Challenge</label>
            <div className="content">
                <CategoryManager
                    categoryIds={baseCategoryIds}
                    setCategoryIds={(newCatIds: string[]) => {
                        //console.log(newCatIds)
                        //TODO: Fix this is CategoryManager
                        //If last element is "None" then remove it
                        newCatIds = newCatIds.filter(x => x !== "None");
                        props.updateAttributes({
                            baseCategoryIds: newCatIds
                        });
                    }}
                    editable={false} />
            </div>
            {baseCategoryIds.length > 0 && baseCategoryIds[0] !== "None" && <div>
                <MultiSelect
                   value={getCategorySelectedValues()}
                   isMultiple={true}
                   onChange={(e) => { props.updateAttributes({ selectedCategoryIds: parseMultiSelect.multiSelectOptionsToStringArray(e) }) }}
                   options={getOptionsFromCategories()}
                   primaryColor='#4F46E5'
                />
            </div>}
        </NodeViewWrapper>
    )
}