import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";
import {CourseContent} from "classes/course/CourseContent";
//import {EditablePropertySelectItem} from "classes/gridslate/EditablePropertySelectItem";
import { Status } from  "classes/enums/Status";
import { CourseChallenge } from "classes/course/CourseChallenge";
import createGUID from "logic/utility/createGUID";
import { CourseContentType } from "classes/enums/CourseContentType";
import { StatusCode } from "classes/enums/StatusCode";

class Course {
    id: string = createGUID(10);
    name: string = "Course Default Name Default";
    description: string = "This is the default description for the course.";
    masterContentDocument: CourseContent = new CourseContent(CourseContentType.MasterPage, "Master page");
    ownerId: string = "";
    courseContents: CourseContent[] = [];
    courseChallenges: CourseChallenge[] = [];
    statusCode: StatusCode = StatusCode.new;
    dateCreated: number = Date.now();
    dateModified: number = Date.now();
    
    //local
    status: Status = Status.unloaded;
    currentlyLoadedParent: CourseContent | null = null;
}

const getCourseProperties = (thisObject: Course, pages: CourseContent[]) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Course name", value: thisObject? thisObject.name : "new"});
    editableProperties.push({ propertyName: 'description', propertyType: 'string', displayName: "Course description", value: thisObject? thisObject.name : "new"});
    return editableProperties;
}

export {Course, getCourseProperties};