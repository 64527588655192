
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";

class CourseEnrollment {
    id: string = createGUID(10); 
    userId: string = "";
    name: string = "";
    studentNumber: string = "";
    courseId: string = "";
    group: number = 1;
    dateEnrolled: number = Date.now();
    pointsAwarded: number = 0;
    accessFlag: number = 0;
    statusCode: StatusCode = StatusCode.new;
}

export { CourseEnrollment };