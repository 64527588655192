//React

//UI

//Services

//Logics

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';

//Components
import { mergeAttributes,  } from '@tiptap/core';
import { Node, ReactNodeViewRenderer } from '@tiptap/react';

import CodeExerciseChallenge from './CodeExerciseChallenge';

//TODO: Consider trying to put baseCategoryIds straight into default courseChallenge

export default Node.create({
    name: ExtensionType.CodeExerciseChallenge,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            elementId: {
                default: "default",
            },
            courseChallenge: {
                default: new CourseChallenge(CourseChallengeType.CodeExerciseChallenge),
            }
        }
    },
    parseHTML() {
        return [
            {
                tag: ExtensionType.CodeExerciseChallenge,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [ExtensionType.CodeExerciseChallenge, mergeAttributes(HTMLAttributes)]
    },
    addNodeView() {
        return ReactNodeViewRenderer(CodeExerciseChallenge)
    },
})

