
enum PageGroupSubCategory {
    Test = "Test",
    Course = "Course",
    Admin = "Admin",
    Constructor = "Constructor",
    Page = "Page",
    Code = "Code",
}


export { PageGroupSubCategory };
