//React
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

//UI
import { Container, Row } from 'view_components/helper/HelperComponents';
import { Navbar, Dropdown, Button, Select, TextInput, ToggleSwitch } from "flowbite-react";
import MultiSelect from "react-tailwindcss-select";

//Services
import config from "config";

//Logics

//Components

//Classes
import { Page } from "classes/Page";
import { NavbarClass } from "classes/gridslate/navbar/Navbar";
import { NavbarElement } from "classes/gridslate/navbar/NavbarElement";
import { NavbarChild } from "classes/gridslate/navbar/NavbarChild";
//import pageGroups from "classes/enums/custom-pages";
import pageGroups from "logic/gridslate/custom_pages/preconstructedPages";

//Store
import { editableNavbarAtom } from 'atom';
import { useAtom } from 'jotai';

type Props = {
    thisNavBar: NavbarClass;
    thesePages: Page[];
}

class SelectedNavbarItem {
    topLevelIndex: number;
    childIndex: number;

    constructor(topLevelIndex: number, childIndex: number) {
        this.topLevelIndex = topLevelIndex;
        this.childIndex = childIndex;
    }
}

const NavbarConstructorPanel = (props: Props) => {

    const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedNavbarItem | null>(null);
    const [pages, setPages] = useState<Page[]>([]);
    //const [groupedPagesOptions, setGroupedPagesOptions] = useState<any[]>(pageGroups.convertPagesToGroupedOptions());
    const [navbar, setNavbar] = useAtom(editableNavbarAtom);
    
    class SelectOption {
        value: string;
        label: string;

        constructor(value: string, label: string) {
            this.value = value;
            this.label = label;
        }
    }

    const claimsList = [
        new SelectOption("Master", "👑 Master"),
        new SelectOption("Teacher", "👩‍🏫 Teacher"),
        new SelectOption("Student", "👨‍🎓 Student"),
        new SelectOption("Guest", "👤 Guest")
    ];

    //const [testSelectItem, setTestSelectItem] = useState<any>(null);

    useEffect(() => {
        if (props.thisNavBar) {
            setNavbar(props.thisNavBar);
        }
    }, [props.thisNavBar, setNavbar]);

    useEffect(() => {
        if (props.thesePages) {
            setPages(props.thesePages);
        }
    }, [props.thesePages]);

    //Add a new link as per the example in the JSON in the variabled navbarGrid
    //value as string or string[] for required claims
    const editNavBar = (target: string, value: any = "", childIndex: number = 0) => {
        let tempNavBarGrid = navbar.elements;

        if (target === "addNew") {
            let newElement = new NavbarElement('none', '', 'New Link', 'NewLink', []);
            tempNavBarGrid.push(newElement);
            setNavbar({ ...navbar, elements: tempNavBarGrid });
            return;
        }

        if (selectedMenuItem === null) {
            console.log("No menu item selected");
            return;
        }

        if (target === "setpageIdTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].pageId = value;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].pageRef = pages.find((page) => page.id === value)?.id || '';
        }
        if (target === "setpageIdChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].pageId = value;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].pageRef = pages.find((page) => page.id === value)?.id || '';
        }

        if (target === "typeTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].type = tempNavBarGrid[selectedMenuItem.topLevelIndex].type === 1 ? 0 : 1;
        }

        if (target === "typeChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].type = tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].type === 1 ? 0 : 1;
        }

        if (target === "titleTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].title = value;
        }
        if (target === "routeTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].route = value;
        }

        if (target === "titleChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].title = value;
        }
        if (target === "routeChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].route = value;
        }

        if (target === "requiredClaimsTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].requiredClaims = value;
        }

        if (target === "requiredClaimsChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].requiredClaims = value;
        }

        if (target === "addDropDownLink") {
            let newChild = new NavbarChild('none', '', 'New Link', 'NewLink');
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.push(newChild);
        }
        if (target === "moveLinkUp") {
            //move the link up in the array using childIndex
            //only if the childIndex is not 0
            if (childIndex === 0) return;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex - 1, 0, tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex, 1)[0]);

        }
        if (target === "moveLinkDown") {
            //move the link down in the array using childIndex, only if the childIndex is not the last index
            if (childIndex === tempNavBarGrid[selectedMenuItem.topLevelIndex].children.length - 1) return;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex + 1, 0, tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex, 1)[0]);
        }

        if (target === "moveLinkLeft") {
            //move the link left in the array using topLevelIndex, only if the topLevelIndex is not 0
            if (selectedMenuItem.topLevelIndex === 0) return;
            tempNavBarGrid.splice(selectedMenuItem.topLevelIndex - 1, 0, tempNavBarGrid.splice(selectedMenuItem.topLevelIndex, 1)[0]);
            setSelectedMenuItem(new SelectedNavbarItem(selectedMenuItem.topLevelIndex - 1, 0));
        }

        if (target === "moveLinkRight") {
            //move the link right in the array using topLevelIndex, only if the topLevelIndex is not the last index
            if (selectedMenuItem.topLevelIndex === tempNavBarGrid.length - 1) return;
            tempNavBarGrid.splice(selectedMenuItem.topLevelIndex + 1, 0, tempNavBarGrid.splice(selectedMenuItem.topLevelIndex, 1)[0]);
            setSelectedMenuItem(new SelectedNavbarItem(selectedMenuItem.topLevelIndex + 1, 0));
        }

        if (target === "deleteDropdownLink") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(selectedMenuItem.childIndex, 1);
        }
        if (target === "deleteLink") {
            tempNavBarGrid.splice(selectedMenuItem.topLevelIndex, 1);
            setSelectedMenuItem(null);
        }
        setNavbar({ ...navbar, elements: tempNavBarGrid });
    }

    const stringArrayToMultiSelectOptions = (array: string[]) => {
        if (array === null) return [];
        let options = [] as SelectOption[];
        array.forEach((element) => {
            options.push({ value: element, label: element });
        })
        return options;
    }

    const stringToMultiSelectOption = (string: string) => {
        return { value: string, label: string };
    }

    const multiSelectOptionsToStringArray = (options: any) => {
        if (options === null) return [];
        let array = [] as string[];
        options.forEach((option: SelectOption) => {
            array.push(option.value);
        })
        return array;
    }

    const multiSelectOptionToString = (option: any) => {
        return option.value;
    }

    return (
        <Container>
            <Row>
                {/* ============================================ MOCKUP navbar with selectable elements */}
                <div className='container mx-auto'>
                    <Navbar fluid rounded className='bg-col-t1 border-b-4 border-col-s2'>
                        <Navbar.Brand >
                            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                                {config.siteTitle}
                            </span>
                        </Navbar.Brand>

                        <Navbar.Collapse>

                            <Link to={""}><span className='text-col-p2 md:text-xl'>Home</span></Link>

                            {navbar && navbar.elements.map((item, index1) => (
                                <React.Fragment key={"navdiv" + index1}>
                                    {item.pageRef !== "dropdown" &&
                                        <Navbar.Link
                                            className={'text-col-p2 text-xl ' + (selectedMenuItem && selectedMenuItem.topLevelIndex === index1 ? 'bg-col-s2' : '')}
                                            onClick={() => setSelectedMenuItem(new SelectedNavbarItem(index1, 0))}
                                        >{item.title}</Navbar.Link>}
                                    {item.pageRef === "dropdown" &&
                                        <Dropdown
                                            label={<span className='text-col-p2 md:text-xl'>{item.title}</span>}
                                            arrowIcon={true}
                                            inline
                                            onClick={() => setSelectedMenuItem(new SelectedNavbarItem(index1, 0))}
                                        >
                                            {item.children.map((child, index2) => (
                                                <Dropdown.Item key={"navbar" + index1 + "-" + index2}>{child.title}</Dropdown.Item>
                                            ))
                                            }
                                        </Dropdown>
                                    }

                                </React.Fragment>
                            ))}

                        </Navbar.Collapse>
                        <div>
                            <Link to={""} >Log in</Link>
                        </div>
                    </Navbar>
                </div>

            </Row>
            <Button onClick={() => editNavBar("addNew")}>Add New</Button>
            <Row>
                {/* ============================================ Adding new top level navbar element */}
                {selectedMenuItem && navbar.elements[selectedMenuItem.topLevelIndex] &&
                    <div>
                        <div className="grid grid-cols-6 gap-3">
                            <div>Title</div>
                            <div>Route</div>
                            <div>Type</div>
                            <div>Page</div>
                            <div>Required Claims</div>
                            <div>Actions</div>
                        </div>
                        <div className="grid grid-cols-6 gap-3">
                            <TextInput type="text" value={navbar.elements[selectedMenuItem.topLevelIndex].title} onChange={(e) => editNavBar("titleTop", e.target.value)}></TextInput>
                            <TextInput type="text" value={navbar.elements[selectedMenuItem.topLevelIndex].route} onChange={(e) => editNavBar("routeTop", e.target.value)}></TextInput>
                            <ToggleSwitch checked={navbar.elements[selectedMenuItem.topLevelIndex].type === 0 ? true : false} label="Type" onChange={() => editNavBar("typeTop")}></ToggleSwitch>
                            {/* ============================= For gridslate pages */}
                            {navbar.elements[selectedMenuItem.topLevelIndex].type === 0 &&
                                <Select
                                    value={navbar.elements[selectedMenuItem.topLevelIndex].pageId}
                                    onChange={(e) => editNavBar("setpageIdTop", e.target.value)}
                                >
                                    <option value='none'>Select link or set as dropdown</option>
                                    <option value='dropdown'>Is dropdown</option>
                                    {pages && pages.map((page, index) => (
                                        <option key={"loadpagedropdown" + index} value={page.id}>{page.name}</option>
                                    ))}
                                </Select>}
                            {/* ============================= For constructed pages */}
                            
                            {navbar.elements[selectedMenuItem.topLevelIndex].type === 1 &&
                                <MultiSelect
                                    value={stringToMultiSelectOption(navbar.elements[selectedMenuItem.topLevelIndex].pageId)}
                                    onChange={(e) => editNavBar("setpageIdTop", multiSelectOptionToString(e))}
                                    options={pageGroups.convertPagesToGroupedOptions()}
                                    primaryColor='#4F46E5'
                                />}

                            <MultiSelect
                                value={stringArrayToMultiSelectOptions(navbar.elements[selectedMenuItem.topLevelIndex].requiredClaims)}
                                isMultiple={true}
                                onChange={(e) => { editNavBar("requiredClaimsTop", multiSelectOptionsToStringArray(e)) }}
                                options={claimsList}
                                primaryColor='#4F46E5'
                            />

                            <div className="grid grid-cols-3 gap-2">
                                <Button onClick={() => editNavBar("moveLinkLeft")}>Move Left</Button>
                                <Button onClick={() => editNavBar("moveLinkRight")}>Move Right</Button>
                                <Button onClick={() => editNavBar("deleteLink")}>Delete</Button>
                            </div>

                        </div>

                        {/* ============================================ Adding new child elements */}
                        {navbar.elements[selectedMenuItem.topLevelIndex].pageId === "dropdown" && <div>

                            <Button onClick={() => editNavBar("addDropDownLink")}>Add drop down link</Button>

                            {navbar.elements[selectedMenuItem.topLevelIndex].children.map((child, index) => (
                                <div key={"navbardropdowns" + index}>
                                    <div className="grid grid-cols-6 gap-3">
                                        <div>Title</div>
                                        <div>Route</div>
                                        <div>Type</div>
                                        <div>Page</div>
                                        <div>Required Claims</div>
                                        <div>Actions</div>
                                    </div>
                                    <div className="grid grid-cols-6 gap-3">
                                        <TextInput type="text" value={child.title} onChange={(e) => editNavBar("titleChild", e.target.value, index)}></TextInput>
                                        <TextInput type="text" value={child.route} onChange={(e) => editNavBar("routeChild", e.target.value, index)}></TextInput>
                                        <ToggleSwitch checked={navbar.elements[selectedMenuItem.topLevelIndex].children[index].type === 0 ? true : false} label="Type" onChange={() => editNavBar("typeChild", '', index)}></ToggleSwitch>
                                        {/* ============================= For gridslate pages */}
                                        {navbar.elements[selectedMenuItem.topLevelIndex].children[index].type === 0 &&
                                            <Select
                                                value={navbar.elements[selectedMenuItem.topLevelIndex].children[index].pageId}
                                                onChange={(e) => editNavBar("setpageIdChild", e.target.value, index)}
                                            >
                                                <option value='none'>Select link</option>
                                                {pages && pages.map((page, index) => (
                                                    <option key={"loadpagedropdown" + index} value={page.id}>{page.name}</option>
                                                ))}
                                            </Select>}
                                        {/* ============================= For constructed pages */}
                                      
                                        {navbar.elements[selectedMenuItem.topLevelIndex].children[index].type === 1 &&
                                            <MultiSelect
                                                value={stringToMultiSelectOption(navbar.elements[selectedMenuItem.topLevelIndex].children[index].pageId)}
                                                onChange={(e) => editNavBar("setpageIdChild", multiSelectOptionToString(e), index)}
                                                options={pageGroups.convertPagesToGroupedOptions()}
                                                primaryColor='#4F46E5'
                                            />}

                                        <MultiSelect
                                            value={stringArrayToMultiSelectOptions(navbar.elements[selectedMenuItem.topLevelIndex].children[index].requiredClaims)}
                                            isMultiple={true}
                                            onChange={(e) => { editNavBar("requiredClaimsChild", multiSelectOptionsToStringArray(e), index) }}
                                            options={claimsList}
                                            primaryColor='#4F46E5'
                                        />

                                        <div className="grid grid-cols-3 gap-2">
                                            <Button onClick={() => editNavBar("moveLinkUp", "", index)}>Move Up</Button>
                                            <Button onClick={() => editNavBar("moveLinkDown", "", index)}>Move Down</Button>
                                            <Button onClick={() => editNavBar("deleteDropdownLink", "", index)}>Delete</Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}

                    </div>}

            </Row>
        </Container >
    )


}

export default NavbarConstructorPanel