//React
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//UI

//Services

//Logics

//Components

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { User } from 'classes/accounts/User';

const Logout = () => {
    const navigate = useNavigate();

    const [user, setUser] = useAtom(userAtom);

    useEffect(() => {
        //Todo: logout on server
        //dispatch(setLoggedIn({ loggedIn: false }));
        setUser(new User());
        localStorage.removeItem("accessToken");
        navigate("/");
    });

    return (
        <div className='container mx-auto'>
            <h1>Logging out...</h1>
        </div>
    );
}

export default Logout;