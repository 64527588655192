//For simple codeblocks inside text views, NOT for code exercise challenges

//import { Button } from 'flowbite-react';
import Button from 'view_components/core/Button';

import { useState } from 'react';
import CodeTryItViewer from './TryItCodeblockElement';
import parseCodeEditor from 'logic/parse/parseCodeEditor';

type Props = {
    code: string;
    enableTryIt: boolean;
}

const renderCodeLines = (code: string) => {
    let parsedCode = parseCodeEditor.parseCodeLines(code, "thisCode");
    let codeLines = parsedCode.split("\n");
    let codeLineElements = [];
    for (let line of codeLines) {
        let newElement = [];
        //Extract span and class text from line
        let regex = /<span class="([^"]*)">([^<]*)<\/span>/g;
        let match;
        let lastIndex = 0;
        while (match = regex.exec(line)) {
            let text = line.substring(lastIndex, match.index);

            //Parse special characters, like &amp to & etc
            text = text.replace(/&amp;/g, "&");
            text = text.replace(/&lt;/g, "<");
            text = text.replace(/&gt;/g, ">");

            

            newElement.push(text);
            newElement.push(<span className={match[1]}>{match[2]}</span>);
            lastIndex = match.index + match[0].length;
        }
        newElement.push(line.substring(lastIndex));
        codeLineElements.push(newElement);

    }

    console.log("codeLines", codeLines);
    console.log("codeLineElements", codeLineElements);

    return codeLineElements.map((line, index) => {
        return <div key={"line-" + index}>{line}
        
        </div>
    });
    

    // return codeLines.map((line, index) => {
    //     return <div key={"line-" + index}>{line}
        
    //     </div>
    // });

    
}

const CodeblockEelement = (props: Props) => {
    const { code, enableTryIt } = props;
    const [tryIt, setTryIt] = useState(false);

    //TODO: fix safe rendering of pre code

    return (
        <div>
            {tryIt && <CodeTryItViewer code={code} />}
            {!tryIt && <div className="relative">
                {enableTryIt && <Button className="absolute right-0 m-1 text-xs" onClick={() => { setTryIt(true) }} >Try it</Button>}
                {/* <pre><code>{code}</code></pre> */}
                <pre ><code dangerouslySetInnerHTML={{ __html: parseCodeEditor.parseCodeLines(code, "thisCode")}}></code></pre>
                {/* <pre ><code className="">{renderCodeLines(code)}</code></pre> */}
                
            </div>}
        </div>
    );
}

export default CodeblockEelement;

