class ResponseObject {
    payload: any;
    success: Boolean;
    statusCode: number;
    internalErrorCode: number = 0;
    message: string;
    errorSource : string;

    constructor() {
        this.payload = null;
        this.success = true;
        this.statusCode = 0;
        this.message = "";
        this.errorSource = "";
    }
}

export { ResponseObject };