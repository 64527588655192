import { NavbarChild } from './NavbarChild';
import createGUID from "logic/utility/createGUID";

class NavbarElement {
    id : string = createGUID(10);
    route: string;
    pageId: string;
    pageRef: string;
    type: number = 0;
    title: string;
    requiredClaims: string[] = [];
    isVisible: boolean = true;
    children: NavbarChild[];

    constructor(pageId: string, pageRef: string, title: string, route: string, children: NavbarChild[]) {
        this.pageId = pageId;
        this.pageRef = pageRef;
        this.title = title;
        this.route = route;
        this.children = children;
    }
    
}

export { NavbarElement};