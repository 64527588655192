//React

//UI

//Services

//Logics

//Classes
import { CustomPages } from "classes/enums/custom_pages/CustomPages";

//Components
import PageConstructor from "views/redactreview/PageConstructor";
import CourseManager from "views/courseconstruction/CourseConstructor";
import CourseBrowser from "views/courseview/CourseBrowser";
import TestTurtle from "views/custom/tools/Turtle";
import Test from 'views/test/Test'
//import CourseTemplateBrowser from "views/redactreview/synapp/course/CourseTemplateBrowser";
import NavbarConstructor from "views/siteconstruction/NavbarConstructor";
import Home from "views/siteview/pages/Home";
import AccountManager from 'views/admin/AccountManager';
import HelpPage from 'views/siteview/pages/Help';
import CodePlayground from "views/custom/tools/CodePlayground";

type Props = {
    name: string;
}

const RenderCustomPage = (props: Props) => {

    let name = props.name;

    switch (name) {
        case CustomPages.TestTurtle:
            return (<TestTurtle></TestTurtle>);
        case CustomPages.Test:
            return <Test></Test>;
        // case CustomPages.CourseTemplateBrowser:
        //     return <CourseTemplateBrowser></CourseTemplateBrowser>;
      
       
        case CustomPages.NavbarConstructor:
            return <NavbarConstructor pages={null}></NavbarConstructor>;
        case CustomPages.Home:
            return <Home></Home>;
        // case customPages.CustomPages.NotFound:
        //     return <NotFound></NotFound>;
      
      
        case CustomPages.AccountManager:
            return <AccountManager></AccountManager>;
      
        case CustomPages.HelpPage:
            return <HelpPage></HelpPage>;
        case CustomPages.PageConstructor:
            return <PageConstructor></PageConstructor>;
      
        case CustomPages.CourseBrowser:
            return <CourseBrowser></CourseBrowser>;
        case CustomPages.CourseManager:
            return <CourseManager></CourseManager>;
            case CustomPages.CodePlayground:
            return <CodePlayground></CodePlayground>;

        default:
            return (<></>);
    }
}

export default RenderCustomPage