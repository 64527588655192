
import { CheckpointTypes } from "classes/enums/CheckpointTypes";
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";

class ChallengeCheckpoint {
    id: string = createGUID(10);
    elementId: string = "";
    type: CheckpointTypes = CheckpointTypes.Continue; //TODO: Change to enum
    categoryIds: string[] = [];
    minimumPointsRequired: number = 2;
    numberOfQuestionsPerAttempt: number = 3;
}

export {ChallengeCheckpoint};