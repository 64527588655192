
enum CustomPages {
    TestTurtle = "TestTurtle",
    Test = "Test",
    PageConstructor = "PageConstructor",
    NavbarConstructor = "NavbarConstructor",
    CourseInstanceConstructor = "CourseInstanceConstructor",
    CourseInstanceBrowser = "CourseInstanceBrowser",
    CourseTemplateBrowser = "CourseTemplateBrowser",
    ChallengeConstructor = "ChallengeConstructor",
    PageChallengeConstructor = "PageChallengeConstructor",
    PageChallengeViewer2 = "PageChallengeViewer2",
    PageChallengeInstanceViewer = "PageChallengeInstanceViewer",
    AccountManager = "AccountManager",
    Home = "Home",
    HelpPage = "HelpPage",
    StageFlashGame = "StageFlashGame",
    RunFlashGame = "RunFlashGame",
    PageViewer = "PageViewer",
    CourseBrowser = "CourseBrowser",
    CourseManager = "CourseManager",
    CodePlayground = "CodePlayground",
}

export { CustomPages };
