import { useCallback } from 'react';
import { Editor } from '@tiptap/react';
import {ExtensionType} from 'classes/enums/ExtensionType';

type Props = {
    editor: Editor;
    bubble: boolean;
}

const LinkPanel = () => {
    return (
        <div className="flex-auto">
            <input
                type="text"
                placeholder="Paste the link..."
                className="bg-gray-200 p-2 rounded m-2"
            />
            <button
                className="bg bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2"
            >
                Apply
            </button>
        </div>
    )
    // <div className="p-2 bg-white rounded-lg dark:bg-black shadow-sm border border-neutral-200 dark:border-neutral-800">
    //     <form className="flex items-center gap-2">
    //         <label className="flex items-center gap-2 p-2 rounded-lg bg-neutral-100 dark:bg-neutral-900 cursor-text">
    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-link w-4 h-4 flex-none text-black dark:text-white"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
    //             </svg><input className="flex-1 bg-transparent outline-none min-w-[12rem] text-black text-sm dark:text-white" placeholder="Enter URL" type="url" value="">
    //         </label>
    //         <button disabled={} className="flex group items-center justify-center border gap-2 text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap text-white bg-black border-black dark:text-black dark:bg-white dark:border-white py-1 px-2" type="submit">Set Link</button>
    //     </form><div className="mt-3">
    //         <label className="flex items-center justify-start gap-2 text-sm font-semibold cursor-pointer select-none text-neutral-500 dark:text-neutral-400">Open in new tab<button className="inline-flex cursor-pointer items-center rounded-full border-transparent transition-colors bg-neutral-200 hover:bg-neutral-300 dark:bg-neutral-800 dark:hover:bg-neutral-700 h-5 w-9 px-0.5" type="button" role="switch" aria-checked="false" data-state="unchecked" value="off"><span className="rounded-full pointer-events-none block transition-transform bg-white dark:bg-black h-4 w-4 translate-x-0" data-state="unchecked"></span></button></label></div></div>
}

const TiptapPanelMarks = (props: Props) => {
    let editor = props.editor;

    const setLink = useCallback(() => {
        if (!editor) {
            return
        }
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()

            return
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
    }, [editor])

    if (!editor) {
        return null
    }

    const returnButtonClassName = (isActive: Boolean) => {
        return "bg " + (isActive ? "bg-col-p2" : "bg-col-s2") + " hover:bg-col-s1 text-white font-bold py-2 px-4 rounded m-2";
    }

    const returnBubbleClassName = () => {
        let bubbleClassName = "flex-auto";
        if (props.bubble) {
            //Place buttons in a panel with a white background and a border
            bubbleClassName = "flex-auto bg-white border border-gray-300 p-2 rounded shadow-lg";
        }
        return bubbleClassName;
    }

    const isValidTarget = () => {
        let isValid = true;
        if (editor.isActive('codeBlock') || editor.isActive('image') || editor.isActive(ExtensionType.Checkpoint) || editor.isActive(ExtensionType.CourseTextChallenge) || editor.isActive(ExtensionType.CodeExerciseChallenge)) {
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            {isValidTarget() && <div className={returnBubbleClassName()}>

                {/* ================================= Marks */}
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    className={returnButtonClassName(editor.isActive('bold'))}
                >
                    Bold
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    className={returnButtonClassName(editor.isActive('italic'))}

                >
                    Italic
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                    }
                    className={returnButtonClassName(editor.isActive('strike'))}
                >
                    Strike
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleCode()
                            .run()
                    }
                    className={returnButtonClassName(editor.isActive('code'))}
                >
                    Code
                </button>
                {/* ================================= Highlight */}
                <button
                    onClick={() => editor.chain().focus().unsetHighlight().run()}
                    disabled={!editor.isActive('highlight')}
                    className={returnButtonClassName(editor.isActive('highlight'))}
                >
                    Remove highlight
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHighlight({ color: 'yellow' }).run()}
                    className={returnButtonClassName(editor.isActive('highlight', { color: '#yellow' }))}
                >
                    Highlight Yellow
                </button>
                <button
                    onClick={() => editor.chain().focus().unsetAllMarks().run()}
                    className='bg bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2'
                >
                    Clear marks
                </button>

                <input
                    type="color"
                    //onInput={event => editor.chain().focus().setColor(event.target.value).run()}
                    onChange={event => editor.chain().focus().setColor(event.target.value).run()}
                    value={editor.getAttributes('textStyle').color}
                    data-testid="setColor"
                />
                {/* ================================= Link */}
                <button
                    onClick={setLink}
                    className={returnButtonClassName(editor.isActive('link'))}
                >
                    Set link
                </button>
                <button
                    onClick={() => editor.chain().focus().unsetLink().run()}
                    className={returnButtonClassName(editor.isActive('link'))}
                    disabled={!editor.isActive('link')}
                >
                    Unset link
                </button>

            </div>}
        </>
    )
}
export default TiptapPanelMarks;  