import { Editor } from '@tiptap/react';
//import TiptapPanelControls from './TiptapPanelControls';
import TiptapPanelControls from '../tiptappanels2/TiptapPanelControls';
//import TiptapPanelTypes from '../tiptappanels2/TiptapPanelTypes';
//import TiptapPanelTypes from './TiptapPanelTypes';
import TiptapPanelSynappTypes from "../tiptappanels2/TiptapPanelSynappTypes";

type Props = {
    editor: Editor;
    disallowedNodes: string[];
    disallowedControls: string[];
}

const TiptapToolBar = (props: Props) => {
    const { editor, disallowedNodes, disallowedControls } = props;

    if (!editor) {
        return null
    }

    return (
        <div className='sticky top-5 z-10 bg-white rounded border-col-s1 border-4'>
            <div className="flex gap-2 bg-col-t1">
                <TiptapPanelControls editor={editor} disallowedControls={disallowedControls} />
                <TiptapPanelSynappTypes editor={editor} disallowedNodes={disallowedNodes} />
            </div>
        </div>

    )
}
export default TiptapToolBar;  