import createGUID from "logic/utility/createGUID";
class NavbarChild {
    id : string = createGUID(10);
    route : string;
    pageRef: string;
    pageId: string;
    title: string;
    requiredClaims: string[] = [];
    type: number = 0;
    isVisible: boolean = true;

    constructor(pageRef: string, pageId: string, title: string, route: string) {
        this.pageRef = pageRef;
        this.pageId = pageId;
        this.title = title;
        this.route = route;
    }
}

export { NavbarChild};