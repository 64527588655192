
//import { CheckpointTypes } from "classes/enums/CheckpointTypes";
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";
import { ChallengeCheckpoint } from "classes/course/ChallengeCheckpoint";
import { CourseChallengeType } from "classes/enums/CourseChallengeType";
import time from 'logic/utility/time';
class CourseChallenge {
    id: string = createGUID(10);
    elementId: string = "";
    type: CourseChallengeType = CourseChallengeType.TextChallenge;
    name: string = "";
    categoryIds: string[] = [];
    minimumPointsRequired: number = 10;
    startDate: number =  time.now().timeStamp;
    dueDate: number =  time.now().addDays(7).timeStamp;
    passedDueDatePolicyFlag: number = 0;
    checkpoints: ChallengeCheckpoint[] = [];
    courseContentId: string = "none";
    statusCode: StatusCode = StatusCode.new;

    constructor(type: CourseChallengeType = CourseChallengeType.TextChallenge, categoryIds: string[] = []) {
        this.type = type;
        this.categoryIds = categoryIds;
    }
}

export {CourseChallenge};