//React
import { useEffect } from "react";

//UI

//Services
import { useAtom } from 'jotai';
import { courseAtom } from 'atom';

//Logics

//Classes
import { CheckpointTypes} from 'classes/enums/CheckpointTypes'

//Components
import McqConstructor from "views/courseconstruction/McqConstructor";
import CategoryManager from "views/courseconstruction/CategoryManager";
import { NodeViewWrapper } from '@tiptap/react'

export default (props: any) => {

    let checkpoint = props.node.attrs.checkpoint;

    const [nodeCourse] = useAtom(courseAtom);

    useEffect(() => {
        //Set the categoryIds from parent CourseContent if they are not set
        //I suspect this is not working
        if (checkpoint.categoryIds.length === 0 && nodeCourse.currentlyLoadedParent) {
            //To prevent tiptap flush sync error
            setTimeout(() => {
                editCheckpoint("categoryIds", nodeCourse.currentlyLoadedParent?.categoryIds);    
            }, 200);
            
        }
    });
 
    const editCheckpoint = (target: string, value: any) => {
        let newCheckpoint = {...checkpoint};
        newCheckpoint[target] = value;
        props.updateAttributes({
            checkpoint: newCheckpoint,
        })
    }
   
    return (
        <NodeViewWrapper className="checkpoint" data-localid={props.node.attrs.elementId}>
            <label>Checkpoint</label>
            
            <div className="content">
                <select value={checkpoint.type} onChange={(e) => editCheckpoint("type", e.target.value)}>
                    <option value={CheckpointTypes.Continue}>Continue</option>
                    <option value={CheckpointTypes.Mcq}>Mcq</option>
                </select>
            </div>

            {checkpoint.type === CheckpointTypes.Mcq && <div>
                <CategoryManager categoryIds={checkpoint.categoryIds} setCategoryIds={(e: any) => editCheckpoint("categoryIds", e)} editable={true} />
                Minimum Points Required: <input type="number" value={checkpoint.minimumPointsRequired} onChange={(e) => editCheckpoint("minimumPointsRequired", e.target.value)} />
                <McqConstructor content={""} getContent={() => {}} baseCategoryIds={checkpoint.categoryIds}  />
            </div>}
        </NodeViewWrapper>
    )
}