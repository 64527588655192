// "userToken": {},
// "loggedIn": false,
// "username": "",
// "email": "",
// "claims": "",
// "refreshExpirationTime" : ""

class User {
    id : string;
    loggedIn: boolean;
    username: string;
    fullname: string;
    handle: string;
    email: string;
    claims: string;
    refreshExpirationTime: number;
    checkedForToken: boolean;
    initials: string = "";

    constructor() {
        this.id = "";
        this.loggedIn = false;
        this.username = "";
        this.fullname = "";
        this.handle = "";
        this.email = "";
        this.claims = "";
        this.refreshExpirationTime = 0;
        this.checkedForToken = false;
    }

    setUserFromToken(userToken: any) {
        this.id = userToken.id;
        this.claims = userToken.claims;
        this.email = userToken.email;
        this.username = userToken.username;
        this.fullname = userToken.fullname;
        this.handle = userToken.handle;
        this.refreshExpirationTime = userToken.refreshExpirationTime;
        this.loggedIn = true;
        this.checkedForToken = true;
        return this;
    }

    getInitials = (fullname: string) => {
        if (fullname) {
          let initials = fullname.split(' ').map((name: string) => name.charAt(0).toUpperCase());
          if (initials.length > 1) {
            return initials[0] + initials[initials.length - 1];
          } else {
            return initials[0];
          }
        } else {
          return '';
        }
      }
}

export {User};