
class GoogleRegisterAccount {
    
    username: string = "";
    password: string = "";
    fullname: string = "";
    handle: string = "";
    email: string = "";
    googleCredentialString: string = "";

    constructor(email: string, password: string, fullname: string, googleCredentialString: string) {
        this.username = email;
        this.password = password;
        this.fullname = fullname;
        this.handle = fullname;
        this.email = email;
        this.googleCredentialString = googleCredentialString;
    }

}

export {GoogleRegisterAccount};