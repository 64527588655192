import { useState, useEffect } from 'react';
import { useAtom} from 'jotai';
import { windowDimensionsAtom } from 'atom';
import { WindowDimensions } from 'classes/general/WindowDimensions';
//TODO: Reconsider using Jotai here

// from: https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
  const { innerWidth: viewWidth, innerHeight: viewHeight } = window;
 

    let viewBreakpoint = "";
    if (viewWidth <= 640) {
      viewBreakpoint = "sm";
    }
    if (viewWidth > 640 && viewWidth <= 768) {
      viewBreakpoint = "md";
    }
    if (viewWidth > 768 && viewWidth <= 1024) {
      viewBreakpoint = "lg";
    }
    if (viewWidth > 1024 && viewWidth <= 1280) {
      viewBreakpoint = "xl";
    }
    if (viewWidth > 1280) {
      viewBreakpoint = "2xl";
    }

    return {
    viewWidth,
    viewHeight,
    viewBreakpoint
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [windowDimensionsA, setWindowDimensionsA] = useAtom(windowDimensionsAtom);

  useEffect(() => {
    function handleResize() {
      let dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
      setWindowDimensionsA(new WindowDimensions(dimensions.viewWidth, dimensions.viewHeight, dimensions.viewBreakpoint));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export {useWindowDimensions};