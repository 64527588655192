//import { Slide, Fade } from "react-awesome-reveal";
import { useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { Container } from 'view_components/helper/HelperComponents';
import Button from 'view_components/core/Button';
import tiptapControlIcons from 'view_components/icons/tiptapControlIcons';
import tiptapCustomNodeIcons from 'view_components/icons/tiptapCustomNodeIcons';
//import useS3Fetch from 'services/useS3Fetch';
//import "animate.css/animate.min.css";

const Test = () => {

    //const defaultClass = "bg bg-blue-300 hover:bg-blue-700 text-black font-bold py-2 px-4 rounded m-2";
    const defaultSize = 10;
    const defaultClass = "bg select-none bg-col-t1 hover:bg-col-p1 text-white rounded size-" + defaultSize;
    const defaultCol = "col-p2";

    const [alignmentSetting, setAlignmentSetting] = useState("left");
    const [headingSize, setHeadingSize] = useState(1);
    const [textColor, setTextColor] = useState("black");
    const [highlightColor, setHighlightColor] = useState("none");
    const [showTextColorMenu, setShowTextColorMenu] = useState(false);

    const switchAlignment = () => {
        if (alignmentSetting === "left") {
            setAlignmentSetting("center");
        } else if (alignmentSetting === "center") {
            setAlignmentSetting("right");
        } else if (alignmentSetting === "right") {
            setAlignmentSetting("justify");
        } else if (alignmentSetting === "justify") {
            setAlignmentSetting("left");
        }
    }

    return (
        <Container>
            <div>
                <Button onClick={() => alert("button pressed")}>TestButton</Button>
                {/* {JSON.stringify(navbarS3)} */}
                <h1>Test</h1>
                <div className="flex gap-1 mb-4">
                    {/* =================================== Controls*/}
                    <div className={defaultClass}>
                        {tiptapControlIcons.undoIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.redoIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass} onClick={() => switchAlignment()}>
                        {alignmentSetting === "left" && tiptapControlIcons.alignmentLeftIcon(defaultSize * 4, defaultCol)}
                        {alignmentSetting === "center" && tiptapControlIcons.alignmentCenterIcon(defaultSize * 4, defaultCol)}
                        {alignmentSetting === "right" && tiptapControlIcons.alignmentRightIcon(defaultSize * 4, defaultCol)}
                        {alignmentSetting === "justify" && tiptapControlIcons.alignmentJustifyIcon(defaultSize * 4, defaultCol)}
                    </div>

                    {/* <div className={defaultClass}>
                        {tiptapControlIcons.fontFamilyIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.fontSizeIcon(defaultSize * 4, defaultCol)}
                    </div> */}
                </div>
                {/* =================================== Marks*/}
                <div className="flex gap-1  mb-4">
                    <div className={defaultClass}>
                        {tiptapControlIcons.boldIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.italicIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.underlineIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.strikeIcon(defaultSize * 4, defaultCol)}
                    </div>
                    {/* Text color */}
                    <div className={defaultClass} onClick={() => setShowTextColorMenu(true)}>
                        {tiptapControlIcons.textColorIcon(defaultSize * 4, textColor)}
                        {showTextColorMenu &&
                            <div className="absolute z-10 bg-white border border-gray-300 p-2 rounded shadow-lg">
                                <input
                                    type="color"
                                    //onInput={event => editor.chain().focus().setColor(event.target.value).run()}
                                    //onChange={event => editor.chain().focus().setColor(event.target.value).run()}
                                    onChange={(e) => { setTextColor(e.target.value); setShowTextColorMenu(false) }}
                                    value={textColor}
                                    data-testid="setColor"
                                />
                            </div>
                        }
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.highlightColorIcon(defaultSize * 4, highlightColor)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.codeIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.linkIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.footnoteIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.superscriptIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.subscriptIcon(defaultSize * 4, defaultCol)}
                    </div>
                </div>
                {/* =================================== Nodes*/}
                <div className="flex gap-1 mb-4">
                    <div className={defaultClass}>
                        {tiptapControlIcons.paragraphIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div>
                        <Dropdown size="sm" value={headingSize} label={"H" + headingSize} dismissOnClick={true}>
                            <Dropdown.Item onClick={() => setHeadingSize(1)} value={1}>H1</Dropdown.Item>
                            <Dropdown.Item onClick={() => setHeadingSize(2)} value={2}>H2</Dropdown.Item>
                            <Dropdown.Item onClick={() => setHeadingSize(3)} value={3}>H3</Dropdown.Item>
                            <Dropdown.Item onClick={() => setHeadingSize(4)} value={4}>H4</Dropdown.Item>
                            <Dropdown.Item onClick={() => setHeadingSize(5)} value={5}>H5</Dropdown.Item>
                            <Dropdown.Item onClick={() => setHeadingSize(6)} value={6}>H6</Dropdown.Item>
                        </Dropdown>
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.bulletListIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.orderedListIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.blockQuoteIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.codeBlockIcon(defaultSize * 4, defaultCol)}
                    </div>
                    {/* <div className={defaultClass}>
                        {tiptapControlIcons.headingIcon(defaultSize * 4, defaultCol)}
                    </div> */}
                    <div className={defaultClass}>
                        {tiptapControlIcons.horizontalRuleIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.imageIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapControlIcons.tableIcon(defaultSize * 4, defaultCol)}
                    </div>

                </div>
                {/* =================================== Custom Nodes*/}
                <div className="flex gap-1 mb-4">
                    <div className={defaultClass}>
                        {tiptapCustomNodeIcons.checkpointIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapCustomNodeIcons.chapterIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapCustomNodeIcons.textChallengeIcon(defaultSize * 4, defaultCol)}
                    </div>
                    <div className={defaultClass}>
                        {tiptapCustomNodeIcons.codeChallengeIcon(defaultSize * 4, defaultCol)}
                    </div>
                </div>

                <p className="animate__animated animate__bounce">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    posuere. Sed ac est eu nunc vestibulum pellentesque
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec dui nec eros convallis tincidunt. Donec id sapien auctor, ultricies sapien eu, tincidunt nunc. Integer in nunc nec nunc ultricies luctus. Nullam nec sapien nec nunc consequat
                    equat
                    posuere. Sed ac est eu nunc vestibulum pellentesque

                    <br />
                    <br />
                </p>
            </div>

        </Container >

    );
}

export default Test;