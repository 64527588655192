import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

type Props = {
    showConfirmDeleteModal: boolean;
    setShowConfirmDeleteModal: Function;
    handleDelete: Function;
    messageString: string;
}


const ConfirmDeleteModal = (props: Props) => {

    const { showConfirmDeleteModal, setShowConfirmDeleteModal, messageString, handleDelete } = props;

    return (<Modal show={showConfirmDeleteModal} size="md" onClose={() => setShowConfirmDeleteModal(false)} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {messageString}
          </h3>
          <div className="flex justify-center gap-4">
            {/* <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); deleteType === 'template' ? handleDeleteCourseTemplate() : handleDeleteWordSetGroup() }}> */}
            <Button color="failure" onClick={() => { setShowConfirmDeleteModal(false); handleDelete() }}>
              {"Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => setShowConfirmDeleteModal(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>)
}

export default ConfirmDeleteModal;