import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(response => {
   
    const localAccessToken = localStorage.getItem("accessToken");
    const responseAccessToken = response.headers ? response.headers["token"] : null;
   
    if (responseAccessToken && localAccessToken !== responseAccessToken) {
   
        if (responseAccessToken.length > 32) {
            localStorage.setItem("accessToken", responseAccessToken);
        }
    }

    if (typeof response.data === 'object' && response.data !== null) {
        response.data.errorCaught = false;
        if (response.data.success === false) {
            response.data.errorCaught = true;
        }
    } else {
        //TODO: Possibly check response status code here
        response.data = {
            payload: response.data,
            errorCaught: false
        }
    }
    
    return response.data;
    //return new httpResponse();
    
}, error => {

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    
    console.log("Axios intercept: ", error);

    let responseObject = {
        errorCaught: true,
        statusCode: error.response ? error.response.status : null,
        message: ""
    }

    if (error.response && error.response.status === 401) {
        console.log("Unauthorized!");
        toast.error("Unauthorized! Please login again.");
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("refreshToken");
        // window.location = "/";
    }

    if (error.response && error.response.status === 400) {
        console.log("Bad request!");
        toast("Oops. An error occured. Some data was not configured properly.");
    }

    if (error.response && error.response.status === 404) {
        console.log("Resource not found! 404 Error");
        toast("Oops. An error occured. What you are looking for does not exist!");
    }

    if (error.response && error.response.status === 500) {
        console.log("Internal server error");
        toast("Oops. Something is wrong with the server.");
    }

    if (!expectedError) {
        //For unexpected errors
        //console.log(error);
        //toast.error("An unexpected error occured!");
    }
    
    //Promise.resolve();

    return responseObject;
    //return Promise;
    //return Promise.reject(error);

});

export default {
    get: (url) => axios.get(url, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    post: (url, data) => axios.post(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    upload: (url, data) => axios.post(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken"),
            'Content-Type': 'multipart/form-data'
            //'Accept': 'application/json'
        }
    }),
    //For Amazon S3
    put: (url, data) => axios.put(url, data, {
        headers: {
            // Authorization: localStorage.getItem("accessToken"),
            // Refresh: localStorage.getItem("refreshToken"),
            'Content-Type': 'image/jpeg/png',
        }
    }),
    update: (url, data) => axios.put(url, data, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    }),
    delete: (url) => axios.delete(url, {
        headers: {
            Authorization: localStorage.getItem("accessToken"),
            Refresh: localStorage.getItem("refreshToken")
        }
    })

};