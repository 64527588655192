import { Status } from 'classes/enums/Status';
import { McqAnswer } from './McqAnswer';
import createGUID from 'logic/utility/createGUID';
import { StatusCode } from "classes/enums/StatusCode";

class Mcq {
    id: string = createGUID(10);
    ownerId: string = "";
    difficulty: number = 0; // 1-5
    categoryIds: string[] = [];
    isSelfContained: boolean = false;
    isReviewed: boolean = false;
    answers: McqAnswer[] = [];
    //componentJSON: string = "";
    contentDocument: string = "";
    statusCode: StatusCode = StatusCode.new;

    status: Status = Status.new;
}

export { Mcq };