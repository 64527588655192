

const SynappIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={257.499}
    height={257.147}
    viewBox="0 0 68.13 68.037"
    {...props}
  >
    <path
      d="M86.515 147.07c8.742-.035 17.485-.056 26.228.028-9.658.066-19.318.067-28.975 0 .915-.02 1.831-.02 2.747-.028zm56.354-22.556c-.03-2.451.354.723.032.962l-.023-.362zm-47.494.349c1.627-.266-.492.512 0 0zm0-.794c1.627-.265-.492.512 0 0zm25.664-22.225c1.627-.265-.491.512 0 0zm21.788-22.309c.271-1.604.117 1.504 0 0z"
      style={{
        fill: "#d0d0d0",
        fillOpacity: 1,
        strokeWidth: 0.264583,
      }}
      transform="translate(-74.733 -78.985)"
    />
    <path
      d="m108.254 124.846 12.3-.072.147-22.75-.008 22.887-24.74.008 12.3-.073zm-12.102-.771c1.05-.224.887.416 0 0zm25.946-23.025c1.627-.265-.492.512 0 0zM80.343 86.58c1.595-1.907.509.178 0 0zm1.323-1.323c1.595-1.907.509.178 0 0z"
      style={{
        fill: "#b9b9b9",
        fillOpacity: 1,
        strokeWidth: 0.264583,
      }}
      transform="translate(-74.733 -78.985)"
    />
    <path
      d="M136.17 94.484c2.248-2.288-.594 1.214-.595.654l.257-.294z"
      style={{
        fill: "#a3a3a3",
        fillOpacity: 1,
        strokeWidth: 0.264583,
      }}
      transform="translate(-74.733 -78.985)"
    />
    <path
      d="M74.923 145.36c.763-8.201 6.325-15.613 13.953-18.698 4.787-2.187 10.222-1.267 15.323-1.58l16.493-.17.138-22.96c8.514.333 16.758 5.617 20.077 13.562 2.217 4.649 2.092 10.109 1.114 15.053-1.886 6.523-6.727 12.067-13.021 14.664-4.388 2.207-9.492 1.347-14.223 1.644-13.348.064-26.696.096-40.044.147l.19-1.663zm17.699-21.768c-10.206-2.144-18.194-11.998-17.742-22.5.061-9.064 6.24-17.444 14.653-20.635 4.592-1.838 9.7-1.043 14.526-1.33 12.934-.06 25.87-.093 38.804-.142-.267 5.786-2.434 11.595-6.825 15.512-2.953 3.196-7.053 5.301-11.282 6.191-8.262.739-16.58.389-24.868.56-.93.294-3.135-.679-2.876.794v21.943c-1.473.003-2.947-.073-4.39-.393z"
      style={{
        fill: "#4a0",
        fillOpacity: 1,
        strokeWidth: 0.264583,
      }}
      transform="translate(-74.733 -78.985)"
    />
  </svg>
)
export default SynappIcon