//React

//Components 
import { Accordion } from 'flowbite-react';
import Slider from "react-slick";

//Services/Helpers

//Store

//Views/Components

//Classes
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HelpPage = () => {

    var helperImages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SampleNextArrow />,

    };

    function SampleNextArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ display: "block", color: "green", background: 'black' }}
                onClick={onClick}
            ></div>
        );
    }

    return (
        <div className='container mx-auto'>
            <div id='slider-show'>
                <Slider className='w-10/12 m-auto my-12' {...settings}>
                    {helperImages.map((item, index) => {
                        return (
                            <div key={index}>
                                <img className='w-10/12 m-auto' src={require(`assets/help/Step0${item}.jpg`)} alt="placeholder" />
                            </div>
                        );
                    })}
                </Slider>
            </div>
            {/* <p className='mb-8'>asd</p> */}
            <Accordion className='my-4'>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What is Synapp?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            Synapp is a platform that provides dynamic tools for teaching and learning.
                            These tools are designed to help teachers better engage students with learning content.
                            The current offering includes word flashsets and question/answer flashsets.
                            Future offerings include interactive presentations, multiple choice questions, polling tools, and more.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What is a course template?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                           A course template is a collection of flashsets that are grouped together by topic or theme.
                           Course templates can be either public or private.
                           These are called templates because there are no users attached to them.
                           In the future, I plan to add the ability to attach users to courses and allow teachers to track the scores of individual students and teams.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What is a word flashset?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            A word flashset is a set of words that can be used to help students learn new vocabulary or concepts.
                            The words are presented in a slideshow format. Check out the <a href='#slider-show' className='font-bold'>slideshow</a> above to see how this works.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            When launching the wordflash game, you can choose to display the words in a random order or in the order they were entered.
                            You can also preview the words before starting the game.
                            Each word flashset contains seperate subsets of words. You can choose to display all the words at once or in smaller groups.
                            Finally, you can set the countdown timer to any duration from between 15 seconds to 2 minutes.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What is a question/answer flashset?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            A question/answer set is similar to a word flashset.
                            The difference is that Q/A sets are designed to prompt students to develop their conversational skills.
                            The students facing the screen can see only the answer. 
                            They must intuit the question that corresponds to the answer.
                            The student facing away from the screen must then answer the question. 
                            In this way, all students are engaged in the learning process.
                            This is a great way to get students to practice their speaking and listening skills.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            Another way to use Q/A sets is to have students work in pairs or small groups.
                            One student launches the flashset on their mobile phone and then reads (or intuits) the questions while the other student answers.
                            Only one student needs to have a device to play the game.
                            The slideshow game is mobile-friendly and should work on any device.
                            Click the QRCode in the course browser to allow students to quickly launch a game.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            Once students have completed a round of questions, they can switch roles and play again.
                            Moreover, once the students have mastered the conversational patterns, they should be able to practice without the flashsets.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>Do I need an account to use this site?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            You do not need an account to use this site.
                            You, or your students, can launch any flashsets that are available in public courses without logging in.
                            However, you will need an account to create new flashsets or courses.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>How can I create new flashsets?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            To create new flashsets, you must first create a course.
                            Courses are collections of flashsets that are grouped together by topic or theme.
                            You need to be logged in to create a course. You can currently ONLY log in/create an account using Google signin.
                            Moreover, this is currently restricted to users with email addresses ending in "@woosong.org" or "@wsu.ac.kr".
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            If you are interested in using this platform, but are unable to sign up, please contact me at synapporg@gmail.com
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What is the difference between a public and private course?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            A public course is a course that is available to all users of the platform.
                            Anyone can browse or play the flashsets in a public course.
                            Moreover, anyone can copy a public course to their own account and then modify it.
                            However, only the original owner can edit the original course.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            A private course is a course that is only available to the creator of the course.
                            These courses are not visible to other users of the platform and cannot be copied.
                            You can change a course from public to private, or vice versa, at any time.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>Are there more features/tools coming?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            Yes, I plan to add more features and tools to this platform in the future.
                            The next major tool is an interactive presentation tool.
                            This will allow users to create interactive presentations that can be used in the classroom.
                            One major feature of this tool is interactive Kahoot-like slides that allow students to answer questions or participate in polls.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            Another major tool is a padlet-like tool that allows students to post questions or comments on a shared board.
                            These tools are designed in a way that participation points can be easily awarded and tallied.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What should I do if I have a feature suggestion?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            If you have a feature suggestion, please contact me at synapporg@gmail.com
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className='md:text-2xl'>What should I do if I encounter a serious bug or error?</Accordion.Title>
                    <Accordion.Content className='md:text-2xl'>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            If you encounter a bug, please contact me at synapporg@gmail.com and tell me as much as you can about the bug.
                            Any attached screenshots will be very helpful.
                        </p>
                        <p className="mb-2 text-col-p2 dark:text-gray-400">
                            In general, if you encounter a problem, try refreshing the page.
                            If that doesn't work, try logging in and out again (if you were logged in to start with).
                        </p>

                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
        </div>
    );
}

export default HelpPage;
