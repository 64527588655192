class WindowDimensions {
    width: number = 0;
    height: number = 0;
    breakpoint: string = "";

    constructor(viewWidth = 0, viewHeight = 0, viewBreakpoint = "") {
        this.width = viewWidth;
        this.height = viewHeight;
        this.breakpoint = viewBreakpoint;
    }
}

export {WindowDimensions};
