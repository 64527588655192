//React
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//UI
//import { Button } from 'flowbite-react';
import Button from 'view_components/core/Button';

//Services
import { useAtom } from 'jotai';
import { courseAtom, courseProgressAtom } from 'atom';

//Logics
import time from 'logic/utility/time';

//Classes
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { ChallengeProgress } from 'classes/courseprogress/ChallengeProgress';

//Components

type Props = {
    courseChallenge: CourseChallenge;
}

const CodeExerciseChallengeElement = (props: Props) => {

    const { courseChallenge } = props;
    const navigate = useNavigate();
    const [viewChallenge, setViewChallenge] = useState(false);
    const [nodeCourse] = useAtom(courseAtom);
    const [courseProgress] = useAtom(courseProgressAtom);

    // const thisChallenge = nodeCourse.courseChallenges.find((courseChallenge) => courseChallenge.masterPageLocalId === elementId) ?
    //     nodeCourse.courseChallenges.find((courseChallenge) => courseChallenge.masterPageLocalId === elementId) : undefined;

    //TODO: thisChallenge should not be needed, unless reload?
    let thisChallenge = nodeCourse.courseChallenges.find((courseChallenge) => courseChallenge.id === courseChallenge.id);
    let thisChallengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id) as ChallengeProgress;

    const getButtonText = () => {
        let buttonText = "Start Challenge";

        if (deadlinePassed()) {
            return "Review challenge";
        }
        
        if (thisChallengeProgress) {
            if (thisChallengeProgress.isComplete) {
                buttonText = "Review/Continue Challenge";
            }
        }
        return buttonText;
    }

    const deadlinePassed = () => {
        return courseChallenge.dueDate < Date.now();
    }

    return (
        <div>
            {!thisChallenge && <div>Challenge not found {JSON.stringify(nodeCourse.courseChallenges)}</div>}
            {thisChallenge && courseChallenge && <div>
                <div className="border border-col-p2 border-2 hover:bg-col-t1 m-2 flex flex-col items-center justify-center relative">
                    <div className="bg-col-p2 text-white rounded-br-md font-bold text-xs md:text-sm p-1 md:p-2 absolute top-0 left-0">Code Challenge</div>
                    <div className="text-lg md:text-2xl font-black mx-2 md:mx-4 mt-6 md:my-2">{courseChallenge.name}</div>
                    <div className="flex my-2">
                        {/* //TODO: fix this */}
                        <div className="mr-8 p-2"><span className="font-bold">Start Date:</span>{time.toCoolTimeString(thisChallenge.startDate)}</div>
                        <div className="mr-8 p-2"><span className="font-bold">Due Date:</span>{time.toCoolTimeString(courseChallenge.dueDate)}</div>
                        {thisChallengeProgress && thisChallengeProgress.isComplete && <div className="text-green-500">Completed: {time.toCoolTimeString(thisChallengeProgress.dateCompleted)}</div>}
                    </div>

                    {!viewChallenge && <Button className="mb-4" onClick={() => {
                        navigate("/CodeExerciseChallengeWrapper/" + courseChallenge.id, { state: { courseChallenge: courseChallenge } });
                    }}>{getButtonText()}</Button>}

                    {deadlinePassed() && <div className="text-red-500">Deadline passed</div>}
                </div>

            </div>}

        </div>
    )

}

export default CodeExerciseChallengeElement;


