enum Status {
    new = "new",
    updated = "updated",
    deleted = "deleted",
    unchanged = "unchanged",
    submitted = "submitted",
    //graded = "graded", //redact?
    unloaded = "unloaded",
    loaded = "loaded",
}

export { Status};