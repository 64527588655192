//React
import { useEffect, useState } from 'react';

//UI
import { Button, Modal, ToggleSwitch, Select } from "flowbite-react";

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';
import { toast } from 'react-toastify';
import { useAtom } from 'jotai';
import { categoriesAtom } from "atom";

//Logics

//Components
import ConfirmDeleteModal from 'views/shared/modals/ConfirmDeleteModal';

//Classes
import { Category } from 'classes/synapp/Category';
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';

class CreateCategoryTracker {
    categoryNames = "";
    parentId: string = "";
    level: number = 0;
}

type Props = {
    categoryIds: string[];
    setCategoryIds: Function;
    editable: boolean;
    lockedCategoryIds?: string[];
    levelLimit?: number;
}

const CategoryManager = (props: Props) => {

    const { setCategoryIds, categoryIds, editable, lockedCategoryIds, levelLimit } = props;
    const [categories, setCategories] = useAtom(categoriesAtom);
    const [tempCategoryIds, setTempCategoryIds] = useState<string[]>([...categoryIds, "None"]);
    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState<boolean>(false);
    const [deleteCategoryId, setDeleteCategoryId] = useState<string>("");
    const [createCategoryTracker, setCreateCategoryTracker] = useState<CreateCategoryTracker>(new CreateCategoryTracker());
    const [showConfirmDeleteCategoryModal, setShowConfirmDeleteCategoryModal] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    //const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [editingOn, setEditingOn] = useState(false);

    useEffect(() => {
        //Add None category
        if (categories.length > 0 && categories[0].name !== "None") {
            let rootCategory = new Category();
            rootCategory.id = "None";
            rootCategory.name = "None";
            rootCategory.level = 0;
            rootCategory.parentId = "None";

            let tempCategories = [rootCategory, ...categories];
            setCategories(tempCategories);
        }

    }, []);

    //Update categoryIds if lockedCategoryIds change
    //Set categoryIds to lockedCategoryIds
    useEffect(() => {
        if (lockedCategoryIds !== undefined) {
            let theseCategoryIds = [...categoryIds];
            for (let i = 0; i < lockedCategoryIds.length; i++) {
                theseCategoryIds[i] = lockedCategoryIds[i];
            }
            theseCategoryIds.push("None");
            setTempCategoryIds(theseCategoryIds);
        }
    }, [lockedCategoryIds]);

    const deleteCategory = async () => {
        let thisCategory = categories.find(x => x.id === deleteCategoryId);
        if (thisCategory === undefined || thisCategory.name === "None") {
            return;
        }
        
        let filterModel = new FilterModel([new PropertyFilter("Id", deleteCategoryId)]);
        let response = await crudService.del(Class.category, filterModel);
        if (response.success) {
            let tempCategories = [...categories];
            let index = tempCategories.findIndex(x => x.id === deleteCategoryId);
            tempCategories.splice(index, 1);
            setCategories(tempCategories);
            //TODO: remove selectedCategory from state
            toast.success("Category deleted");
        } else {
            errorService.handleError(response);
        }
    }

    const createUpdateCategory = async () => {
        setIsCreating(true);
        let newCategories = [];
        let categoryNames = createCategoryTracker.categoryNames.split(";");
        //remove empty strings
        categoryNames = categoryNames.filter((name) => name.trim() !== "");
        //TODO: check if category already exists

        for (let name of categoryNames) {
            let thisCategory = new Category();
            thisCategory.name = name.trim();
            thisCategory.parentId = createCategoryTracker.parentId;
            thisCategory.level = createCategoryTracker.level;
            newCategories.push(thisCategory);
        }

        let requestModel = newCategories;

        let response = await crudService.create(Class.category, requestModel);
        if (response.success) {
            let responseModel = response.payload as Category[];
            let tempCategories = [...categories];
            //TODO: Only creating new categories, not updating existing ones
            for (let category of responseModel) {
                tempCategories.push(category);
            }
            setCategories(tempCategories);
            toast.success("Category created");
            setIsCreating(false);
            setShowCreateCategoryModal(false);
        } else {
            errorService.handleError(response);
            setIsCreating(false);
        }
    }

    const updateCategoryIds = (level: number, categoryId: string) => {
        let newCategoryIds = [...categoryIds];
        //Replace category id at level, and remove all categories after that level
        //If level is the last level, add the new category id

        if (level === categoryIds.length) {
            newCategoryIds.push(categoryId);
        } else {
            newCategoryIds[level] = categoryId;
            newCategoryIds = newCategoryIds.slice(0, level + 1);
        }

        setCategoryIds([...newCategoryIds]);

        if (categories.find(x => x.id === categoryId)?.name !== "None") {
            if (level !== levelLimit){
                newCategoryIds.push("None");
            }
        }

        setTempCategoryIds(newCategoryIds);
        
    }

    //Creation modal
    const createCategoryModal = () => {
        return (<Modal show={showCreateCategoryModal} size="md" onClose={() => setShowCreateCategoryModal(false)} popup>
            <Modal.Header>
                Parent: {categories.find(x => x.id === createCategoryTracker.parentId)?.name}
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    Seperate name with semicolon for multiple categories:
                    <input className="w-full" type="text" placeholder='Category names here...'
                        value={createCategoryTracker.categoryNames}
                        onChange={(e) => setCreateCategoryTracker({ ...createCategoryTracker, categoryNames: e.target.value })} />
                    <Button disabled={isCreating} onClick={() => createUpdateCategory()}>{isCreating?"Creating...":"Create new category"}</Button>
                </div>
            </Modal.Body>
        </Modal>);
    }

    return (
        <div>
            {/* {JSON.stringify(categoryIds)} */}
            {editable && <div className='relative'>
                <ToggleSwitch color='gray' className='absolute right-2' checked={editingOn} label={editingOn ? 'Editing on' : 'Editing off'} onChange={() => setEditingOn(!editingOn)} />
            </div>}

            {/* No categories selected yet*/}
            <div className="flex flex-row gap-2">
                {tempCategoryIds.length === 0 && <div key={"selected-"} className="">
                    <Select value={categoryIds[0]} onChange={(e: any) => setCategoryIds([e.target.value, "None"])}>
                        <option value="None">None</option>
                        {categories.filter(x => x.parentId === "None").map((childCategory) => {
                            return (
                                <option key={childCategory.id} value={childCategory.id}>{childCategory.name}</option>
                            );
                        })}

                    </Select>
                    <div className="flex gap-2">
                        {editable && editingOn && <Button size="xs" className="my-2" onClick={() => {
                            setCreateCategoryTracker({ categoryNames: "", parentId: "None", level: 0 });
                            setShowCreateCategoryModal(true)
                        }
                        }>Add</Button>}

                    </div>
                </div>}

                {/* Regular category selection*/}
                {tempCategoryIds.length > 0 && tempCategoryIds.map((categoryId, index) => {
                    let parentId = index === 0 ? "None" : tempCategoryIds[index - 1];
                    return (

                        <div key={"selected-" + index} className="">
                            <Select disabled={lockedCategoryIds?.includes(categoryId)} value={categoryId} onChange={(e: any) => updateCategoryIds(index, e.target.value)}>
                                <option value="None">None</option>
                                {categories.filter(x => x.parentId === parentId).map((childCategory) => {
                                    return (
                                        <option key={childCategory.id} value={childCategory.id}>{childCategory.name}</option>
                                    );
                                })}
                            </Select>
                            <div className="flex gap-2">
                                {editable && editingOn && <Button size="xs" className="my-2" onClick={() => {
                                    setCreateCategoryTracker({ categoryNames: "", parentId: index - 1 !== -1 ? tempCategoryIds[index - 1] : "None", level: index });
                                    setShowCreateCategoryModal(true)
                                }
                                }>Add</Button>}
                                {editable && editingOn && <Button size="xs" className="" color="red" onClick={() => {
                                    setDeleteCategoryId(categoryId);
                                    setShowConfirmDeleteCategoryModal(true)
                                }
                                }>Del</Button>}
                            </div>
                        </div>

                    );
                })}

            </div>

            {editable && showCreateCategoryModal && createCategoryModal()}
            {editable && showConfirmDeleteCategoryModal &&
                <ConfirmDeleteModal
                    showConfirmDeleteModal={showConfirmDeleteCategoryModal}
                    setShowConfirmDeleteModal={setShowConfirmDeleteCategoryModal}
                    handleDelete={deleteCategory}
                    messageString={"Are you sure you want to delete category " + categories.find(x => x.id === deleteCategoryId)?.name + " and its subcategories?"}
                />
            }
        </div>
    );
}

export default CategoryManager;