//import createGUID from 'logic/utility/helper-functions/createGUID';

import {EditablePropertyMetadata} from 'classes/gridslate/EditablePropertyMetadata';
import {EditablePropertySelectItem} from 'classes/gridslate/EditablePropertySelectItem';
import { Status } from "classes/enums/Status";
import createGUID from 'logic/utility/createGUID';

const generateTypeSelectionList = () => {
    let list = [] as EditablePropertySelectItem[];
    list.push(new EditablePropertySelectItem("Webpage", "webpage"));
    list.push(new EditablePropertySelectItem("Course", "course"));
    list.push(new EditablePropertySelectItem("Course Text", "courseText"));
    // for (let i = 0; i < allThemes.length; i++) {
    //     list.push(new EditablePropertySelectItem(allThemes[i].name, allThemes[i].id));
    // }
    return list;
}

class Page {
    id: string = "";
    elementId: string = "";
    type: string = "webpage";
    name: string = "";
    categoryIds: string[] = ["None"];
    description: string = "";
    isPublic: boolean = true;
    styleThemeId: string = "";
    ownerId: string = "";
    creationDate: string = "";
    lastUpdateDate: string = "";
    componentJSON: string = "{\"type\":\"doc\",\"content\":[{\"type\":\"heading\",\"attrs\":{\"elementId\":\"" + createGUID(10) + "\",\"color\":null,\"textAlign\":\"left\",\"level\":1},\"content\":[{\"type\":\"text\",\"text\":\"Your content here...\"}]}]}";
    

    //local
    status: Status = Status.unloaded;

}

const getPageProperties = (thisObject: Page) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Title", value: thisObject? thisObject.name : "New nodepage" });
    editableProperties.push({ propertyName: 'description', propertyType: 'string', displayName: "Description", value: thisObject? thisObject.description : "" });
    editableProperties.push({ propertyName: 'type', propertyType: 'select', displayName: "Type", value: thisObject? thisObject.type : "webpage", dataSource: generateTypeSelectionList() });
    //editableProperties.push({ propertyName: 'tags', propertyType: 'string', displayName: "Tags", value: thisObject? thisObject.tags : "" });
    editableProperties.push({ propertyName: 'isPublic', propertyType: 'boolean', displayName: "Publicly accessable?", value: thisObject? thisObject.isPublic: true });
    return editableProperties;
}

export { Page, getPageProperties };