import { CourseContentType } from "classes/enums/CourseContentType";
import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";
import { ChallengeCheckpoint } from "classes/course/ChallengeCheckpoint";

class CourseContent {
    id: string = createGUID(10);    
    type: string = ""; //coursePage, courseText, webpage, wordSet, quiz, test, activity
    name: string = ""; 
    categoryIds: string[] = []; //Required for courseText, code Exercise, and mcq
    checkpoints: ChallengeCheckpoint[] = [];
    contentDocument: string = "";
    dashboardIsVisible: boolean = true; //for students
    dashboardOrder: number = 0;
    statusCode: StatusCode = StatusCode.new;

    //local
    status: string = "";

    constructor (type: string = CourseContentType.Page, name: string = "New page") {
        this.type = type;
        this.name = name;
    }
}

export {CourseContent};