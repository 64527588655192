import {
    Menu,
    Item,
    Separator,
    Submenu,
    useContextMenu
} from "react-contexify";

import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "menu-id";

const TestContextify = () => {
    // 🔥 you can use this hook from everywhere. All you need is the menu id
    const { show } = useContextMenu({
        id: MENU_ID
    });

    const handleItemClick = ({ event, props, triggerEvent, data } : any) => {
        console.log(event, props, triggerEvent, data);
    }

    const handleContextItemClick = (item: string) => {
        console.log(item);
    }

    const displayMenu= (e: any) => {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show({
            event: e,
        });
    }

    return (
        <div>
            {/* just display the menu on right click */}
            <div onContextMenu={(e:any) => show({event: e})}>
                Right click inside the box
            </div>
            {/* run custom logic then display the menu */}
            <div onContextMenu={displayMenu}>
                Right click inside the box
            </div>


            <Menu id={MENU_ID}>
                <Item onClick={(e: any) => handleItemClick(e)}>
                    Item 1
                </Item>
                <Item onClick={() =>handleContextItemClick("item2")}>
                    Item 2
                </Item>
                <Separator />
                <Item disabled>Disabled</Item>
                <Separator />
                <Submenu label="Submenu">
                    <Item onClick={() => handleContextItemClick("Sub Item 1")}>
                        Sub Item 1
                    </Item>
                    <Item onClick={() => handleContextItemClick("Sub Item 2")}>Sub Item 2</Item>
                </Submenu>
            </Menu>
        </div>
    );
}

export default TestContextify;