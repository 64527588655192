// public string Id { get; set; } = "";
// public string CourseId { get; set; } = "";
// public string ElementId { get; set; } = "";
// //public string Name { get; set; } = "";
// public byte Difficulty { get; set; } = 0;        
// public List<string> QuestionIds { get; set; } = [];
// public List<string> AnswerIds { get; set; } = [];
// public string OwnerId { get; set; } = "";
// public long DateCreated { get; set; } = 0;
// public long StartDate { get; set; } = 0;
// public int Duration { get; set; } = 120; //seconds
// public byte ChallengeStatus { get; set; } = 0; //0: NotStarted, 1: InProgress, 2: Completed; 3: Cancelled
// public StatusCode StatusCode { get; set; } = StatusCode.None;

import createGUID from "logic/utility/createGUID";

class McqRevisionChallenge {
    id: string = createGUID(10);
    courseId: string = "";
    elementId: string = "";
    difficulty: number = 1;
    categoryIds: string[] = []; 
    numberOfQuestions: number = 10;
    questionIds: string[] = [];
    answerIds: string[] = [];
    ownerId: string = "";
    dateCreated: number = 0;
    dateCompleted: number = 0;
    startDate: number = 0;
    duration: number = 10 * 60; //seconds
    numberOfCorrectAnswers: number = 0;
    pointsAwarded: number = 0;
    challengeStatus: number = 0; //0: NotStarted, 1: InProgress, 2: Completed; 3: Cancelled
    statusCode: number = 0; //New

    constructor (courseId: string = "None", elementId: string = "None", selectedCategoryIds: string[] = []) {
        this.courseId = courseId;
        this.elementId = elementId;
        this.categoryIds = selectedCategoryIds;
    }
}

export { McqRevisionChallenge };