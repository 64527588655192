import { Editor } from '@tiptap/react';
import tiptapControlIcons from 'view_components/icons/tiptapControlIcons';
import { Dropdown } from 'flowbite-react';

type Props = {
    editor: Editor;
    disallowedControls: string[];
}

const TiptapPanelControls = (props: Props) => {
    const { editor, disallowedControls } = props;

    if (!editor) {
        return null
    }

    const defaultSize = 10;
    const defaultCol = "col-p2";

    const checkIfDisabled = (type: string) => {
        switch (type) {
            case 'undo':
                return !editor.can().chain().focus().undo().run();
            case 'redo':
                return !editor.can().chain().focus().redo().run();
            case 'alignment':
                return !editor.can().chain().focus().setTextAlign('left').run();
            case 'paragraph':
                return !editor.can().chain().focus().setParagraph().run();
            case 'heading':
                return !editor.can().chain().focus().toggleHeading({ level: 1 }).run();
            case 'bulletList':
                return !editor.can().chain().focus().toggleBulletList().run();
            case 'orderedList':
                return !editor.can().chain().focus().toggleOrderedList().run();
            case 'codeBlock':
                return !editor.can().chain().focus().toggleCodeBlock().run();
            case 'blockquote':
                return !editor.can().chain().focus().toggleBlockquote().run();
            // case 'image':
            //     return !editor.can().chain().focus().setImage().run();
            // case 'table':
            //     return !editor.can().chain().focus().setTable().run();
            case 'horizontalRule':
                return !editor.can().chain().focus().setHorizontalRule().run();
            case 'hardBreak':
                return !editor.can().chain().focus().setHardBreak().run();
            default:
                return false;

        }
    }

    const getDefaultClass = (type: string = "") => {
        let isDisabled = checkIfDisabled(type);
        if (isDisabled) {
            let defaultClass = "bg select-none bg-white text-gray-400 cursor-not-allowed pointer-events-none rounded size-" + defaultSize;
            return defaultClass;
        } else {
            let attributeActive = editor.isActive(type);
            if (type === 'alignment') {
                attributeActive = editor.isActive({ textAlign: 'left' }) || editor.isActive({ textAlign: 'center' }) || editor.isActive({ textAlign: 'right' }) || editor.isActive({ textAlign: 'justify' });
            }
            let defaultClass = "bg select-none " + (attributeActive ? "bg-col-p1" : "bg-col-t1") + " hover:bg-col-p1 text-white cursor-pointer rounded size-" + defaultSize;
            return defaultClass;
        }
    }

    const getHeadingLevel = () => {
        let headingNode = editor.getAttributes(editor.state.selection.$anchor.node().type.name);
        return headingNode.level ? "H" + headingNode.level : "None";
    }

    const getNextAlignment = (currentAlignment: any) => {
        if (!currentAlignment) {
            return "left";
        } else if (currentAlignment.textAlign === "left") {
            return "center";
        } else if (currentAlignment.textAlign === "center") {
            return "right";
        } else if (currentAlignment.textAlign === "right") {
            return "justify";
        } else if (currentAlignment.textAlign === "justify") {
            return "left";
        } else {
            return "left";
        }
    }

    return (
        <div className="flex items-center gap-2">
            <div
                onClick={() => editor.chain().focus().undo().run()}
                className={getDefaultClass('undo')}
            >
                {tiptapControlIcons.undoIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                onClick={() => editor.chain().focus().redo().run()}
                className={getDefaultClass('redo')}
            >
                {tiptapControlIcons.redoIcon(defaultSize * 3, defaultCol)}
            </div>

            {/* ================================= Alignment */}
            <div
                onClick={() => editor.chain().focus().setTextAlign(getNextAlignment(editor.getAttributes(editor.state.selection.$anchor.node().type.name))).run()}
                className={getDefaultClass('alignment')}
            >
                {editor.isActive({ textAlign: 'left' }) && tiptapControlIcons.alignmentLeftIcon(defaultSize * 4, defaultCol)}
                {editor.isActive({ textAlign: 'center' }) && tiptapControlIcons.alignmentCenterIcon(defaultSize * 4, defaultCol)}
                {editor.isActive({ textAlign: 'right' }) && tiptapControlIcons.alignmentRightIcon(defaultSize * 4, defaultCol)}
                {editor.isActive({ textAlign: 'justify' }) && tiptapControlIcons.alignmentJustifyIcon(defaultSize * 4, defaultCol)}
            </div>

            {/* ================================= Types */}
            <div
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={getDefaultClass('paragraph')}
            >
                {tiptapControlIcons.paragraphIcon(defaultSize * 3, defaultCol)}
            </div>
            <div className="w-20">
                <Dropdown disabled={checkIfDisabled('heading')} size="sm" label={getHeadingLevel()} dismissOnClick={true}>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} value={1}>H1</Dropdown.Item>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} value={2}>H2</Dropdown.Item>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} value={3}>H3</Dropdown.Item>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} value={4}>H4</Dropdown.Item>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()} value={5}>H5</Dropdown.Item>
                    <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()} value={6}>H6</Dropdown.Item>
                </Dropdown>
            </div>
            <div
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={getDefaultClass('bulletList')}
            >
                {tiptapControlIcons.bulletListIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={getDefaultClass('orderedList')}
            >
                {tiptapControlIcons.orderedListIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={getDefaultClass('codeBlock')}
            >
                {tiptapControlIcons.codeBlockIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={getDefaultClass('blockquote')}
            >
                {tiptapControlIcons.blockQuoteIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                className={getDefaultClass('image')}
            >
                {tiptapControlIcons.imageIcon(defaultSize * 3, defaultCol)}
            </div>
            <div
                className={getDefaultClass('table')}
            >
                {tiptapControlIcons.tableIcon(defaultSize * 3, defaultCol)}
            </div>
            {/* ================================= Misc */}
            <div
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
                className={getDefaultClass('hr')}
            >
                {tiptapControlIcons.horizontalRuleIcon(defaultSize * 3, defaultCol)}
            </div>
            {/* <div
                onClick={() => editor.chain().focus().setHardBreak().run()}
                className={defaultClass}
            >
                Hard break
            </div> */}


        </div>

    )
}
export default TiptapPanelControls;  