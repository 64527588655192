//React
import { useEffect, useState } from 'react';

//UI
//import { Timeline } from "flowbite-react";

//Services
import { useAtom } from 'jotai';
import { courseProgressAtom } from 'atom';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CourseChallenge } from 'classes/course/CourseChallenge';

//Logics
import time from 'logic/utility/time';

//Components

//Classes

type Props = {
    contentDocument: any;
    courseChallenge: CourseChallenge;
}

const CheckpointTimeline = (props: Props) => {

    const { contentDocument, courseChallenge } = props;
    const [courseProgress] = useAtom(courseProgressAtom);

    class TimeLineObject {
        elementId: string = "";
        isCompleted: boolean = false;
        name: string = "";
        dateCompleted: number = 0;

        constructor(elementId: string) {
            this.elementId = elementId;
        }
    }

    const [timeLineObjects, setTimeLineObjects] = useState<TimeLineObject[]>([]);

    //TODO: Needs to be ordered based on components order

    useEffect(() => {
        let tempTimeLineObjects: TimeLineObject[] = [];

        if (!contentDocument.content) {
            return;
        }

        //Objects in order that components are rendered
        for (let component of contentDocument.content) {
            if (component.type === ExtensionType.Checkpoint) {
                tempTimeLineObjects.push(new TimeLineObject(component.attrs.elementId));
            }
        }

        let thisChallengeProgress = courseProgress.challengeProgresses.find(x => x.challengeId === courseChallenge.id);
        if (thisChallengeProgress) {
            for (let checkpointProgress of thisChallengeProgress.checkpointProgresses) {
                let timeLineObject = tempTimeLineObjects.find(x => x.elementId === checkpointProgress.elementId);
                if (timeLineObject) {
                    timeLineObject.isCompleted = true;
                    timeLineObject.dateCompleted = checkpointProgress.dateCompleted;
                }
            }
        }

        setTimeLineObjects(tempTimeLineObjects);
    }, [contentDocument, courseProgress.challengeProgresses.find(x => x.challengeId === courseChallenge.id)?.checkpointProgresses.length]);

    const renderCheckpointProgress = () => {
        let thisChallengeProgress = courseProgress.challengeProgresses.find(x => x.challengeId === courseChallenge.id);
        //Should not possible
        if (!thisChallengeProgress) {
            console.log("ChallengeProgress not found for challenge: " + courseChallenge.id + " in CheckpointTimeline");
            return <></>;
        }

        const renderCheckmark = (elementId: string) => {
            let checkpoint = thisChallengeProgress?.checkpointProgresses.find((x) => x.elementId === elementId);
            if (checkpoint) {
                return <span>✔️</span>
            }
            return <span>❌</span>
        }

        const renderDateCompleted = (elementId: string) => {
            let checkpoint = thisChallengeProgress?.checkpointProgresses.find((x) => x.elementId === elementId);
            if (checkpoint) {
                return <span className="text-xs text-center">{time.toCoolTimeString(checkpoint.dateCompleted)}</span>
            }
            return <div className="text-xs text-center">_</div>
        }


        const getCheckpointTimelineStyle = () => {
            let baseClass = "m-1 p-1 md:my-2 md:p-2 border border-2 md:border-4 ";
            if (thisChallengeProgress?.isComplete) {
                baseClass += "border-green-500";
            } else {
                baseClass += "border-slate-500";
            }
            return baseClass;
        }

        return (
            <div className={getCheckpointTimelineStyle()} >
                {courseChallenge.checkpoints.map((checkpoint, index) => {
                    return <div key={checkpoint.elementId + index} className="inline-block h-auto rounded bg-slate-200 m-2 p-2">
                        <div className="font-gray text-sm md:text-lg flex justify-center">
                            <span>{checkpoint.type + ": "}</span>
                            <span>{renderCheckmark(checkpoint.elementId)}</span>
                        </div>
                        <div className="font-gray ">
                            <span>{renderDateCompleted(checkpoint.elementId)}</span>
                        </div>
                    </div>

                })
                }
            </div >)
    }

    return (
        <div>{renderCheckpointProgress()}</div>
      
    )
}

export default CheckpointTimeline;