class GenericPropertyUpdate {
    propertyName: string = "";
    value: any = "";

    constructor(propertyName: string, value: any) {
        this.propertyName = propertyName;
        this.value = value;
    }
}

export {GenericPropertyUpdate};