//Container component for the main content of the page
const Container = ({ children}: any) => {
    return (
        <div id="main-container" className='container mx-auto'>
            {children}
        </div>
    )
}

export default Container;
