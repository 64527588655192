//React
import { useEffect, useState, useRef } from 'react';

//UI

//Services
//import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';

//Logics
import parseLogic from 'logic/parse/parseLogic';
import genericSaveLoadLogic from 'logic/saveload/genericSaveLoadLogic';

//Components
import GenericSaveLoadPanel from "views/utility/GenericSaveLoadPanel";
import NavbarConstructorPanel from "views/siteconstruction/NavbarConstructorPanel";

//Classes
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import {Page} from "classes/Page";
import { NavbarClass, getNavbarClassProperties } from "classes/gridslate/navbar/Navbar";

//Store
import { editableNavbarAtom } from 'atom';
import { useAtom } from 'jotai';

//import websiteData from "assets/db/website.json";

import { CrudAction } from 'classes/enums/CrudAction';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';

import { IsBusy } from 'classes/general/IsBusy';

type Props = {
    pages: Page[] | null;
}

const NavbarConstructor = (props: Props) => {

    const [navBars, setNavBars] = useState<NavbarClass[]>([]);
    const [loadedNavbarClassIndex, setLoadedNavbarClassIndex] = useState<number>(-1);
    const [loadedNavbar, setLoadedNavbar] = useAtom(editableNavbarAtom);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const [pages, setPages] = useState<Page[]>([]);
   
    useEffect(() => {

        const loadNavbarClasss = () => {
            setIsBusy(isBusy.loading(["navBars"]));
            let filterModel = new FilterModel();
            filterModel.onlyOwner = true;
            crudService.get(Class.navbarClass, filterModel).then(response => {
                if (response.success) {
                    setNavBars(response.payload);
                } else {
                    errorService.handleError(response);
                }
                setIsBusy(isBusy.loaded("navBars"));
            });
        }

        if (navBars.length === 0) {
            loadNavbarClasss();
        }

    }, []);

     useEffect(() => {

        // const fetchPages = async () => {
        //     let filterModel = new FilterModel();
            
        //     //filterModel.PropertyFilters = [{ propertyName: 'websiteId', value: websiteData.id }];
        //     //filterModel.PropertyFilters = [["ParentId", websiteData.id]];
        //     filterModel.onlyOwner = true;
        //     let response = await crudService.get(Class.page, filterModel);
        //     if (response.success) {
        //         setPages(response.payload);
        //     } else {
        //         errorService.handleError(response);
        //     }
        // }

        // if (props.pages) {
        //     setPages(props.pages);
        // } else {
        //     fetchPages();
        // }

    }, [props.pages]);

    //================================================================================================= - Edit NavbarClass

    const crudActions = (action: CrudAction, value: any, target?: string) => {
        if (action === CrudAction.appendNewObject) {
            //expect value as new code challenge object
            var newNavbarClasss = [...navBars, value];
            setLoadedNavbarClassIndex(newNavbarClasss.length - 1);
            setNavBars(newNavbarClasss);
        }
        if (action === CrudAction.getUpdatedObject) {
            return navBars[loadedNavbarClassIndex];
        }
        else if (action === CrudAction.returnNewObject) {
            let newNavbarClass = new NavbarClass();
            newNavbarClass.websiteId = config.site;
            return newNavbarClass;
        }
        else if (action === CrudAction.loadObject) {
            //value is actually id of object here
            console.log(value);
            let index = navBars.findIndex((NavbarClass) => NavbarClass.id === value);
            setLoadedNavbarClassIndex(index);
            setLoadedNavbar(navBars[index]);
        }
        else if (action === CrudAction.updateObject) {
            //value as NavbarClass object
            let newNavbarClass = value as NavbarClass;
            var tempNavbarClasss = [...navBars];
            tempNavbarClasss[loadedNavbarClassIndex] = newNavbarClass;
            setNavBars(tempNavbarClasss);
            toast.success("Navbar Updated");
        }
        else if (action === CrudAction.updateObjectProperties) {
            let editableProperties = value as EditablePropertyMetadata[];
            let newNavbarClasss = [...navBars];
            let newNavbarClass = { ...newNavbarClasss[loadedNavbarClassIndex] };
            newNavbarClass = parseLogic.parseObjectUpdate(newNavbarClass, editableProperties);
            newNavbarClasss[loadedNavbarClassIndex] = newNavbarClass;
            setNavBars(newNavbarClasss);
        }

        else if (action === CrudAction.deleteObject) {
            //value is actually the object here
            //But SHOULD always be currently selected object, especially if disabling selection while pending
            var tempNavbarClasss = [...navBars];
            //find index of object
            let index = tempNavbarClasss.findIndex((NavbarClass) => NavbarClass.id === value.id);
            tempNavbarClasss.splice(index, 1);
            setNavBars(tempNavbarClasss);
            setLoadedNavbarClassIndex(-1);
            toast.success("Code Exercise Deleted");
        }

    }

    //================================================================================================= - Save/Load


    //================================================================================== - Render

    return (
        <div className='container mx-auto'>
            <GenericSaveLoadPanel
                objectType='Navbar'
                loadedObjectIndex={loadedNavbarClassIndex}
                objects={navBars}
                crudActions={crudActions}
                saveLoadData={navBars[loadedNavbarClassIndex]}
                getEditableProperties={() => { return getNavbarClassProperties(navBars[loadedNavbarClassIndex]) }}
                saveLoadLogic={genericSaveLoadLogic}
            />

            {loadedNavbarClassIndex !== -1 &&
                <NavbarConstructorPanel
                    thisNavBar={navBars[loadedNavbarClassIndex]}
                    thesePages={pages} 
                />
            }

        </div>

    )

}

export default NavbarConstructor;