import { useState } from 'react';
import { Progress } from 'flowbite-react';
import { useInterval } from 'logic/hooks/useInterval';
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";

const customTheme: CustomFlowbiteTheme = {
  progress: {
    bar: "space-x-2 rounded-full text-center text-xl leading-none text-white dark:text-red",
  }
};

const Counter = (props: any) => {
  const { timeRemaining, duration, timerDelay } = props;
  const [count, setCount] = useState(timeRemaining);

  useInterval(() => {
    setCount(count - 1);
  }, timerDelay);

  let time = Math.floor(count / (duration / 100));

  return <Flowbite theme={{ theme: customTheme }}>
          <Progress 
                    color={count > 5 ? 'blue' : 'red'} 
                    size='xl' 
                    textLabel={count} 
                    progress={time} 
                    labelText />
         </Flowbite>
}

export default Counter;