//TODO: Possibly REDACT
//Description: This redirects to the code exercise challenge system, and handles the completion of the challenge.
//state not necessary if getting from nodeCourse

//React
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

//UI
import Container from "view_components/helper/Container";

//Services
import { useAtom } from 'jotai';
import { courseAtom, courseProgressAtom } from 'atom';
import crudService from 'services/crudService';
import errorService from 'services/errorService';

//Logics

//Classes
import { Class } from 'classes/enums/Class';
import { Mcq } from 'classes/synapp/mcq/Mcq';
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import { McqRevisionChallenge } from 'classes/synapp/challenge/McqRevisionChallenge';
import { McqRevisionChallengeLoadResponseModel } from 'classes/models/response/RevisionChallengeLoadResponseModel';

//Components
import McqChallengeSystem from "views/courseview/challenge/McqChallengeSystem";
import { toast } from 'react-toastify';

type Props = {
}

const McqRevisionChallengeWrapper = (props: Props) => {

    const [revisionChallenge, setRevisionChallenge] = useState<McqRevisionChallenge | null>(null);
    const { challengeId } = useParams();
    const [mcqs, setMcqs] = useState<Mcq[]>([]);

    useEffect(() => {
        if (!challengeId) {
            //TODO: Get revisionChallenge
        } else {
            loadRevisionChallenge()
        }
    }, [challengeId]);


    const loadRevisionChallenge = async () => {
        let filter = new FilterModel([new PropertyFilter("Id", challengeId)]);
        let response = await crudService.load(Class.mcqRevisionChallenge, filter);
        if (response.success) {
            let responseModel = response.payload as McqRevisionChallengeLoadResponseModel;
            setMcqs(responseModel.mcqs);
            if (responseModel.mcqRevisionChallenge.challengeStatus === 0) {
                //Reconcile number of mcqs with number of AnswerIds
                //TODO: remove this when duplicate Mcqs are removed from backend revision challenge creation logic
                let answerIds = [];
                for (let i = 0; i < responseModel.mcqs.length; i++) {
                    answerIds.push("None");
                }
                responseModel.mcqRevisionChallenge.answerIds = answerIds;
                //Update status to in progress
                responseModel.mcqRevisionChallenge.challengeStatus = 1;
                responseModel.mcqRevisionChallenge.startDate = Date.now();
                updateChallenge(responseModel.mcqRevisionChallenge, "start");
            } else {
                setRevisionChallenge(responseModel.mcqRevisionChallenge);
            }
        } else {
            errorService.handleError(response);
        }
    }

    //Save answer progress
    const updateChallenge = async (updatedRevisionChallenge: McqRevisionChallenge, action?: string) => {
        //console.log("Updating challenge: ", updatedRevisionChallenge);
        let response = await crudService.update(Class.mcqRevisionChallenge, updatedRevisionChallenge);
        if (response.success) {
            let thisUpdatedRevisionChallenge = response.payload as McqRevisionChallenge;
            setRevisionChallenge(thisUpdatedRevisionChallenge);
            if (action === "start") {
                toast.success("Challenge started.");
            } else if (action === "submit") {
                toast.success("Challenge completed.");
            }
            else {
                toast.success("Progress saved.");
            }
            return true;
        } else {
            errorService.handleError(response);
            return false;
        }
    }

    return (
        <Container>
            <div className="max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl">
                {!revisionChallenge && <div>Loading...</div>}
                {revisionChallenge &&
                    <McqChallengeSystem
                        mcqs={mcqs}
                        updateChallenge={updateChallenge}
                        revisionChallenge={revisionChallenge}
                        parentType={Class.mcqRevisionChallenge}
                        parentId={challengeId ? challengeId : "None"}
                    />
                }
            </div>
        </Container>
    )

}

export default McqRevisionChallengeWrapper;