import createGUID from "logic/utility/createGUID";
import { StatusCode } from "classes/enums/StatusCode";
class McqSubmittedAnswer {
    id: string = createGUID(10);
    userId: string = "";
    parentId: string = "";
    parentType: string = "";
    mcqId: string = "";
    submittedAnswerId: string = "";   
    incorrectSubmittedAnswerIds: string[] = [];
    isCorrect: boolean = false;
    isGraded: boolean = false;
    pointsAwarded: number = 0;
    isReset: boolean = false; 
    statusCode: StatusCode = StatusCode.new;
}

export { McqSubmittedAnswer};