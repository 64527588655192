//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";
import Container from "view_components/helper/Container";

//Services
import crudService from 'services/crudService';
import errorService from 'services/errorService';

//Logics

//Store

//Classes
import { CodeExercise } from "classes/synapp/code_exercise/CodeExercise";
import { CodeExerciseSubmission } from 'classes/synapp/code_exercise/CodeExerciseSubmission';
import { CodeExerciseSubmissionGrade } from 'classes/synapp/code_exercise/CodeExerciseSubmissionGrade';
import { Status } from "classes/enums/Status";
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from "classes/enums/Class"; //TODO: change this to enums/Class
import { CourseChallenge } from "classes/course/CourseChallenge";   

//Components
import SingleCodeExercise from './CodeExerciseSystemSingle';
import { StatusCode } from 'classes/enums/StatusCode';

type Props = {
    questionsSubmittable: boolean;
    completeChallenge: Function;
    courseChallenge: CourseChallenge;
    courseId: string;
}

const CodeExerciseSystem = (props: Props) => {

    const { questionsSubmittable, courseChallenge, completeChallenge, courseId } = props;
    const [selectedCodeExerciseIndex, setSelectedCodeExerciseIndex] = useState(-1);
    const [challengeStatus, setChallengeStatus] = useState(questionsSubmittable ? "loading" : "viewing");
    const [codeExercises, setCodeExercises] = useState<CodeExercise[]>([]);
    const [deadlinePassed, setDeadlinePassed] = useState(false);

    const [codeExerciseSubmissions, setCodeExerciseSubmissions] = useState<CodeExerciseSubmission[]>([]);
    const [allChangesSaved, setAllChangesSaved] = useState(true);
    
    useEffect(() => {

        console.log("reached course challenge: ", courseChallenge);

        const getCodeExercises = async () => {

            let parentCategory = courseChallenge.categoryIds.length > 0 ? courseChallenge.categoryIds[courseChallenge.categoryIds.length - 1] : "None";
            let filterModel = new FilterModel([new PropertyFilter("CategoryIds", parentCategory)]);

            let response = await crudService.get(Class.codeExercise, filterModel);
            if (response.success) {
                //Sort code exercises by difficulty

                if (response.payload.length === 0) {
                    setChallengeStatus("error");
                    return;
                }

                response.payload.sort((a: CodeExercise, b: CodeExercise) => a.difficulty - b.difficulty);
                //TODO: Check that codeExercises exist
                setCodeExercises(response.payload);
                setSelectedCodeExerciseIndex(0);
                setChallengeStatus("ready");
            } else {
                errorService.handleError(response);
            }
        }

        const getCodeExerciseSubmissions = async () => {
            let filterModel = new FilterModel([new PropertyFilter("ChallengeId", courseChallenge.id)]);
            filterModel.onlyOwner = true;
            let response = await crudService.get(Class.codeExerciseSubmission, filterModel);
            if (response.success) {
                let codeExerciseSubmissions = response.payload as CodeExerciseSubmission[];
                codeExerciseSubmissions.forEach((codeExerciseSubmission) => {
                    codeExerciseSubmission.status = Status.unchanged;
                });
                setCodeExerciseSubmissions(response.payload);
            } else {
                errorService.handleError(response);
            }
        }

        getCodeExercises();
        getCodeExerciseSubmissions();

    }, [courseChallenge.id]);

    useEffect(() => {
        if (courseChallenge.dueDate < Date.now()) {
            setDeadlinePassed(true);
        }
    }, [courseChallenge.dueDate]);

    //======================================================== Helper functions


    const getTotalPointsSaved = (codeExerciseSubmissions: CodeExerciseSubmission[]) => {
        let totalPoints = 0;
        codeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            totalPoints += codeExerciseSubmission.pointsAwarded;
        });
        return totalPoints;
    }

    const getTotalPointsFromCodeExerciseSubmission = (codeExerciseSubmission: CodeExerciseSubmission) => {

        const getAverageGrade = (grades: CodeExerciseSubmissionGrade[], difficulty: number) => {
            let total = 0;

            grades.forEach((grade) => {
                total += grade.grade;
            });
            return Math.round(total / grades.length) * difficulty;
        }

        let totalPoints = 0;

        let difficulty = codeExercises.find(x => x.id === codeExerciseSubmission.codeExerciseId)?.difficulty;
        if (!difficulty) {
            difficulty = 1;
        }
        totalPoints += getAverageGrade(codeExerciseSubmission.grades, difficulty);

        return totalPoints;
    }

    //======================================================== Render and Render helper functions


    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-40 h-24 ";
        classString += (selectedCodeExerciseIndex === index) ? "border-4 border-black" : " border-gray-300";
        let thisAnswer = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[index].id);
        if (!thisAnswer) {
            classString += " bg-gray-100";
        }
        else if (thisAnswer.grades.length > 0) {

            classString += " bg-green-200 border-green-500 border-2";
        }
        else if (thisAnswer.status === Status.updated) {
            classString += " bg-yellow-200 border-yellow-500 border-2";
        }
        else if (thisAnswer.status === Status.submitted || thisAnswer.isSubmitted) {
            classString += " bg-blue-200 border-blue-500 border-2";
        }
        else if (thisAnswer.status === Status.unchanged) {
            classString += " bg-green-200 ";
        }

        return classString;
    }

    const checkForPointThresholdExceeded = (codeExerciseSubmission: CodeExerciseSubmission) => {
        let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        let index = tempCodeExerciseSubmissions.findIndex(x => x.id === codeExerciseSubmission.id);
        tempCodeExerciseSubmissions[index] = codeExerciseSubmission;
        let totalPoints = getTotalPointsSaved(tempCodeExerciseSubmissions);
        if (totalPoints >= courseChallenge.minimumPointsRequired) {
            setChallengeStatus("completed");
            completeChallenge(totalPoints);
        }
    }

    //======================================================== Render

    const editCodeExerciseSubmissionSourceCode = (value: string) => {

        let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
        if (!thisCodeExerciseSubmission) {
            console.error("Code exercise submission not found");
            return;
        }
        thisCodeExerciseSubmission.sourceCode = value;

        //Cheatcode
        if (value === "cheat") {
            thisCodeExerciseSubmission.sourceCode = codeExercises[selectedCodeExerciseIndex].possibleAnswer;
        }

        thisCodeExerciseSubmission.status = Status.updated;
        setAllChangesSaved(false);

        let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        let index = tempCodeExerciseSubmissions.findIndex(x => x.id === thisCodeExerciseSubmission?.id);
        tempCodeExerciseSubmissions[index] = thisCodeExerciseSubmission;
        setCodeExerciseSubmissions(tempCodeExerciseSubmissions);

    }

    const updateCodeExerciseSubmissionWithGrade = (updatedCodeExerciseSubmission: CodeExerciseSubmission) => {
        updatedCodeExerciseSubmission.pointsAwarded = getTotalPointsFromCodeExerciseSubmission(updatedCodeExerciseSubmission);
        checkForPointThresholdExceeded(updatedCodeExerciseSubmission);
        saveCodeExerciseSubmission(updatedCodeExerciseSubmission);
    }

    const updateCodeExerciseSubmissions = (updatedCodeExerciseSubmissions: CodeExerciseSubmission[]) => {
        let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        updatedCodeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            let index = tempCodeExerciseSubmissions.findIndex(x => x.id === codeExerciseSubmission.id);
            tempCodeExerciseSubmissions[index] = codeExerciseSubmission;
            tempCodeExerciseSubmissions[index].status = Status.unchanged;
        });
        setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
    }

    const getUpdatedCodeExerciseSubmissions = () => {
        let updatedCodeExerciseSubmissions = [] as CodeExerciseSubmission[];
        codeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            if (codeExerciseSubmission.status !== Status.unchanged && codeExerciseSubmission.sourceCode !== "") {
                updatedCodeExerciseSubmissions.push(codeExerciseSubmission);
            }
        });
        return updatedCodeExerciseSubmissions;
    }

    const saveCodeExerciseSubmission = async (codeExerciseSubmission?: CodeExerciseSubmission) => {
        if (!codeExerciseSubmission) {
            codeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
        }

        if (codeExerciseSubmission && codeExerciseSubmission.courseId === "") {
            codeExerciseSubmission.courseId = courseId;
        }

        let response = await crudService.update(Class.codeExerciseSubmission, [codeExerciseSubmission]);
        if (response.success) {
            updateCodeExerciseSubmissions(response.payload);
            checkAllChangesSaved();
        } else {
            errorService.handleError(response);
        }
    }

    const saveAllCodeExerciseSubmissions = async () => {
        let updatedCodeExerciseSubmissions = getUpdatedCodeExerciseSubmissions();
        let response = await crudService.update(Class.codeExerciseSubmission, updatedCodeExerciseSubmissions);
        if (response.success) {
            updateCodeExerciseSubmissions(response.payload);
            checkAllChangesSaved();
        } else {
            errorService.handleError(response);
        }
    }

    const checkAllChangesSaved = () => {
        let allChangesSaved = true;
        codeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            if (codeExerciseSubmission.status !== Status.unchanged && codeExerciseSubmission.sourceCode !== "") {
                allChangesSaved = false;
            }
        });
        setAllChangesSaved(allChangesSaved);
    }

    const checkSaveButtonDisabled = () => {
        let disabled = false;
        if (selectedCodeExerciseIndex === -1) {
            disabled = true;
        } else {
            let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
            if (!thisCodeExerciseSubmission) {
                disabled = true;
            } else if (thisCodeExerciseSubmission.status === Status.unchanged) {
                disabled = true;
            } else if (thisCodeExerciseSubmission.sourceCode === "") {
                disabled = true;
            }
        }
        return disabled;
    }

    const checkSaveAllButtonDisabled = () => {
        let disabled = true;
        codeExercises.forEach((codeExercise) => {
            let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercise.id);
            if (thisCodeExerciseSubmission && thisCodeExerciseSubmission.status !== Status.unchanged) {
                disabled = false;
            }
        });
        return disabled;
    }

    const getCodeExerciseSubmission = () => {
        let codeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
        if (!codeExerciseSubmission) {

            codeExerciseSubmission = new CodeExerciseSubmission();
            codeExerciseSubmission.codeExerciseId = codeExercises[selectedCodeExerciseIndex].id;
            codeExerciseSubmission.challengeId = courseChallenge.id;
            codeExerciseSubmission.courseId = courseId;
            codeExerciseSubmission.statusCode = StatusCode.new;
            codeExerciseSubmission.status = Status.new;

            let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
            tempCodeExerciseSubmissions.push(codeExerciseSubmission);
            setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
        }

        return codeExerciseSubmission
    }

    return (
        <Container>
            <div className="grid grid-cols-1 place-items-center">
                {/* {JSON.stringify(courseChallenge)} */}
                <div className="text-lg md:text-2xl">
                    {courseChallenge.name}
                </div>
                <div className="text-lg md:text-2xl">
                    Points needed to complete challenge: {getTotalPointsSaved(codeExerciseSubmissions)}/{courseChallenge.minimumPointsRequired}
                </div>
                {/* {getTotalPointsSaved(codeExerciseSubmissions) >= courseChallenge.minimumPointsRequired && <Button onClick={() => { completeChallenge(getTotalPointsSaved(codeExerciseSubmissions)) }}>Complete challenge!</Button>} */}
                {getTotalPointsSaved(codeExerciseSubmissions) >= courseChallenge.minimumPointsRequired && 
                    <div>Challenge completed! Congratulations! Keep going for more points.</div>}

                {challengeStatus === "error" && <div>Error: There are no code exercises in this category!</div>}
                {challengeStatus === "loading" && <div>Loading...</div>}

                {challengeStatus === "ready" && <div>
                    {<div>Changes saved: {allChangesSaved.toString()}</div>}
                    <div className="flex gap-2 flex-wrap items-center">
                        {codeExercises && codeExercises.map((codeExercise, index) => {
                            return (
                                <div key={index} className={getSelectionCardClassString(index)} onClick={() => setSelectedCodeExerciseIndex(index)}>
                                    <div>{codeExercise.name}</div>
                                    <div className="truncate">{codeExercise.instructionText}</div>
                                    <div>Difficulty: {codeExercise.difficulty}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex gap-4 items-center">
                        <Button disabled={checkSaveButtonDisabled()} onClick={() => { saveCodeExerciseSubmission() }}>Save</Button>
                        <Button disabled={checkSaveAllButtonDisabled()} onClick={() => { saveAllCodeExerciseSubmissions() }}>Save All</Button>
                    </div>
                  
                </div>}

                {selectedCodeExerciseIndex !== -1 && <div className="w-full">
                        <SingleCodeExercise
                            codeExercise={codeExercises[selectedCodeExerciseIndex]}
                            codeExerciseSubmission={getCodeExerciseSubmission()}
                            editCodeExercise={editCodeExerciseSubmissionSourceCode}
                            updateCodeExerciseSubmissionWithGrade={updateCodeExerciseSubmissionWithGrade}
                            dueDatePassed={deadlinePassed}
                        />
                    </div>}

            </div>
        </Container>
    );
}

export default CodeExerciseSystem;