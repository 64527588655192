
const extractTextFromNodeElement = (nodeElement: any) => {

    let text = "";
   
    if (!nodeElement.content) {
        console.log("No content in nodeElement");
        return text;
    }

    for (let i = 0; i < nodeElement.content.length; i++) {
        const content = nodeElement.content[i];
        if (content.type === "text") {
            text += content.text;
        } else if (content.type === "heading") {
            text += extractTextFromNodeElement(content);
        } 
        else if (content.type === "paragraph") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "bulletList") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "listItem") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "blockquote") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "orderedList") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "codeBlock") {
            text += extractTextFromNodeElement(content);
        } else if (content.type === "image") {
            //text += extractTextFromElement(content);
        }
    }

    return text;

}

export { extractTextFromNodeElement };