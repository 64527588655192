
enum ExtensionType {
    Checkpoint = 'checkpoint',
    Mcq = 'mcq',
    CourseText = 'courseText',
    CourseTextChallenge = 'courseTextChallenge',
    CodeExerciseChallenge = 'codeExerciseChallenge',
    McqChallenge = 'mcqChallenge',
    McqRevisionChallenge = 'mcqRevisionChallenge',
}

export {ExtensionType}