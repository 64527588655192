import { SelectOption } from "classes/general/SelectOption";

const stringArrayToMultiSelectOptions = (array: string[]) => {
    if (array === null) return [];
    let options = [] as SelectOption[];
    array.forEach((element) => {
        options.push({ value: element, label: element });
    })
    return options;
}

const stringToMultiSelectOption = (string: string) => {
    return { value: string, label: string };
}

const multiSelectOptionsToStringArray = (options: any) => {
    if (options === null) return [];
    let array = [] as string[];
    options.forEach((option: SelectOption) => {
        array.push(option.value);
    })
    return array;
}

const multiSelectOptionToString = (option: any) => {
    return option.value;
}


export default { 
    stringArrayToMultiSelectOptions, 
    stringToMultiSelectOption, 
    multiSelectOptionsToStringArray, 
    multiSelectOptionToString 
};