//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Modal, Textarea, Tabs, Spinner, TabsRef } from 'flowbite-react';
import { Row } from 'view_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import { useAtom } from 'jotai';
import { codeExercisesAtom } from 'atom';

//Logics
import parseCodeEditor from "logic/parse/parseCodeEditor";

//Components
import Editor from "react-simple-code-editor";

//Classes
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
import { CodeExercise } from "classes/synapp/code_exercise/CodeExercise";
import { IsBusy } from 'classes/general/IsBusy';

type Props = {
    content: string;
    categoryIds: string[];
}

const CodeExerciseConstructor = (props: Props) => {

    const { content, categoryIds } = props;
    //const [codeExercises, setCodeExercises] = useState<CodeExercise[]>([]);
    const [codeExercises, setCodeExercises] = useAtom(codeExercisesAtom);
    const [selectedCodeExerciseIndex, setSelectedCodeExerciseIndex] = useState<number>(-1);
    const [difficultySetting, setDifficultySetting] = useState("1");
    const [numberOfQuestionsToGenerate, setNumberOfQuestionsToGenerate] = useState("3");
    const [codeExerciseAIContentForPrompt, setCodeExerciseAIContentForPrompt] = useState(content);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const mainTabsRef = useRef<TabsRef>(null);
    const [showAIGenerationModal, setShowAIGenerationModal] = useState(false);

    // useEffect(() => {
    //     if (content !== "") {
    //         setCodeExerciseAIContentForPrompt(content);
    //     }
    // }, [content]);

    useEffect(() => {
        loadCodeExercises();
        setSelectedCodeExerciseIndex(-1);
    }, [categoryIds]);

    useEffect(() => {
        setShowAIGenerationModal(isBusy.isGenerating);
    }, [isBusy.isGenerating]);

    const createCodeExercise = () => {
        let newCodeExercise = new CodeExercise();
        newCodeExercise.name = "New Code Exercise";
        newCodeExercise.difficulty = parseInt(difficultySetting);
        newCodeExercise.categoryIds = categoryIds;
        let tempCodeExercises = [...codeExercises];
        tempCodeExercises.push(newCodeExercise);
        setSelectedCodeExerciseIndex(tempCodeExercises.length - 1);
        setCodeExercises(tempCodeExercises);
    }

    //================================================================================================= - Edit CodeExercise

    const editCodeExercise = (action: string, value: any, index?: number) => {
        if (selectedCodeExerciseIndex !== -1) {
            let tempCodeExercises = [...codeExercises];
            if (action === "editInstructionText") {
                tempCodeExercises[selectedCodeExerciseIndex].instructionText = value;
            }
            if (action === "editSourceCode") {
                tempCodeExercises[selectedCodeExerciseIndex].sourceCode = value;
            }
            if (action === "editPossibleAnswer") {
                tempCodeExercises[selectedCodeExerciseIndex].possibleAnswer = value;
            }
            if (action === "editDifficulty") {
                tempCodeExercises[selectedCodeExerciseIndex].difficulty = parseInt(value);
            }
            if (action === "editName") {
                tempCodeExercises[selectedCodeExerciseIndex].name = value;
            }

            if (tempCodeExercises[selectedCodeExerciseIndex].status !== Status.new) {
                tempCodeExercises[selectedCodeExerciseIndex].status = Status.updated;
            }
            setCodeExercises(tempCodeExercises);
        }
    }

    const deleteCodeExercise = () => {
        if (selectedCodeExerciseIndex === -1) {
            toast.error("No codeExercise selected to delete");
            return;
        }
        if (codeExercises[selectedCodeExerciseIndex].status === Status.new) {
            let tempCodeExercises = [...codeExercises];
            tempCodeExercises.splice(selectedCodeExerciseIndex, 1);
            setCodeExercises(tempCodeExercises);
            setSelectedCodeExerciseIndex(-1);
        } else {
            //delete from db
            let filterModel = new FilterModel([new PropertyFilter("Id", codeExercises[selectedCodeExerciseIndex].id)]);
            crudService.del(Class.codeExercise, filterModel).then(response => {
                if (response.success) {
                    let tempCodeExercises = [...codeExercises];
                    tempCodeExercises.splice(selectedCodeExerciseIndex, 1);
                    setCodeExercises(tempCodeExercises);
                    setSelectedCodeExerciseIndex(-1);
                } else {
                    errorService.handleError(response);
                }
            });
        }

    }

    //================================================================================================= - Save/Load CodeExercise

    const saveCodeExercises = async () => {

        //check validity of all codeExercises
        for (let codeExercise of codeExercises) {
            if (!checkForValidity(codeExercise)) {
                alert("Invalid codeExercise found. Please check all codeExercises (in red) for validity before saving.");
                return;
            }
        }

        let requestModelList = codeExercises.filter(x => x.status === Status.new || x.status === Status.updated);
        //TODO: parse data property when necessary
        //TODO: parse for problems like empty question or answers or no correct answer selected

        let response = await crudService.create(Class.codeExercise, requestModelList);
        if (response.success) {
            let responseModel = response.payload as CodeExercise[];
            let tempCodeExercises = [...codeExercises];

            for (let i = 0; i < responseModel.length; i++) {
                let index = tempCodeExercises.findIndex(x => x.id === responseModel[i].id);
                tempCodeExercises[index] = responseModel[i];
                tempCodeExercises[index].status = Status.unchanged;
            }
            toast.success("Code Exercises saved successfully");
            setCodeExercises(tempCodeExercises);
        } else {
            errorService.handleError(response);
        }
    }

    const loadCodeExercises = async () => {
        let parentCategory = categoryIds.length > 0 ? categoryIds[categoryIds.length - 1] : "None";
        let filterModel = new FilterModel([new PropertyFilter("CategoryIds", parentCategory)]);

        filterModel.onlyOwner = true;
        let codeIds = [];
        let response = await crudService.get(Class.codeExercise, filterModel);
        if (response.success) {
            let responseModel = response.payload as CodeExercise[];
            for (let i = 0; i < responseModel.length; i++) {
                responseModel[i].status = Status.unchanged;
                codeIds.push(responseModel[i].id);
            }
            console.log(codeIds);
            setCodeExercises(response.payload);
        } else {
            errorService.handleError(response);
        }

    }

    // [
    //     "vzcWj3rnyH",
    //     "iwhgsGl0mO",
    //     "q428HEUozz",
    //     "rVpL7whByP"
    // ]

    //============================================================ - Category Constructor, Selector and Helper Functions


    //============================================================= - AI Generator


    const parseAndCreateAIGeneratedCodeExercises = (response: string) => {
        // let tempCodeExercises = parseCodeExerciseAILogic.parseAIResponseToCodeExercises(response);
        // for (let codeExercise of tempCodeExercises) {
        //     //codeExercise.categoryId = categoryStatusLogic.findParentCategoryId(categoryStatus);
        //     codeExercise.level0CategoryId = level0CategoryId;
        //     codeExercise.level1CategoryId = level1CategoryId;
        //     codeExercise.level2CategoryId = level2CategoryId
        //     codeExercise.difficulty = parseInt(difficultySetting);
        //     codeExercise.status = Status.new;
        // }
        // //Add to existing codeExercises
        // let allTempCodeExercises = [...codeExercises];
        // allTempCodeExercises = allTempCodeExercises.concat(tempCodeExercises);
        // console.log(tempCodeExercises);
        // console.log(allTempCodeExercises);
        // setCodeExercises(allTempCodeExercises);
    }

    const generateCodeExercisesFromAI = async () => {
        // setIsBusy(isBusy.generating());
        // let request = { "Prompt": parseCodeExerciseAILogic.returnPrompt(numberOfQuestionsToGenerate, difficultySetting, codeExerciseAIContentForPrompt) };
        // let response = await apiService.post(config.apiUrl + '/cms/testGPT', request);
        // if (response.success) {
        //     console.log(response.payload);
        //     parseAndCreateAIGeneratedCodeExercises(response.payload);
        //     //set tab to create
        //     mainTabsRef.current?.setActiveTab(0);
        //     setIsBusy(isBusy.reset());
        //     setShowAIGenerationModal(false);
        // } else {
        //     errorService.handleError(response);
        //     setIsBusy(isBusy.reset());
        // }

    }

    const renderAIGenerationModal = () => {

        return (<Modal show={showAIGenerationModal} size="5xl" onClose={() => setShowAIGenerationModal(false)} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="my-10">
                    <div>{"Difficulty:" + difficultySetting}</div>
                    <select disabled={isBusy.isBusy} value={difficultySetting} onChange={(e) => { setDifficultySetting(e.target.value) }}>
                        <option value="different">Different difficulties</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <div>Number of questions to generate: </div>
                    <select disabled={isBusy.isBusy} value={numberOfQuestionsToGenerate} onChange={(e) => { setNumberOfQuestionsToGenerate(e.target.value) }}>
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                    </select>
                    <Button onClick={() => { setShowAIGenerationModal(false); }}>Select Content</Button>
                    <div>Content</div>
                    <div className="flex gap-2">
                    </div>
                    <div>
                        <Textarea placeholder="Content for generating codeExercises..." required rows={8} value={codeExerciseAIContentForPrompt} onChange={(e) => setCodeExerciseAIContentForPrompt(e.target.value)} />
                    </div>

                    <div className="flex gap-2 my-6">
                        <Button disabled={codeExerciseAIContentForPrompt === "" || isBusy.isBusy} onClick={() => generateCodeExercisesFromAI()}>Generate</Button>
                    </div>
                    {isBusy.isGenerating && <div className="flex justify-center gap-2">
                        <div className="w-32">
                            <Spinner size="large" />
                            Generating...
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>);
    }

    //================================================================================== - Render CodeExercise

    const renderCodeExercise = (codeExercise: CodeExercise) => {

        // const {codeExercise} = props;

        return (
            <div style={{ height: "400px" }}>
                <div className="flex center-items gap-2">
                    <span className="inline-block align-middle">Name:</span>
                    <input className="w-full" type="text" value={codeExercise.name} onChange={(e) => editCodeExercise("editName", e.target.value)} />
                    Difficulty:
                    <select value={codeExercise.difficulty} onChange={(e) => editCodeExercise("editDifficulty", e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
                
                <Tabs
                    //onSelect={(e) => setCurrentTab(e)}
                    className="mb-3"
                >
                    <Tabs.Item active title="Instructions">
                        <strong>Instructions</strong>
                        <textarea
                            className="w-full h-full"
                            value={codeExercises[selectedCodeExerciseIndex].instructionText}
                            rows={10}
                            onChange={(e) =>
                                editCodeExercise("editInstructionText", e.target.value)
                            }
                        />
                    </Tabs.Item>
                    <Tabs.Item title="Code">
                        <strong>Code</strong>
                        <div className="code-editor-div w-full">

                            <Editor
                                value={codeExercises[selectedCodeExerciseIndex].sourceCode}
                                padding={10}
                                className="code-editor text-white"
                                onValueChange={(sourceCode) =>
                                    editCodeExercise("editSourceCode", sourceCode)
                                }
                                highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode, "codeExerciseId")}
                                textareaId="codeArea"
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 18,
                                    outline: 0
                                }}
                            />
                        </div>
                    </Tabs.Item>

                    <Tabs.Item title="PossibleAnswer">
                        <strong>Possible Answer</strong>
                        <div className="code-editor-div">
                            <Editor
                                value={codeExercises[selectedCodeExerciseIndex].possibleAnswer}
                                padding={10}
                                className="code-editor text-white"
                                onValueChange={(sourceCode) =>
                                    editCodeExercise("editPossibleAnswer", sourceCode)
                                }
                                highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode)}
                                textareaId="codeArea"
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 18,
                                    outline: 0
                                }}
                            />
                        </div>
                    </Tabs.Item>
                </Tabs>
            </div>
        )
    }

    const checkForValidity = (codeExercise: CodeExercise) => {
        let valid = true;
        if (codeExercise.instructionText === "") {
            valid = false;
        }
        return valid;
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedCodeExerciseIndex === index) ? " border-black" : " border-gray-300";
        let thisCodeExercise = codeExercises[index];
        //let thisAnswer = submittedAnswers.find(x => x.codeExerciseId === filteredCodeExercises[index].id);
        if (thisCodeExercise.status === Status.unchanged) {
            classString += " bg-gray-100";
        }
        else if (thisCodeExercise.status === Status.new) {
            if (checkForValidity(thisCodeExercise)) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        } else if (thisCodeExercise.status === Status.updated) {
            classString += " bg-yellow-200";
        }
        return classString;
    }

    return (
        <div className='container mx-auto'>
            <div className="flex gap-2 my-6">
                <Button onClick={() => createCodeExercise()}>Create Code Exercise</Button>
                <Button onClick={() => saveCodeExercises()}>Save Code Exercises</Button>
                <Button onClick={() => setShowAIGenerationModal(true)}>Generate Code Exercises</Button>
                <Button disabled={selectedCodeExerciseIndex === -1} onClick={() => deleteCodeExercise()}>Delete</Button>
            </div>
            <div className="flex gap-2 flex-wrap">
                {codeExercises && codeExercises.map((codeExercise, index) => {
                    return (
                        <div key={"codeExercise-" + index} className={getSelectionCardClassString(index)}
                            onClick={() => setSelectedCodeExerciseIndex(index)}
                        >
                            {"Dif: " + codeExercise.difficulty}
                        </div>
                    )
                }
                )}
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    {selectedCodeExerciseIndex !== -1 && codeExercises[selectedCodeExerciseIndex] && 
                        renderCodeExercise(codeExercises[selectedCodeExerciseIndex])}
                </div>
            </div>

            {showAIGenerationModal && renderAIGenerationModal()}

        </div>
    )

}

export default CodeExerciseConstructor;

