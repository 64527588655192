//React

//UI
import 'css/codeeditor.css';
import { highlight, languages } from "prismjs";

//Services

//Logics

//Components

//Store

//Classes
import { CodeError } from 'classes/synapp/code_exercise/CodeError';

const hightlightWithLineNumbersAndErrors = (input: string, language: any, codeExerciseId: string, codeError: CodeError) => {
    //let errorLine = codeError.errorLine;
    return highlight(input, languages.js, 'js')
        .split("\n")
        .map((line, i) => {
            if (codeError.lineNumber === "" || (codeError.lineNumber !== "" && codeError.codeExerciseId === codeExerciseId && i + 1 !== parseInt(codeError.lineNumber))) {
                return `<span class="editorLineNumber">${parseZeroes(i + 1)}</span>${line}`
            }
            if (codeError.lineNumber !== null && codeError.codeExerciseId === codeExerciseId && i + 1 === parseInt(codeError.lineNumber)) {
                return `<span class="editorLineNumber red-line">${parseZeroes(i + 1)}</span>${line}`
            }
            return '';
        })
        .join("\n");
}

const parseZeroes = (index: number) => {
    if (index < 10) { return "00" + index; }
    if (index < 100) { return "0" + index; }
    return index;
}

const hightlightWithLineNumbers = (input: string, language: any) =>
    highlight(input, languages.js, 'js')
        .split("\n")
        .map((line: string, i: number) => `<span class="editorLineNumber">${parseZeroes(i + 1)}</span>${line}`)
        .join("\n");

const parseCodeLines = (code: string, codeExerciseId?: string, codeError?: CodeError) => {
    if (codeError && codeExerciseId) {
        return hightlightWithLineNumbersAndErrors(code, languages, codeExerciseId, codeError);
    } else {
        return hightlightWithLineNumbers(code, languages);
    }
}


export default { parseCodeLines };