//React

//UI

//Services

//Logics

//Classes

//Components

type Props = {
    pageId: string;
}


const ViewPage = (props: Props) => {

    const { pageId } = props;

    return (
        <div>
        <h1>ViewPage</h1>
        </div>
    );
    }

export default ViewPage;