//React

//UI

//Services

//Logics

//Classes
import { ExtensionType } from 'classes/enums/ExtensionType';
import {ChallengeCheckpoint} from "classes/course/ChallengeCheckpoint";

//Components
import { mergeAttributes,  } from '@tiptap/core';
import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import Checkpoint from "views/courseconstruction/customelements/Checkpoint"

export default Node.create({
    name: ExtensionType.Checkpoint,
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            elementId: {
                default: "default",
            },
            checkpoint: {
                default: new ChallengeCheckpoint(),
            }
        }
    },
    parseHTML() {
        return [
            {
                tag: ExtensionType.Checkpoint,
            },
        ]
    },
    renderHTML({ HTMLAttributes }) {
        return [ExtensionType.Checkpoint, mergeAttributes(HTMLAttributes)]
    },
    addNodeView() {
        return ReactNodeViewRenderer(Checkpoint)
    },
})

